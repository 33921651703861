import React, { FC } from 'react'
import styles from './../styles.module.scss'
import { QuestionType } from '../types'
import CheckboxGroup from '../../../../../components/CheckboxGroup'
import RadioGroup from '../../../../../components/RadioGroup'
import { OptionType } from '../../../../../components/RadioGroup/types'
import { isEmpty } from 'lodash-es'

export interface QuestionProps {
  data: QuestionType
  questionNumber: number
  selectedAnswer?: OptionType | OptionType[]
  onAnswerSelect: (queNo: number, option: OptionType | OptionType[]) => void
}

const Question: FC<QuestionProps> = ({
  data,
  selectedAnswer,
  questionNumber,
  onAnswerSelect,
}) => {
  const { ansOptions, questionType, question, questionHelpText } = data
  return (
    <div data-test-id={'prt-question-wrap'} className={styles.questionWrap}>
      <div data-test-id={'prt-question-text'} className={styles.questionText}>
        {question}
      </div>
      {!isEmpty(questionHelpText) && (
        <div className={styles.questionText}>{`(${questionHelpText})`}</div>
      )}
      <div>
        <div
          data-test-id={'prt-question-responses'}
          className={styles.questionOptions}
        >
          {questionType === 'Multiple Response' ? (
            <CheckboxGroup
              id={'policy-rec-que-' + questionNumber}
              value={selectedAnswer as OptionType[]}
              options={ansOptions}
              onChange={ans => onAnswerSelect(questionNumber, ans)}
            />
          ) : (
            <RadioGroup
              id={'policy-rec-que-' + questionNumber}
              value={selectedAnswer as OptionType}
              options={ansOptions}
              onChange={ans => onAnswerSelect(questionNumber, ans)}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Question
