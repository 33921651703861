import React, { DetailedHTMLProps, FC, InputHTMLAttributes, ReactNode } from "react"
import styles from "./styles.module.scss"
import clsx from "clsx";
import withErrorBoundary from "../../util/components/ErrorBoundary/withErrorBoundary";

export type CheckboxProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  label?: ReactNode;
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
}

const Checkbox: FC<CheckboxProps> = ({ label, className, labelProps, ...inputPros }) => {
  return (
    <label data-test-id={'Checkbox-Wrap'} {...labelProps} className={clsx(styles.label, labelProps && labelProps.className)}>
      <input
        data-test-id={'checkbox-selector'}
        className={clsx(styles.checkbox, className)}
        type="checkbox"
        {...inputPros}
      />
      <span data-test-id={`${label}-checkbox`}>{label}</span>
    </label>
  );
}

export default withErrorBoundary(Checkbox, {component: 'Checkbox'});