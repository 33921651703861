import React, { FC } from 'react'
import BlogCard from '../Blog-Card'
import BlogCardList from '../Blog-Card/List'
import BlogContain from '../Blog-Card/Contain'
import clsx from 'clsx'
import styles from './styles.module.scss'
import { ExploreCardSelectionFields } from './types'

const ExploreCardSection: FC<ExploreCardSelectionFields> = ({
  heading,
  cards,
}) => {
  return (
    <div
      data-test-id="ExploreCardSection"
      className={clsx(styles.explor, styles.blogBlock)}
    >
      <BlogContain>
        <h2 className={styles.heading}>{heading}</h2>

        <BlogCardList testId="ExploreCardSection-Cards">
          {cards.map(card => (
            <BlogCard
              key={card.title}
              title={card.title}
              href={card.href}
              tag={card.tag}
            >
              {card.description}
            </BlogCard>
          ))}
        </BlogCardList>
      </BlogContain>
    </div>
  )
}

export default ExploreCardSection
