import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import * as CMS from '../types/SitecoreAdapter'

// Add a dataLayer event so Google Tag Manager can track pages which are the result of client-side routing.
// Skip the first pageview, which analytics handles out of the box, and fire on subsequent pageviews
const usePageTracking = (title: string) => {
  const location = useLocation()
  // Use a ref to store the firstHit flag.
  // React pummels normal javascript variables -- they don't stick around long enough
  // to be useful. The function reruns a bunch of times, the variables are recreated and thrown away.
  // Not good for a flag that needs to stick around awhile.
  // To keep values around in React, we usually use useState. In this case, though, setting the state would cause
  // the effects attached to the state to re-run, and that would mess with the point of the flag
  // being a record of a first hit.
  // useRef is the ticket here. It wraps a value (any value) inside of itself in a property called `current`.
  // Also, it does not trigger re-renders inside of effects -- the value you set is opaque to React.
  const firstHit = useRef(true)

  // Store the initial title in a ref, to compare with the new title
  // location.pathname changes before title is updated
  // so, we must check that both the pathname and the title have updated
  const lastTitle = useRef(title)

  useEffect(() => {
    // a ref is a wrapper around a value which is stored in a key called 'current'
    if (firstHit.current === true) {
      firstHit.current = false
      lastTitle.current = title
      return
    }

    // location has changed _and_ the title has changed too
    if (lastTitle.current !== title) {
      lastTitle.current = title

      const dataLayer = window['dataLayer'] || []

      dataLayer.push({
        event: 'TrackPage',
        path: location.pathname,
        title: title,
      })
      return
    }
  }, [location, title])
}

type TitleAdapterProps = CMS.RouteFields<{
  pageTitle: CMS.TextField
}>

function PageTrackingForClientRouting({
  sitecoreContext: {
    route: {
      fields: { pageTitle },
    },
  },
}: TitleAdapterProps) {
  usePageTracking(pageTitle.value)
  return null
}

export default withSitecoreContext()(PageTrackingForClientRouting)
