import React, { FC } from 'react'
import styles from './styles.module.scss'
import BackButton from './components/BackButton'
import QustionHeader from './components/Header'
import QuestionView from './components/Section'
import { useRecommendationQuestionnaire } from './services/useRecommendationQuestionnaire'

export const PolicyRecommendationQuestionnaire: FC = () => {
  const {
    activeQuestion,
    questionNumber,
    selectedAnswer,
    totalQuestions,
    actionBtnProps,
    goBack,
    handleAnswerSelection,
  } = useRecommendationQuestionnaire()

  return (
    <>
      <QustionHeader question={activeQuestion} />
      <div
        data-test-id={'prt-question-container'}
        className={styles.questionRoot}
      >
        {questionNumber > 1 && <BackButton onClick={goBack} />}
        <QuestionView
          {...{
            actionBtnProps,
            activeQueNumber: questionNumber,
            activeQuestion,
            handleAnswerSelection,
            selectedAnswer,
            totalQuestions,
          }}
        />
      </div>
    </>
  )
}
