export function getEmbedUrlFromId(youtubeId: string) {
  return `https://www.youtube.com/embed/${youtubeId}?enablejsapi=1`
}

export function getIdFromUrl(url: string) {
  const regEx = "^(?:https?:)?//[^/]*(?:youtube(?:-nocookie)?\.com|youtu\.be).*[=/]([-\\w]{11})(?:\\?|=|&|$)";
  const matches = url.match(regEx);
  
  if (matches) {
    return matches[1]
  }

  return false;
}