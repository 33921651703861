import { useParams } from 'react-router-dom'
import React from 'react'
import RouteHandler from './RouteHandler'

// This is the main JSX entry point of the app invoked by the renderer (server or client rendering).
// By default the app's normal rendering is delegated to <RouteHandler> that handles the loading of JSS route data.

// support languages in the URL prefix
// e.g. /da-DK/path, or /en/path, or /path
const LANGUAGE_REG_EXP = /^\/?(([a-z]{2}-[A-Z]{2})|([a-z]{2}))(\/|$)/g

export const parseRouteParams = (url: string) => {
  const language = url.match(LANGUAGE_REG_EXP)
  const route = url.replace(LANGUAGE_REG_EXP, '')

  return {
    route: route.startsWith('/') ? route : `/${route}`,
    language: language ? language[0].replace(/\//g, '') : undefined,
  }
}

type Props = {
  isSSR: boolean
}

const JssRoute = (props: Props) => {
  const params = useParams()
  const url = params['*'] || ''

  return (
    <RouteHandler {...parseRouteParams(url)} url={url} isSSR={props.isSSR} />
  )
}

export default JssRoute
