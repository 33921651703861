import React from 'react'
import clsx from 'clsx'
import Tag from '../../../page-components/TagTopics/Tag'
import { TagProps } from '../../../page-components/TagTopics/types'
import styles from './styles.module.scss'

export type TagListProps = {
  tags: TagProps[]
  rootPath: string
}

function TagList({ tags, rootPath = '' }: TagListProps) {
  const tagEls = tags.map(tag => (
    <Tag label={tag.label} href={`${rootPath}${tag.href}`} key={tag.href} />
  ))

  return (
    <div className={styles.root}>
      <div className={clsx('h3', styles.title)}>Related Topics:</div>
      <div className={styles.tags}>{tagEls}</div>
    </div>
  )
}

export default TagList
