import React, { ReactNode } from 'react'
import Media from '../Media'
import { MobileTable } from './Mobile/MobileTable'
import { DesktopTable } from './Desktop/DesktopTable'
import { Column } from './types'

export type TableProps = {
  rowHeadings: ReactNode[]
  columns: Column[]
  boldRowHeadings?: boolean
  squash?: boolean
  largerFirstColumn: boolean
  cornerLabel: ReactNode
  numberOfColumns: number
}

const Table = ({
  rowHeadings,
  columns,
  boldRowHeadings = true,
  squash = false,
  largerFirstColumn = false,
  cornerLabel,
  numberOfColumns
}: TableProps) => {
  const getDesktopTable = () => (
    <DesktopTable
      rowHeadings={rowHeadings}
      columns={columns}
      numberOfColumns={numberOfColumns}
      boldRowHeadings={boldRowHeadings}
      largerFirstColumn={largerFirstColumn}
      cornerLabel={cornerLabel}
    />
  )

  const getMobileTable = () => (
    <MobileTable rowHeadings={rowHeadings} columns={columns} />
  )

  return squash ? (
    getDesktopTable()
  ) : (
    <Media desktop={getDesktopTable} mobile={getMobileTable} />
  )
}

export default Table
export { DesktopTable, MobileTable }
