import fetch from 'isomorphic-fetch'
import { IOption } from '../types'
import { find, memoize } from 'lodash-es'

// Atom services return in this format
export type AsyncProfession = {
  Description: string
  Id: number
  Tags: string[]
}

export type AsyncProfessionList = AsyncProfession[]

type IndustriesResponse = {
  IndustriesServices: {
    Id: number
    Services: { Id: number }[]
  }[]
}

/**
 * ListResults pulls the initial list of options displayed to app search user
 */
export class ListResults {
  private endpointProfessions: string
  private endpointIndustries: string
  private primed: boolean
  private cache: IOption[]
  private preselect: { industry: false | string }

  constructor(
    endpointProfessions: string,
    endpointIndustries: string,
    preselect: { industry: false | string }
  ) {
    if (!endpointProfessions) {
      throw new Error('Professions endpoint required')
    }

    if (!endpointIndustries) {
      throw new Error('Industries endpoint required')
    }

    this.endpointProfessions = endpointProfessions
    this.endpointIndustries = endpointIndustries
    this.cache = []
    this.primed = false
    this.preselect = preselect
  }

  fetch(): Promise<IOption[]> {
    return this.cache.length > 0
      ? Promise.resolve(this.cache)
      : this.fetchFromService()
  }

  private async fetchFromService(): Promise<IOption[]> {
    try {
      // console.log('[Atom Results] fetch list from Atom')

      // get the set of preselected profession IDs
      if (this.preselect.industry) {
        this.cache = await this.filterByIndustry(this.preselect.industry)
      }

      if (this.cache.length === 0) {
        this.cache = await this.fetchProfessions()
      }

      return this.cache
    } catch (err) {
      console.log('Unable to fetch initial results from Atom')
      console.log(err)
    }
    return []
  }

  private async filterByIndustry(
    preselectedIndustry: string
  ): Promise<IOption[]> {
    const [professions, industries] = await Promise.all([
      this.fetchProfessions(),
      this.fetchIndustries(),
    ])

    const idNum = parseInt(preselectedIndustry, 10)
    const industry = find(industries, ({ Id }) => Id === idNum)
    if (industry) {
      const preselectedProfessions = new Set()
      industry.Services.forEach(({ Id }) => preselectedProfessions.add(Id))

      return professions.filter(({ value }) =>
        preselectedProfessions.has(value)
      )
    }
    return []
  }

  fetchProfessions: () => Promise<IOption[]> = memoize(async () => {
    console.log('[List Results] fetch professions')
    const response = await fetch(this.endpointProfessions)
    const atomResult = await response.json()
    return this.processList(atomResult.map(resultToOption))
  })

  fetchIndustries = memoize(async () => {
    console.log('[List Results] fetch industries')
    const industriesRes = await fetch(this.endpointIndustries)
    const industriesJson: IndustriesResponse = await industriesRes.json()
    return industriesJson.IndustriesServices
  })

  primeCache() {
    if (this.primed === false) {
      this.primed = true
      this.fetch()
    }
  }

  // if the first option of the list is abortion, remove it
  private processList(list: IOption[]) {
    if (list.length > 0 && list[0].label.indexOf('Abortion') > -1) {
      return list.slice(1)
    }
    return list
  }
}

// treating this as a singleton for now
type ListResultsInstance = false | ListResults

let listResultsInstance: ListResultsInstance = false

export function getListResults(
  endpointProfessions: string,
  endpointIndustries: string,
  preselect: { industry: false | string }
): ListResults {
  if (listResultsInstance === false) {
    listResultsInstance = new ListResults(
      endpointProfessions,
      endpointIndustries,
      preselect
    )
  }

  return listResultsInstance
}

// adapt to the "Option" shape expected by react-select
export function resultToOption(profession: AsyncProfession): IOption {
  return {
    label: profession.Description,
    value: profession.Id,
  }
}
