import React from 'react'
import { Text, Image, Placeholder } from '@sitecore-jss/sitecore-jss-react'
import clsx from 'clsx'
import styles from './styles.module.scss'
import spaceStyles from '../../styles/space.module.scss'
import { ImageField, TextField } from '../../types/SitecoreAdapter'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type H2andIconProps = {
  fields: {
    icon: ImageField
    title: TextField
  }
  rendering: any
}

const H2andIcon = ({ fields, rendering }: H2andIconProps) => {
  return (
    <div
      data-test-id="H2WithIcon"
      className={clsx(spaceStyles.contain, 'block', styles.wrap)}
    >
      <Image className={styles.xlargeImage} field={fields.icon} />
      <div className={styles.right}>
        <Text tag="h2" field={fields.title} />
        <div className={clsx(styles.body, 'Subsection', 'list-style')}>
          <Placeholder name="icon-section-body" rendering={rendering} />
        </div>
      </div>
    </div>
  )
}

export default withErrorBoundary(H2andIcon, { component: 'H2andIcon' })
