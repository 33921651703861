import React from 'react'
import RecentPostsBase from './base'
import { RecentPostsFields, RecentPostsProps } from './types'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type Props = {
  fields: RecentPostsFields
} & RecentPostsProps

const RecentPosts = ({
  fields: { title, cards, seeMoreLink, seeMoreText },
  styleOverride,
  hideCardDescription,
}: Props) => {
  return (
    <RecentPostsBase
      title={title}
      cards={cards}
      seeMoreLink={seeMoreLink}
      seeMoreText={seeMoreText}
      styleOverride={styleOverride}
      hideCardDescription={hideCardDescription}
    />
  )
}

export default withErrorBoundary(RecentPosts, { component: 'RecentPosts' })
