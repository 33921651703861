import React from 'react'
import clsx from 'clsx'

import CollapsiblePanel from '../../components/CollapsiblePanel/base'
import TagList from './Tag-List'

import styles from './styles.module.scss'
import { TagProps } from './types'

type MobileTagListProps = {
  tags: TagProps[]
  title: string
}

const BlogTagTopics = ({ tags, title }: MobileTagListProps) => (
  <div className={clsx(styles.mobileWrap, 'TagTopics')}>
    <CollapsiblePanel
      testId="BlogTagTopics-TagListPanel"
      toggle={<div>{title}</div>}
      styleOverrides={{ body: styles.body, toggle: styles.title }}
    >
      <TagList tags={tags} rootPath="/blog" className={styles.list} />
    </CollapsiblePanel>
  </div>
)

export default BlogTagTopics
