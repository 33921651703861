import React, { ReactNode } from 'react'
import BackgroundImage from './BackgroundImage'
import styles from './styles.module.scss'
import clsx from 'clsx'
import Media from '../Media'
import TrustPilotWidget from '../TrustPilot-Widget/TrustPilotWidget'

type HeroProps = {
  backgroundImage: ReactNode
  heading: ReactNode
  descriptor: ReactNode
  isDark?: boolean
  children: ReactNode
  showTrustPilotWidget?: boolean
}

const HomepageHero = ({
  backgroundImage,
  heading,
  descriptor,
  isDark,
  children,
  showTrustPilotWidget,
}: HeroProps) => {
  // if the background image is not dark, meaning background is light, use dark text
  const darkTextClass = !isDark && styles.darkText

  return (
    <div
      className={styles.heroWrapper}
      data-test-context="Hero"
      data-embed="Homepage-Hero"
    >
      <div className={styles.body}>
        <div className={clsx(showTrustPilotWidget && styles.headingContainer)}>
          <h1
            className={clsx(
              styles.title,
              showTrustPilotWidget && styles.headingWithTrustPilot,
              darkTextClass
            )}
          >
            {heading}
          </h1>
          {showTrustPilotWidget && (
            <div
              className={styles.tpMicroHeroWidget}
              data-test-id={'hero-tp-widget'}
            >
              <Media
                mobile={() => (
                  <TrustPilotWidget type={'microstar'} darkBg={isDark} />
                )}
                tablet={() => (
                  <TrustPilotWidget type={'microcombo'} darkBg={isDark} />
                )}
              />
            </div>
          )}
        </div>
        <div>
          <div className={clsx(styles.descriptor, !isDark && styles.dark)}>{descriptor}</div>
        </div>
        {children}
      </div>
      <BackgroundImage>{backgroundImage}</BackgroundImage>
    </div>
  )
}

export default HomepageHero
