import config from '../temp/config'

export type SitecoreEvent = {
    name: string
    id: string
}

export type IEventName = string

export type IEventGuid = string

function getUrl(id: string) {
  return config.tracker + id
}

export async function fetchSitecorePixel(id: string) {
  return fetch(getUrl(id))
}

export function triggerSitecoreEvent(
  goalItems: Map<IEventName, IEventGuid>,
  event: string
) {
  const id = goalItems.get(event)

  if (!id) {
    throw new Error(
      `Missing sitecore goal item reference for event: ${event}`
    )
  }

  return fetchSitecorePixel(id).catch(e => {
    console.warn(`[Sitecore Analytics] did not log event ${event}`)
  })
}
