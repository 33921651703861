import { POLICY, PolicyInfoType } from "./types"

export const policyInfo: PolicyInfoType = {
  [POLICY.GENERAL]: {
    bestFor: [
      "Slip-and-fall accidents",
      "Damaged customer property",
      "Libel or slander lawsuits"
    ]
  },
  [POLICY.WORKERS_COMPENSATION]: {
    bestFor: [
      "Employee medical expenses",
      "Missed wages",
      "Legal costs"
    ]
  },
  [POLICY.BOP]: {
    bestFor: [
      "Customer injury",
      "Damaged business property",
      "Business interruption incidents"
    ]
  },
  [POLICY.COMMERCIAL_AUTO]: {
    bestFor: [
      "Car accidents",
      "Vandalism and theft",
      "Weather damage"
    ]
  },
  [POLICY.PROFESSIONAL]: {
    bestFor: [
      "Professional negligence lawsuits",
      "Project scope disputes",
      "Work mistakes or oversights"
    ]
  },
  [POLICY.CYBER]: {
    bestFor: [
      "Data breach lawsuits",
      "Breach notification expenses",
      "Fraud monitoring costs"
    ]
  },
  [POLICY.COMMERCIAL_UMBRELLA]: {
    bestFor: [
      "Additional liability coverage",
      "Additional workers' comp coverage",
      "Additional commercial auto coverage"
    ]
  },
  [POLICY.PRODUCT]: {
    bestFor: [
      "Construction businesses and contractors",
      "Manufacturers",
      "Retailers and wholesalers"
    ]
  }
}