import React from 'react'
import {
  Placeholder,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react'

type PlaceholderFactoryProps = {
  sitecoreContext: { route: any }
  name: string
}

const PlaceholderFactory = ({
  sitecoreContext: { route },
  name,
}: PlaceholderFactoryProps) => <Placeholder name={name} rendering={route} />

export default withSitecoreContext()(PlaceholderFactory)
