import * as React from 'react'
import * as CMS from '../../types/SitecoreAdapter'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import Helmet from 'react-helmet'

const OgUrl = ({ children }: { children: string }) => {
  return (
    <Helmet>
      <meta property="og:url" content={children} />
    </Helmet>
  )
}

type OgUrlAdapterProps = CMS.RouteFields<{
  canonical: CMS.TextField
}>

const OgUrlAdapter = ({
  sitecoreContext: {
    route: {
      fields: { canonical },
    },
  },
}: OgUrlAdapterProps) => {
  return <OgUrl>{canonical.value}</OgUrl>
}

export const OgUrlWired = withSitecoreContext()(OgUrlAdapter)
