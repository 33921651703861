import React, { ReactNode } from 'react'
import LazyLoadWrapper from './LazyLoadWrapper'
import clsx from 'clsx'
import styles from './styles.module.scss'

export type WrapperChildProps = {
  children: (showVideo: boolean) => ReactNode
}

type WrapperProps = {
  lazyLoad: boolean
} & WrapperChildProps

const Wrapper = ({ children, lazyLoad }: WrapperProps) => {
  const wrapperClasses = clsx(styles.root, 'Video')

  if (!lazyLoad) {
    return (
      <div data-test-id={'video-container'} className={wrapperClasses}>
        {children(true)}
      </div>
    )
  }

  return (
    <LazyLoadWrapper className={wrapperClasses}>{children}</LazyLoadWrapper>
  )
}

export default React.memo(Wrapper)
