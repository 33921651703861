import React, { ReactNode } from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'
import { ButtonAlignment, ButtonSize } from '../../types/common'

const ALIGNMENTS = {
  Left: '',
  Center: styles.center,
  Right: styles.right,
}

export interface ILayoutWrapperProps {
  alignment: ButtonAlignment
  size: ButtonSize
  wrapClassName?: string
}

type LayoutWrapperProps = {
  children?: ReactNode
} & ILayoutWrapperProps

const LayoutWrapper = ({
  alignment,
  wrapClassName,
  size,
  children,
}: LayoutWrapperProps) => {
  const alignmentClass = ALIGNMENTS[alignment]

  return (
    <div
      className={clsx(
        alignmentClass,
        styles[size],
        styles.root,
        wrapClassName
      )}
    >
      {children}
    </div>
  )
}

export default LayoutWrapper
