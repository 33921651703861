import BlogContain from '../../../components/Blog-Card/Contain'
import { FC } from '../../../types/common'
import styles from './styles.module.scss'
import ProfileInfoSection from './ProfileInfo'
import BioInfoSection from './BioInfo'
import { Author } from '../../blog/Attribution'

export interface AuthorProfileProps {
  author: Author
}

const AuthorProfile: FC<AuthorProfileProps> = (props) => {
  return (
    <BlogContain
      testId="AuthorProfileBioContainer"
      block
      className={styles.authorProfileBioContainer}
    >
      <div className={styles.authorProfileBioWrap}>
        <ProfileInfoSection {...props} />
        <BioInfoSection {...props} />
      </div>
    </BlogContain>
  )
}

export default AuthorProfile
