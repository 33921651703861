import React, { useContext } from 'react'
import CallbackButton, {
  ICMSButtonBase,
  ILayoutWrapperProps,
} from '../CMS-Button/CallbackButton'
import clsx from 'clsx'
import { ModalCTAContext } from '../../context/ModalCTAContext';

const ModalButton = ({
  primary,
  alignment,
  size,
  buttonClassName,
  wrapClassName,
  children,
  testId
}: ICMSButtonBase & ILayoutWrapperProps) => {
  const { showModal } = useContext(ModalCTAContext)

  return (
    <CallbackButton
      wrapClassName={clsx(wrapClassName, 'ModalCTA')}
      buttonClassName={buttonClassName}
      alignment={alignment}
      primary={primary}
      size={size}
      onClick={showModal}
      testId={testId}
    >
      {children}
    </CallbackButton>
  )
}

export default React.memo(ModalButton)
