import config from './temp/config'
import * as Sentry from '@sentry/react'

/*
  Sentry.io
  Configure Sentry to send crash alerts
 */
export const initSentry = () => {
  Sentry.init({
    dsn: config.sentryDsn,
    environment: config.env,
    integrations: [new Sentry.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,
  })
}
