import React from 'react'
import HorizontalScroller from '../../../../HorizontalScroller'
import { ICarouselRouterItems } from '../types'
import styles from './styles.module.scss'

export default function Mobile({ routerItems }: ICarouselRouterItems) {
  return (
    <HorizontalScroller className={styles.root}>
      {routerItems.map(item =>
        <div className={styles.slide}>
          {item}
        </div>
      )}
    </HorizontalScroller>
  )
}
