import * as React from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import HomePageHero from './index.base'
import { getResponsiveHeroImage } from '../../components/Responsive-Image/HeroImageAdapter'
import { HomePageHeroProps } from './types'
import styles from './styles.module.scss'
import HeroAppStartSwitch from '../../quotes/HeroAppStartSwitch'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

export type { HomePageHeroProps }

const HomePageHeroAdapter = ({
  fields: {
    backgroundImage,
    mobileImage,
    tabletImage,
    laptopImage,
    desktopImage,
    heading,
    descriptor,
    isDark,
    showTrustPilotWidget,
  },
  enableTpHero,
  children,
}: HomePageHeroProps) => {
  const responsiveImage = getResponsiveHeroImage({
    mobileImage,
    tabletImage,
    laptopImage,
    desktopImage,
    backgroundImage,
  })

  const appStart = (
    <HeroAppStartSwitch
      darkBg={isDark ? isDark.value : false}
      appStartProps={{ rootClass: styles.ctaRoot }}
    />
  )

  return (
    <HomePageHero
      backgroundImage={responsiveImage}
      heading={<Text field={heading} />}
      descriptor={<Text field={descriptor} />}
      isDark={isDark && isDark.value}
      showTrustPilotWidget={enableTpHero || showTrustPilotWidget.value}
    >
      {/* using children, if available, gives supports the Partner site app starts */}
      {/* falling back to the Hero App Start supports using a standalone component */}
      {children || appStart}
    </HomePageHero>
  )
}

export default withErrorBoundary(HomePageHeroAdapter, {
  component: 'Homepage-Hero',
})
