import { TagProps } from '../../../page-components/TagTopics/types'
import { PrimaryTag } from './BreadcrumbAdapter'

const crumbLinkAdapter = (text: string, href: string = '') => ({
  fields: {
    title: { value: text },
    link: { value: { href } },
    linkTitle: { value: text }
  },
})

const getPrimary = (tag: PrimaryTag) => ({
  name: tag.fields.name.value,
  href: tag.url.replace('/shared/tags/', '/blog/tag/'),
})

export const getBreadcrumbs = (
  tags: TagProps[],
  currentPage: string,
  primaryTag: PrimaryTag
) => {
  try {
    const primary = getPrimary(primaryTag)
    return [
      crumbLinkAdapter('Insureon Small Business Blog', '/blog'),
      crumbLinkAdapter(primary.name, primary.href),
      crumbLinkAdapter(currentPage),
    ]
  } catch (err) {
    // @ts-ignore
    console.error(`Error processing breadcrumbs: ${err.message || err}`)
    return []
  }
}
