import React, { FC } from 'react'
import { Field, Placeholder } from '@sitecore-jss/sitecore-jss-react'

import styles from '../../styles/test-utils.module.scss'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type Props = {
  fields: {
    copy: Field
    copyArticle?: Field
  }
  rendering?: any
}

const Preview: FC<Props> = (props) => {
  const { copy, copyArticle } = props.fields
  return (
    <div>
      {copy.value && (
        <div className={styles.previewWrap}>
          <div className={styles.bgCement}>
            <div className={styles.tag}>Copy</div>
          </div>
          <div className="copy">
            <Placeholder name="preview" rendering={props.rendering} />
          </div>
        </div>
      )}
      {copyArticle && copyArticle.value && (
        <div className={styles.previewWrap}>
          <div className={styles.bgCement}>
            <div className={styles.tag}>Copy Article</div>
          </div>
          <div className="copy-article">
            <Placeholder name="preview" rendering={props.rendering} />
          </div>
        </div>
      )}
    </div>
  )
}

export default withErrorBoundary(Preview, { component: 'Preview' })
