import React from 'react'

import { LinkWrapperProps, LinkWrapperWithChildItemsProps } from './LinkTypes'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

export const LinkWrapperWithChildItems = (
  props: LinkWrapperWithChildItemsProps
) => {
  return (
    <a
      href={props.href}
      title={props.title}
      className={props.className}
      target={props.newWindow ? '_blank' : ''}
      onClick={props.onClick}
      {...props.additionalProps}
    >
      {props.children}
    </a>
  )
}

const LinkWrapper = (props: LinkWrapperProps) => {
  return (
    <a
      href={props.href}
      title={props.title}
      className={props.className}
      target={props.newWindow ? '_blank' : ''}
      onClick={props.onClick}
      {...props.additionalProps}
    >
      {props.image
        ? React.createElement(props.image.type, {
            ...props.image.props,
            className: props.imageStyles,
          })
        : props.text}
    </a>
  )
}

export default withErrorBoundary(LinkWrapper, { component: 'LinkWrapper' })
