import * as React from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'

export default function LoadingDots({
  className,
  secondary = false,
}: {
  className?: string
  secondary?: boolean
}) {
  return (
    <div data-test-id="LoadingDots" className={clsx(styles.track, className)}>
      <div
        className={clsx(styles.dot, secondary && styles.purpleDot)}
      ></div>
      <div
        className={clsx(
          styles.dot,
          styles.dotB,
          secondary && styles.purpleDot
        )}
      ></div>
      <div
        className={clsx(
          styles.dot,
          styles.dotC,
          secondary && styles.purpleDot
        )}
      ></div>
    </div>
  )
}
