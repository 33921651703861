import React, { ComponentClass, createElement, FunctionComponent } from 'react'
import { Placeholder } from '@sitecore-jss/sitecore-jss-react'

import HomePage from './home'
import AboutPage from './about'
import MajorPage from './major'
import MinorPage from './minor'
import ArticlePage from './article'
import PolicyPage from './policy'
import LegalPage from './legal'
import BlogPage from './blog'
import BlogHomePage from './blog-home'
import BlogAuthorPage from './blog-author'
import CategoryLandingPage from './category-landing'
import TagResultsPage from './tag-results'
import ErrorPage from './error'
import PartnerPage from './partner'
import SearchPage from './search'
import PolicyFinder from './policy-recommendation'
import { PageTypes } from '../constants/page-type'

type Page = FunctionComponent<any> | ComponentClass<any>

const templateComponentMapping = new Map<string, Page>()
templateComponentMapping.set(PageTypes.HOME_PAGE, HomePage)
templateComponentMapping.set(PageTypes.ABOUT_PAGE, AboutPage)
templateComponentMapping.set(PageTypes.POLICY_PAGE, PolicyPage)
templateComponentMapping.set(PageTypes.ARTICLE_PAGE, ArticlePage)
templateComponentMapping.set(PageTypes.MAJOR_PAGE, MajorPage)
templateComponentMapping.set(PageTypes.MINOR_PAGE, MinorPage)
templateComponentMapping.set(PageTypes.LEGAL_PAGE, LegalPage)
templateComponentMapping.set(PageTypes.BLOG_PAGE, BlogPage)
templateComponentMapping.set(PageTypes.BLOG_HOME_PAGE, BlogHomePage)
templateComponentMapping.set(PageTypes.BLOG_AUTHOR_PAGE, BlogAuthorPage)
templateComponentMapping.set(PageTypes.CATEGORY_LANDING_PAGE, CategoryLandingPage)
templateComponentMapping.set(PageTypes.TAG_RESUTLS_PAGE, TagResultsPage)
templateComponentMapping.set(PageTypes.TEST_PAGE, TestPage)
templateComponentMapping.set(PageTypes.ERROR_PAGE, ErrorPage)
templateComponentMapping.set(PageTypes.PARTNER_PAGE, PartnerPage)
templateComponentMapping.set(PageTypes.SEARCH_PAGE, SearchPage)
templateComponentMapping.set(PageTypes.POLICY_RECOMMENDATIONS_PAGE, PolicyFinder)

type PageTypeProps = {
  route: {
    templateName: string
  }
}

export default ({ route }: PageTypeProps) => {
  const pageType = templateComponentMapping.get(route.templateName)

  if (!pageType) {
    return null
  }

  return createElement(pageType, { route })
}

function TestPage({ route }: any) {
  return <Placeholder name="jss-main" rendering={route} />
}
