import React from 'react'
import clsx from 'clsx'
import { dataMap } from '.'
import LinkWrapper from '../LinkWrapper'
import styles from './styles.module.scss'
import { PolicyRecommendationCTAProps } from './types'
import Media from '../Media'
import { LinkAttrs } from '../LinkWrapper/LinkTypes'
import { PRT_CTA_URL } from '../../constants/policy-recommendation'

export const CTAContent: React.FC<PolicyRecommendationCTAProps> = props => {
  const {
    variant = 'flex',
    alignItems = 'Center',
    direction = 'column',
    wrapClassName,
    theme = "light"
  } = props
  const { ctaText, promotionalText } = dataMap(props)

  const attrs: LinkAttrs = {
    'data-tag': 'prt-cta',
    'data-test-id': 'prt-cta-link-text',
  }

  return variant === 'flex' ? (
    <div
      data-test-id={'prt-cta'}
      className={clsx(
        styles.root,
        styles[direction],
        styles[alignItems],
        styles[variant],
        wrapClassName
      )}
    >
      <div className={styles.promotionalText}>{promotionalText.value}</div>
      <LinkWrapper
        additionalProps={attrs}
        className={styles.ctaLinkWrap}
        text={ctaText.value}
        title={ctaText.value}
        href={PRT_CTA_URL}
      />
    </div>
  ) : (
    <div data-test-id={'prt-cta'} className={clsx(styles[variant], styles[theme])}>
      <Media
        mobile={() => (
          <LinkWrapper
            additionalProps={attrs}
            className={styles.ctaLinkWrapDefault}
            text={
              <>
                {promotionalText.value} <br></br> {ctaText.value}
              </>
            }
            href={PRT_CTA_URL}
          />
        )}
        tablet={() => (
          <LinkWrapper
            additionalProps={attrs}
            className={styles.ctaLinkWrapDefault}
            text={
              <>
                {promotionalText.value} {ctaText.value}
              </>
            }
            href={PRT_CTA_URL}
          />
        )}
      />
    </div>
  )
}
