import React, { createContext, useEffect, useState } from 'react'
import {
  AdditionalPolicyResType,
  PolicyResType,
} from '../services/policy-lookup/types'
import { isEmpty, isEqual } from 'lodash-es'
import { FC } from '../../../types/common'

/**
 * Types
 */
interface PolicyRecommendationProviderProps {
  showResults: boolean
  setShowResults: React.Dispatch<React.SetStateAction<boolean>>
  selectedPolicies: string[]
  setSelectedPolicies: React.Dispatch<React.SetStateAction<string[]>>
  recommendedPolicies: RecommendedPoliciesType
  setRecommendedPolicies: React.Dispatch<
    React.SetStateAction<RecommendedPoliciesType>
  >
}
type RecommendedPoliciesType = {
  main: PolicyResType[]
  additional: AdditionalPolicyResType[]
}

/**
 * Init Value
 */

const recPolicyInitValue = { main: [], additional: [] }

const initValue = {
  showResults: false,
  recommendedPolicies: recPolicyInitValue,
  selectedPolicies: [],
} as Partial<PolicyRecommendationProviderProps>

/**
 * Context
 */

export const PolicyRecommendationContext = createContext<
  PolicyRecommendationProviderProps
>(initValue as PolicyRecommendationProviderProps)

/**
 * Provider
 */

export const PolicyRecommendationProvider: FC<{
  recommendedPoliciesVal: RecommendedPoliciesType
  selectedPoliciesVal: string[]
}> = ({
  children,
  recommendedPoliciesVal = recPolicyInitValue,
  selectedPoliciesVal = [],
}) => {
  /**
   * Constants
   */
  const [showResults, setShowResults] = useState<boolean>(false)
  const [selectedPolicies, setSelectedPolicies] = useState<string[]>(
    selectedPoliciesVal
  )
  const [recommendedPolicies, setRecommendedPolicies] = useState<
    RecommendedPoliciesType
  >(recommendedPoliciesVal)
  const value = {
    showResults,
    setShowResults,
    selectedPolicies,
    setSelectedPolicies,
    recommendedPolicies,
    setRecommendedPolicies,
  }

  /**
   * Effects
   */
  useEffect(() => {
    if (
      !isEmpty(recommendedPoliciesVal.main) &&
      !isEmpty(recommendedPoliciesVal.additional)
    ) {
      setRecommendedPolicies(recommendedPoliciesVal)
      setShowResults(true)
      window.scroll(0, 0) // Scrolls to top
    } else {
      setShowResults(false)
    }
  }, [recommendedPoliciesVal])

  useEffect(() => {
    if (!isEqual(selectedPolicies, selectedPoliciesVal))
      setSelectedPolicies(selectedPoliciesVal)
  }, [selectedPoliciesVal])

  return (
    <PolicyRecommendationContext.Provider value={value}>
      {children}
    </PolicyRecommendationContext.Provider>
  )
}

export default PolicyRecommendationProvider
