import * as React from 'react'

// Detect clicks on link elements inside of Richtext areas.
// We check for two cases:
// case: target element ia <a> with href
// case: target element is <em> with <a> as parent
//
// When we find an href, we run a check on it, and if it matches,
// call a callback and cancel the default link event

type InterceptLinksProps = {
  checkLink: (href: string) => boolean
  onChange: () => void
  children: React.ReactNode
}

export default function InteceptLinks({
  checkLink,
  onChange,
  children,
}: InterceptLinksProps) {
  const handleAnchor = (ev: React.MouseEvent, anchor: HTMLAnchorElement) => {
    if (checkLink(anchor.href)) {
      ev.stopPropagation()
      ev.preventDefault()
      onChange()
    }
  }

  const handleClick = (ev: React.MouseEvent) => {
    const el = ev.target as HTMLElement
    const anchor = extractAnchor(el)

    if (anchor) {
      handleAnchor(ev, anchor)
    }
  }

  return <div onClick={handleClick}>{children}</div>
}

function extractAnchor(el: HTMLElement) {
  if (el.tagName === 'A') {
    const anchor = el as HTMLAnchorElement
    if (anchor.href) {
      return anchor
    }
  }

  // sometimes you have an <em> or a <span> or some other wrapping element inside the <a>
  // so we check for an <a> parent to catch most of the cases
  if (el.parentElement && el.parentElement.tagName === 'A') {
    // we're checking for the kind of element in the line above
    const anchor = el.parentElement as HTMLAnchorElement
    if (anchor.href) {
      return anchor
    }
  }

  return false
}
