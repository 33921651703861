import { once } from 'lodash-es'
import * as DataLayer from '../../../../analytics/google-analytics'
import { EventName } from './events'
import { getEvent, getEventWithValue } from './google-analytics'
import {
  triggerSitecoreEvent,
  triggerSitecoreAppStart,
} from './sitecore-analytics'
import errorOnTimeout from '../../../../analytics/timeout'
import { AnalyticsEvent } from '../state-management/states'

export const triggerInputClick = once(() => {
  triggerSitecoreEvent(EventName.InputClick)
  DataLayer.toDataLayer(getEvent(EventName.InputClick))
})

export const triggerButtonClick = once((label = '') => {
  triggerSitecoreEvent(EventName.ButtonClick)
  DataLayer.waitForDataLayer(getEventWithValue(EventName.ButtonClick, label))
})

export const triggerFirstLetter = once(() => {
  triggerSitecoreEvent(EventName.FirstLetter)
  DataLayer.toDataLayer(getEvent(EventName.FirstLetter))
})

export const triggerNoResults = once((searchInput: string) => {
  triggerSitecoreEvent(EventName.NoResults)
  DataLayer.waitForDataLayer(
    getEventWithValue(EventName.NoResults, searchInput)
  )
})

export const triggerQuote = once((label: string) =>
  DataLayer.waitForDataLayer(getEventWithValue(EventName.Quote, label))
)

export const triggerDrop = (label: string | false) => {
  triggerSitecoreEvent(EventName.Drop)
  DataLayer.toDataLayer(
    getEventWithValue(EventName.Drop, label ? label : 'EMPTY')
  )
}

export const triggerEscapeLink = once(() => {
  return errorOnTimeout(
    Promise.all([
      triggerSitecoreEvent(EventName.EscapeLink),
      DataLayer.waitForDataLayer(getEvent(EventName.EscapeLink)),
    ])
  )
})

export const triggerEventWithValue = ({ type, label }: AnalyticsEvent) => {
  DataLayer.toDataLayer(getEventWithValue(type, label))
}

export function appStartAnalytics(label: string) {
  const tags: Promise<void | Response>[] = [triggerSitecoreAppStart()]

  const gaTag = triggerQuote(label)

  tags.push(gaTag)

  return errorOnTimeout(Promise.all(tags))
}

export type AppStartMeta = {
  professionLabel: string
  webBusClassId: string | number
  searchInput: string | false
  direct: boolean
}

// Get a label tagged with the kind of search (Search / Direct / Scroll),
// the profession label, business class and search input
export function getQuoteLabel({
  professionLabel,
  webBusClassId,
  searchInput,
  direct,
}: AppStartMeta) {
  return searchInput
    ? formatList('Search', professionLabel, webBusClassId, searchInput)
    : formatList(direct ? 'Direct' : 'Scroll', professionLabel, webBusClassId)
}

function formatList(...args: (string | number)[]): string {
  return args.join('; ')
}
