import React, { ReactNode } from 'react'
import Dots from './Dots'
import clsx from 'clsx'

import styles from './styles.module.scss'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

export type ButtonSize =
  | 'slim'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge'
  | 'xxxlarge'

export type ButtonProps = {
  children?: ReactNode
  onClick?: (ev: any) => void
  primary?: boolean
  secondary?: boolean
  disabled?: boolean
  error?: boolean
  loading?: boolean
  size?: ButtonSize
  type?: string
  className?: string
  testId?: string
  title?: string
  href?: string
  target?: string
}

type AllowedAttributes = {
  'data-test-id'?: string
  disabled?: boolean
}

const defaultProps = {
  primary: true,
  secondary: false,
  disabled: false,
  error: false,
  loading: false,
  type: '',
}

const NewButton = (props: ButtonProps = {}) => {
  const {
    type,
    size,
    children,
    disabled,
    error,
    onClick,
    className,
    loading,
    testId,
    title,
    href,
    target,
    primary,
    secondary,
  } = props

  const primaryButton = primary || type === 'primary' || type === ''
  const secondaryButton = secondary || type === 'secondary'

  const htmlAttributes: AllowedAttributes = {}

  const _onClick = (e: any) => {
    if (disabled) {
      return
    }

    if (onClick) {
      onClick(e)
    }
  }

  if (disabled || loading) {
    htmlAttributes['disabled'] = true
  }

  if (testId) {
    htmlAttributes['data-test-id'] = testId
  }

  const styleClasses = {
    [styles.slim]: size === 'slim',
    [styles.medium]: size === 'medium',
    [styles.large]: size === 'large',
    [styles.xlarge]: size === 'xlarge',
    [styles.primary]: primaryButton && !secondaryButton,
    [styles.secondary]: secondaryButton,
    [styles.enabled]: !disabled,
    [styles.loading]: loading,
    [styles.error]: error,
  }

  const buttonBody = (
    <span className={styles.title}>
      {loading ? (
        <LoadingBody secondary={secondaryButton}>{children}</LoadingBody>
      ) : (
        children
      )}
    </span>
  )

  if (href) {
    return (
      <a
        {...(disabled ? {} : { href })}
        target={target}
        title={title}
        className={clsx(styles.buttonQ221, styleClasses, className, 'Button')}
        {...htmlAttributes}
        onClick={_onClick}
      >
        {buttonBody}
      </a>
    )
  }

  return (
    <button
      className={clsx(styles.buttonQ221, styleClasses, className, 'Button')}
      {...htmlAttributes}
      onClick={_onClick}
    >
      {buttonBody}
    </button>
  )
}

NewButton.defaultProps = defaultProps

function LoadingBody({
  children,
  secondary,
}: {
  children: React.ReactNode
  secondary: boolean
}) {
  return (
    <>
      {children} <Dots className={styles.dots} secondary={secondary} />
    </>
  )
}

export default withErrorBoundary(NewButton, { component: 'Button' })
