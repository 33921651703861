import React from 'react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import Media from '../Media'
import { PolicyRecommendationCTAProps } from './types'
import { usePolicyRecommendationCTA } from './services/usePolicyRecommendationCTA'
import { CTAContent } from './Content'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

export const dataMap = (data: PolicyRecommendationCTAProps) =>
  data.sitecoreContext.route.fields.policyRecommendationCtaSettings.fields

const PolicyRecommendationCTA: React.FC<PolicyRecommendationCTAProps> = (
  props
) => {
  if (props.sitecoreContext.route.fields.policyRecommendationCtaSettings) {
    const { visibleOn = 'desktop' } = props
    const { isVisible } = usePolicyRecommendationCTA(props)

    return isVisible ? (
      <Media
        desktop={() =>
          visibleOn.includes('desktop') && <CTAContent {...props} />
        }
        tablet={() => visibleOn.includes('tablet') && <CTAContent {...props} />}
        mobile={() => visibleOn.includes('mobile') && <CTAContent {...props} />}
        laptop={() => visibleOn.includes('laptop') && <CTAContent {...props} />}
      />
    ) : null
  }

  return null
}

export default withErrorBoundary(
  withSitecoreContext()(PolicyRecommendationCTA),
  { component: 'Policy-Recommendation-CTA' }
)
