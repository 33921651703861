import React, { FC, ReactNode } from 'react'
import Step from './components/Step'
import styles from './styles.module.scss'
import { toLower } from 'lodash-es'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

interface StepperProps {
  /**
   * A unique identifier for the stepper
   */
  id: string
  /**
   * A number of active step
   */
  activeStep?: number
  /**
   * A list of steps
   */
  steps: ReactNode[]
  /**
   * A Unique Identifier for Testing
   */
  testId?: string
}

const Stepper: FC<StepperProps> = ({ activeStep, steps, id, testId }) => {
  return (
    <div data-test-id={testId || id} className={styles.stepper}>
      {steps.map((step, index) => (
        <Step
          key={toLower(id.trim()) + index}
          id={toLower(id.trim()) + index}
          isActive={activeStep === index + 1}
        >
          {step}
        </Step>
      ))}
    </div>
  )
}

export default withErrorBoundary(Stepper, { component: 'Stepper' })
