import React from 'react'
import {
  TextField,
  BooleanField,
  RouteFields,
} from '../../types/SitecoreAdapter'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'

import TrustPilotWidget from '../TrustPilot-Widget/TrustPilotWidget'
import styles from './styles.module.scss'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

export type CustomerTestimonialProps = {
  fields: {
    heading: TextField
    trustPilotWidgetType?: TextField // Type of TrustPilot widget (i.e. Grid,Carousel, etc.)
  }
} & RouteFields<{
  tpWidgetGlobalSettings: {
    fields: {
      replaceCustomerTestimonial: BooleanField
    }
  }
}>

const CustomerTestimonial = ({
  fields: { heading, trustPilotWidgetType = { value: 'Carousel' } },
  sitecoreContext: {
    route: {
      fields: { tpWidgetGlobalSettings },
    },
  },
}: CustomerTestimonialProps) => {
  return (
    <TrustPilotWidget
      heading={heading}
      type={trustPilotWidgetType.value}
      externalStyles={styles.tpHomePageReplacement}
    />
  )
}

export default withErrorBoundary(withSitecoreContext()(CustomerTestimonial), {
  component: 'Q221-Customer-Testimonial',
})
