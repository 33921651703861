import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { PageTypes } from '../constants/page-type'

/**
 * A Hook to get the type of page from Sitecore Context
 * @returns Name of route template as PageTypes
 */
export const usePageType = () => {
  const {
    sitecoreContext: { route },
  } = useSitecoreContext()

  return route?.templateName as PageTypes
}
