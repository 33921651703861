import React from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import {
  BooleanField,
  TextField,
  ImageField,
} from '../../../types/SitecoreAdapter'
import AboutHeroBase from './index.base'
import { getResponsiveHeroImage } from '../../../components/Responsive-Image/HeroImageAdapter'
import { ResponsiveImageProps } from '../../../components/Responsive-Image'

export type AboutHeroProps = {
  backgroundImage: ImageField
  heading: TextField
  descriptor: TextField
  isDark: BooleanField
  showTrustPilotWidget?: BooleanField
  enableTrustPilotHeroWidget?: BooleanField
} & ResponsiveImageProps

const AboutHero = ({
  backgroundImage,
  mobileImage,
  tabletImage,
  laptopImage,
  desktopImage,
  heading,
  descriptor,
  isDark,
  showTrustPilotWidget,
  enableTrustPilotHeroWidget,
}: AboutHeroProps) => {
  const responsiveImage = getResponsiveHeroImage({
    mobileImage,
    tabletImage,
    laptopImage,
    desktopImage,
    backgroundImage,
  })

  const showTPWidget =
    (enableTrustPilotHeroWidget && enableTrustPilotHeroWidget.value) ||
    (showTrustPilotWidget && showTrustPilotWidget.value)

  return (
    <AboutHeroBase
      showTrustPilotWidget={showTPWidget}
      title={<Text field={heading} tag="h1" />}
      subtitle={descriptor && <Text field={descriptor} tag="div" />}
      backgroundImage={responsiveImage}
      isDark={isDark.value}
    />
  )
}

export default AboutHero
