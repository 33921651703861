import React from 'react'
import ContactBar from './ContactBar'
import CallCard from './CallCard'
import EmailCard from './EmailCard'
import PhoneLink from './PhoneLink'
import { Image, Text } from '@sitecore-jss/sitecore-jss-react'
import { ImageField, TextField } from '../../types/SitecoreAdapter'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type ContactBarProps = {
  fields: {
    callIcon: ImageField
    callLabel: TextField
    callPhoneNumber: TextField
    emailIcon: ImageField
    emailLabel: TextField
    emailAddress: TextField
  }
}

function ContactBarAdapter({
  fields: {
    callIcon,
    callLabel,
    callPhoneNumber,
    emailIcon,
    emailLabel,
    emailAddress,
  },
}: ContactBarProps) {
  return (
    <>
      <PhoneLink phoneNumber={callPhoneNumber.value} />
      <ContactBar>
        <CallCard
          icon={<Image field={callIcon} />}
          label={<Text field={callLabel} />}
          phoneNumber={callPhoneNumber.value}
        />
        <EmailCard
          icon={<Image field={emailIcon} />}
          label={<Text field={emailLabel} />}
          emailAddress={emailAddress.value}
        />
      </ContactBar>
    </>
  )
}

export default withErrorBoundary(ContactBarAdapter, {
  component: 'Contact-Bar',
})
