import React from 'react'
import Helmet from 'react-helmet'
import { RouteFields, TextField } from '../../types/SitecoreAdapter'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'

type JsonLdProps = RouteFields<{
  jsonLd: TextField
}>

// Machine Readable Linked Data for the website.
// Loaded as a script with the layout
const JsonLd = ({
  sitecoreContext: {
    route: {
      fields: { jsonLd },
    },
  },
}: JsonLdProps) => {
  return (
    <Helmet>
      <script type="application/ld+json">{jsonLd.value}</script>
    </Helmet>
  )
}

export default withSitecoreContext()(JsonLd)
