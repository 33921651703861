import React from 'react'

import styles from './styles.module.scss'
import clsx from 'clsx'
import LinkWrapper from '../../../../components/LinkWrapper'
import LeadRoutingNumber from '../../../../components/Lead-Routing-Number-Attribution'

export type PolicyLinkProps = {
  fields: {
    canonical: { value: string }
    heading: { value: string }
    pageDescription: { value: string }
  }
}

export type BreadCrumbProps = {
  fields: {
    title: {
      value: string
    }
    linkTitle: {
      value: string
    }
  }
}

export type IndustryLinkProps = {
  fields: {
    canonical: { value: string }
    breadcrumbs: BreadCrumbProps[]
  }
  isEscapeLink?: boolean
}

export type NullResultProps = {
  isError?: boolean
  searchTerm: string
  policies: PolicyLinkProps[]
  industries: IndustryLinkProps[]
  industryEscapeLink: IndustryLinkProps
}

function PolicyLinkItem(props: PolicyLinkProps) {
  return (
    <div className={styles.policy}>
      <LinkWrapper
        className={styles.link}
        href={props.fields.canonical.value}
        text={props.fields.heading.value}
      />
      <p className={styles.description}>{props.fields.pageDescription.value}</p>
    </div>
  )
}

function IndustryLinkItem({ fields, isEscapeLink }: IndustryLinkProps) {
  const linkItemData: BreadCrumbProps | null =
    fields.breadcrumbs[fields.breadcrumbs.length - 1]

  const linkText = isEscapeLink
    ? 'See more industries we insure'
    : linkItemData && linkItemData.fields.title.value

  const linkTitle = linkItemData && linkItemData.fields.linkTitle.value

  return (
    <div className={styles.industry}>
      <LinkWrapper
        className={clsx(styles.link, isEscapeLink ? styles.boldLink : '')}
        href={fields.canonical.value}
        text={linkText}
        title={linkTitle}
      />
    </div>
  )
}

function NullResults({
  isError,
  searchTerm,
  policies,
  industries,
  industryEscapeLink,
}: NullResultProps) {
  const policyItems = policies.map((item, index) => (
    <PolicyLinkItem key={index} {...item} />
  ))

  const industryItems = industries.map((item, index) => (
    <IndustryLinkItem key={index} {...item} />
  ))

  return (
    <div className={styles.resultsContainer}>
      <div className={styles.resultList}>
        {!isError && (
          <p className={styles.error}>
            Unfortunately, your search for "{searchTerm}", has no matching
            results. Please use another keyword or choose from other popular
            options below or give us a call at <LeadRoutingNumber testId={'SiteSearch-LeadRouteNumber'} />.
          </p>
        )}
        {isError && (
          <p className={styles.error}>
            Unfortunately, there was an error performing your search. Please try
            again or choose from other popular options below.
          </p>
        )}

        <p className={styles.policyHeader}>Insurance policies</p>
        <div className={styles.policyList}>{policyItems}</div>

        <p className={styles.industryHeader}>Industries we insure</p>
        <div className={styles.industryList}>
          {industryItems}
          <IndustryLinkItem
            key="escape"
            isEscapeLink={true}
            fields={industryEscapeLink.fields}
          />
        </div>
      </div>
    </div>
  )
}

export default NullResults
