import React, { Suspense } from 'react'
import loadable from '@loadable/component'
import {
  Placeholder,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react'
import Nav from '../../page-components/Navigation'
import Banner from '../../components/Banner'
import CTABanner from '../../components/CTA-Banner'
import PolicyDescription from '../../components/Policy-Description'
import PageDescription from '../../components/Page-Description'
import Hero from './components/Hero'
import BreadcrumbsFromRoute from '../../page-components/Breadcrumb/BreadcrumbsFromRoute'
import BreadcrumbList, {
  BreadcrumbLink,
} from '../../page-components/Breadcrumb/BreadcrumbList'
import ArticleHeading from './components/ArticleHeading'
import IconTabAdapter from '../../page-components/IconTabs/Adapter'
import UpdatedDate from '../../page-components/UpdatedDate'
import LearnMoreSection from './components/LearnMoreSection'
import SocialSection from './components/SocialSection'

import { ArticlePageProps } from './domain'

import styles from './styles.module.scss'

const CTAAppStartSwitch = loadable(
  () => import('../../quotes/CTAAppStartSwitch')
)
const TestMetadata = loadable(() => import('../../components/Test-Metadata'))

function ArticlePage({ sitecoreContext: { route } }: ArticlePageProps) {
  const policy = route.fields.policy ? (
    <PolicyDescription
      testId="ArticlePage-PolicyDescription"
      fields={route.fields.policy.fields}
    />
  ) : (
    <PageDescription pageDescription={route.fields.pageDescription} />
  )

  const ctaBanner = route?.fields?.ctaBanner && (
    <CTABanner
      testId="ArticlePage-CTABanner"
      {...route.fields.ctaBanner.fields}
    >
      <CTAAppStartSwitch />
    </CTABanner>
  )

  return (
    <React.Fragment>
      <Nav />
      <Banner />
      <Hero hasPolicyRecCTA={true} />
      <Placeholder name="Hero-Placeholder" rendering={route} />
      <TestMetadata pageType="Article" appStartType="Typeahead" />
      <Suspense>
        <div className="copy-article">
          <BreadcrumbsFromRoute>
            {(breadcrumbs: BreadcrumbLink[]) => (
              <BreadcrumbList
                showWrap={true}
                breadcrumbs={breadcrumbs}
                isSticky={true}
                className={styles.breadcrumb}
                paddedLeft
                showCurrentPageCrumb={false}
              />
            )}
          </BreadcrumbsFromRoute>
          <div>
            {route.fields.iconTabs && (
              <IconTabAdapter tabs={route.fields.iconTabs} />
            )}
            <ArticleHeading />

            {policy}

            <Placeholder name="Article-Placeholder" rendering={route} />

            <UpdatedDate
              date={route.fields.updatedDate}
              fallbackDate={route.fields.lastPublishedDate}
            />

            {ctaBanner}

            <LearnMoreSection />

            <SocialSection />
          </div>
        </div>
      </Suspense>
    </React.Fragment>
  )
}

export default withSitecoreContext()(ArticlePage)
