import React from 'react'
import clsx from 'clsx'

import SearchAppStartContext from '../../quotes/experiences/search-app-start/context'
import SearchAppStart from '../../quotes/experiences/search-app-start/embeds/cta'

import styles from './styles.module.scss'
import spaceStyles from '../../styles/space.module.scss'
import { TextField } from '../../types/SitecoreAdapter'
import { Text } from '@sitecore-jss/sitecore-jss-react'

type BottomCtaProps = {
  heading: TextField
}

const BottomCTA = ({ heading }: BottomCtaProps) => {
  return (
    <div
      data-test-id="Bottom-CTA"
      className={clsx(spaceStyles.contain, 'block', styles.root, 'BottomCTA')}
    >
      <Text
        field={heading}
        tag="h3"
        className={clsx(styles.titleContainer, 'h2')}
      />
      <SearchAppStartContext>
        <SearchAppStart
          appStartKey="BottomCTA"
          showEscapeLink={false}
          rootClass={styles.appRoot}
        />
      </SearchAppStartContext>
    </div>
  )
}

export default BottomCTA
