import React, { useContext } from 'react'
import clsx from 'clsx'
import ModalCTA from '../../../../components/Modal-CTA/ModalCTA'
import { NavigationContext } from '../../services/context'
import { NavigationMenu } from '../../types'
import styles from './styles.module.scss'

const getTitle = (menu: NavigationMenu) => {
  return (menu && menu.startQuote && menu.startQuote.title) || ''
}

const QuoteModalButton = () => {
  const { menu } = useContext(NavigationContext)

  return (
    <ModalCTA
      buttonClassName={clsx('button', styles.modalButton)}
      wrapClassName={styles.modalButtonWrap}
      alignment={'Left'}
      primary={true}
      size="large"
      testId="navButtonCTA"
    >
      {getTitle(menu)}
    </ModalCTA>
  )
}

export default React.memo(QuoteModalButton)
