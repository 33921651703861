import { useState, useEffect } from 'react'

export type InlineStyles = {
  top?: string
}

type ReturnValue = [InlineStyles, number|null]

// We have modals of different heights as part of the typeahead workflow.
// For example, the results screen modal has search results, and maxes at about 500px tall.
// The confirmation screen has just an input and a next button and is shorter.
// We want the tops of the modals to sit at the same point on the screen.
// This ensures that the input box doesn't shift up and down.
// This hook sets, in pixels via inline styles, the top positioning of the modal
// to a fixed spot that will work for both the tall and short modals.
export function useFixedModalTop() {
  const [fixedPxFromTop, setFixedPxFromTop] = useState<number|null>(null)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const maxModalHeight = 500
      const fromTop = Math.round((window.innerHeight - maxModalHeight) / 2)
      setFixedPxFromTop(fromTop)
    }
  }, [])
	
	const styles : InlineStyles = fixedPxFromTop
		? { top: `${fixedPxFromTop}px` }
		: {}
	
	const result : ReturnValue = [styles, fixedPxFromTop]

  return result
}
