import { gql } from 'graphql-request'

export const SEARCH_PAGE = '/search';

export const PAGE_SIZE = 15;

export const SEARCH_QUERY = gql`
  query ($query: String!, $cursor: String!, $pageSize: Int!){
    siteSearch(
      after: $cursor
      first: $pageSize,
      query: $query
    ) {
      results {
        items {
          canonical
          description
          title
        }
        pageInfo {
          endCursor
          startCursor
        }
        totalCount
      }
    }
  }`