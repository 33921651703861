import React from 'react'

import styles from './styles.module.scss'
import clsx from 'clsx'
import { TP_AdditionalProps } from '../../TrustPilotWidget'
import { PageTypes } from '../../../../constants/page-type'
import { usePageType } from '../../../../util/usePageType'
import { getPageStyles } from '../../../PageTypography'

const GridWidget = React.forwardRef(
  (props: TP_AdditionalProps, ref: React.Ref<HTMLDivElement>) => {
    const pageType = usePageType()
    const isHomePage = pageType === PageTypes.HOME_PAGE

    return (
      <div
        className={clsx(
          'TrustPilotGridWidget',
          !isHomePage && 'contain',
          styles[`tpWidget_${getPageStyles(pageType)}`]
        )}
        data-test-id={'grid-tp-widget'}
      >
        <div className={clsx(styles.widgetContainer)} id="widgetContainer">
          <div
            id={'trustBox'}
            ref={ref}
            data-locale="en-US"
            data-template-id="539adbd6dec7e10e686debee"
            data-businessunit-id="545aa5c800006400057b5df7"
            data-style-width="100%"
            data-theme="light"
            data-stars="3,4,5"
            data-review-languages="en"
          ></div>
        </div>
      </div>
    )
  }
)

export default GridWidget
