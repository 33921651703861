import * as React from 'react'
import styles from './styles.module.scss'
import { LinkWrapperWithChildItems } from '../LinkWrapper'

export default function PhoneLink({ phoneNumber }: { phoneNumber: string }) {
  const phoneUrl = `tel:${phoneNumber}`
  return (
    <LinkWrapperWithChildItems href={phoneUrl} className={styles.phoneLink}>
      {phoneNumber}
    </LinkWrapperWithChildItems>
  )
}
