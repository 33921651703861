import React from 'react'
import { Image, Text, RichText } from '@sitecore-jss/sitecore-jss-react'
import styles from './styles.module.scss'
import spaceStyles from '../../styles/space.module.scss'
import clsx from 'clsx'
import { ImageField, TextField } from '../../types/SitecoreAdapter'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

export type PolicyDescriptionFields = {
  icon: ImageField
  title: TextField
  body: TextField
}

export type PolicyDescriptionItem = {
  fields: PolicyDescriptionFields
  testId?: string
}

const PolicyDescription = (props: PolicyDescriptionItem) => {
  return (
    <div
      data-test-id={props.testId || 'PolicyDescription'}
      className={clsx(spaceStyles.contain)}
    >
      <div className={styles.wrap}>
        <Image className={styles.img} field={props.fields.icon} />
        <dl className={styles.list}>
          <dt>
            <h3 className={clsx(`text ${styles.title}`)}>
              {props.fields.title.value}
            </h3>
          </dt>
          <dd className={styles.body}>
            <RichText field={props.fields.body} />
          </dd>
        </dl>
      </div>
    </div>
  )
}

export default withErrorBoundary(PolicyDescription, {
  component: 'Policy-Description',
})
