import React, { useContext } from 'react'
import { Status } from '../../state-management/states'
import {
  ConfirmSelection,
  ModalOpen,
  RejectSelection,
} from '../../state-management/events'
import { selectProfession } from '../../state-management/reducer'
import SelectAsync from '../../components/Select/SelectAsync'
import { AppStartSettingsContext } from '../../context'
import SearchAppStartContainer from '../../state-management/container'
import styles from './styles.module.scss'

// this is not ideal
import headingStyles from '../../../../../page-components/Modal/heading.module.scss'
import SelectInert from '../../components/Select/SelectInert'
import Button from '../../../../../components/Button'

export default function ModalSearchAppStart() {
  const {
    cookieError,
    modalHeading,
    confirmSelection,
    confirmButtonLabel,
  } = useContext(AppStartSettingsContext)

  return (
    <SearchAppStartContainer appStartKey='ModalSearchAppStart'>
      {({
        appState,
        dispatch,
        loadOptions,
        callbacks: { onInput },
      }) => {
        // cookie check fails
        if (appState.status === Status.BrowserError) {
          return cookieError
        }

        if (appState.status === Status.Inert) {
          dispatch(ModalOpen)
        }

        return (
          <div className={styles.root} data-test-context="SearchAppStart">
            <div className={headingStyles.heading}>{modalHeading}</div>
            {confirmBodyActive() || confirmBodyLoading() || modalBody()}
          </div>
        )

        function confirmBodyActive() {
          if (
            confirmSelection &&
            appState.status === Status.Searching &&
            appState.selection !== undefined
          ) {
            return (
              <div className={styles.confirmBody}>
                <SelectInert
                  className={styles.confirmSelect}
                  value={appState.selection}
                  onFocus={() => dispatch(RejectSelection)}
                />
                <Button
                  className={styles.confirmButton}
                  onClick={() => dispatch(ConfirmSelection)}
                  size="xlarge"
                  testId="ConfirmButton"
                >
                  {confirmButtonLabel}
                </Button>
              </div>
            )
          }
        }

        function confirmBodyLoading() {
          if (confirmSelection && appState.status === Status.AppStart) {
            return (
              <div className={styles.confirmBody}>
                <SelectInert
                  className={styles.confirmSelect}
                  value={appState.selection}
                  isDisabled
                />
                <Button
                  className={styles.confirmButton}
                  size="xlarge"
                  testId="ConfirmButton"
                  disabled
                  loading
                >
                  {confirmButtonLabel}
                </Button>
              </div>
            )
          }
        }

        function modalBody() {
          return (
            <div className={styles.horizontalSelectRoot}>
              <SelectAsync
                className={styles.select}
                loadOptions={loadOptions}
                onSelect={option => {
                  dispatch(selectProfession(option))
                }}
                onInput={onInput}
              /> 
            </div>
          )
        }
      }}
    </SearchAppStartContainer>
  )
}
