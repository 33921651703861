import React from 'react'
import {
  ComponentConsumerProps,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react'
import FeaturedPostsBase from './base'
import { FeaturedPostsFields } from './types'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type Props = ComponentConsumerProps & {
  fields: FeaturedPostsFields
  sitecoreContext: { pageEditing: boolean; route: { templateName: string } }
}

const FeaturedPosts = ({
  fields: { heading, cards },
  sitecoreContext: {
    pageEditing,
    route: { templateName },
  },
}: Props) => {
  return (
    <FeaturedPostsBase
      cards={cards}
      heading={heading}
      editing={pageEditing}
      pageType={templateName}
    />
  )
}

export default withErrorBoundary(withSitecoreContext()(FeaturedPosts), {
  component: 'Featured-Posts',
})
