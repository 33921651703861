import * as React from 'react'
import { Placeholder } from '@sitecore-jss/sitecore-jss-react'
import SectionBase from './Section'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type BackgroundColorItem = null | {
  fields: {
    color: {
      value: string
    }
  }
}

type SectionProps = {
  fields: {
    backgroundColor: BackgroundColorItem
    cssClass: {
      value: string
    }
  }
  rendering: any
}

function Section({
  fields: {
    backgroundColor: backgroundColorItem,
    cssClass: { value: cssClass },
  },
  rendering,
}: SectionProps) {
  return (
    <SectionBase
      backgroundColor={getBackgroundColor(backgroundColorItem)}
      className={cssClass}
    >
      <Placeholder name="Section-Content" rendering={rendering} />
    </SectionBase>
  )
}

function getBackgroundColor(backgroundColorItem: BackgroundColorItem) {
  if (backgroundColorItem === null || backgroundColorItem === undefined) {
    console.warn('Expected Section to have a background color item')
    return 'white'
  }

  return backgroundColorItem.fields.color.value
}

export default withErrorBoundary(Section, { component: 'Section' })
