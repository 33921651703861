import React from 'react'
import Helmet from 'react-helmet'

type TestMetadataProps = {
  pageType: string
  appStartType: string
}

function TestMetadata({ pageType, appStartType }: TestMetadataProps) {
  return (
    <Helmet>
      <meta name="PageType" content={pageType} />
      <meta name="AppstartType" content={appStartType} />
    </Helmet>
  )
}

export default TestMetadata