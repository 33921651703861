import React from 'react'
import Slider from 'react-slick'
import clsx from 'clsx'
import styles from './styles.module.scss'
import './styles.scss'
import '../../styles/slidedots.scss'
import ArrowImage from '../../images/arrow.png'
import { FeaturedPostsProps } from './types'

const CarouselNextArrow = (props: any) => {
  const { className, style, onClick } = props
  return (
    <img
      src={ArrowImage}
      width={48}
      height={48}
      style={{ ...style, width: '3rem', height: '3rem' }}
      onClick={onClick}
      className={clsx(className, styles.next)}
      alt="Carousel next arrow"
    />
  )
}

const CarouselPrevArrow = (props: any) => {
  const { className, style, onClick } = props
  return (
    <img
      src={ArrowImage}
      style={{ ...style, width: '3rem', height: '3rem' }}
      onClick={onClick}
      className={clsx(className, styles.prev)}
      alt="Carousel previous arrow"
    />
  )
}

const FeaturedPosts = ({ posts }: FeaturedPostsProps) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    prevArrow: <CarouselPrevArrow />,
    nextArrow: <CarouselNextArrow />,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    dotsClass: 'dots featuredDots',
  }

  return (
    <div className={styles.wrap}>
      <Slider {...settings}>{posts}</Slider>
    </div>
  )
}

export default FeaturedPosts
