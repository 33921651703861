import React, { ReactNode } from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'
import { useShowBoxBorder } from './useShowBoxBorder'

type BreadcrumbProps = {
  children: ReactNode
  showWrap?: boolean
  innerClassName?: string
  isSticky?: boolean
  className?: string
  paddedLeft?: boolean
}

const Breadcrumb = ({
  children,
  showWrap = true,
  innerClassName,
  isSticky = true,
  paddedLeft = false,
  className,
}: BreadcrumbProps) => {
  // if there are no breadcrumbs, don't render
  if (!children) {
    return null
  }

  const showBoxBorder = isSticky && useShowBoxBorder()

  return (
    <div
      className={clsx(
        styles.root,
        isSticky && styles.isSticky,
        showBoxBorder && styles.showBoxShadow,
        className
      )}
    >
      <nav className={clsx(showWrap && styles.wrap)}>
        <div
          className={clsx(
            innerClassName,
            styles.crumbParent,
            paddedLeft && styles.crumbParentPaddedLeft
          )}
        >
          {children}
        </div>
      </nav>
    </div>
  )
}

export default Breadcrumb
