import React from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'
import Media from '../../components/Media'
import TrustPilotWidget from '../../components/TrustPilot-Widget/TrustPilotWidget'

type HeroProps = {
  backgroundImage: JSX.Element
  title: JSX.Element
  subtitle?: JSX.Element
  isDark?: boolean
  showTrustPilotWidget?: boolean
}

const BlogHeroBase = ({
  title,
  subtitle,
  backgroundImage,
  isDark = false,
  showTrustPilotWidget,
}: HeroProps) => {
  return (
    <div className={clsx(styles.hero, isDark && styles.dark)}>
      <div className={styles.image}>{backgroundImage}</div>
      <div className={styles.body}>
        <div className={clsx(showTrustPilotWidget && styles.headingContainer)}>
          <div className={clsx(styles.title)}>{title}</div>
          {showTrustPilotWidget && (
            <div
              className={styles.tpMicroHeroWidget}
              data-test-id={'blog-hero-tp-widget'}
            >
              <Media
                mobile={() => (
                  <TrustPilotWidget type={'microstar'} darkBg={isDark} />
                )}
                tablet={() => (
                  <TrustPilotWidget type={'microcombo'} darkBg={isDark} />
                )}
              />
            </div>
          )}
        </div>
        {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
      </div>
    </div>
  )
}

export default BlogHeroBase
