import React, { Suspense } from 'react'
import loadable from '@loadable/component'
import { Image, Placeholder, RouteData } from '@sitecore-jss/sitecore-jss-react'
import clsx from 'clsx'

import Nav from '../../page-components/Navigation'
import Banner from '../../components/Banner'
import Hero, { HeroProps } from '../../page-components/Hero'
import HeroAppStartSwitch from '../../quotes/HeroAppStartSwitch'
import CTABanner, { ICTABannerProps } from '../../components/CTA-Banner'
import Heading from '../../components/Heading'
import ValueProp from '../../components/ValueProp'
import MultiColumn from '../../components/MultiColumn/base'
import BreadcrumbsFromRoute from '../../page-components/Breadcrumb/BreadcrumbsFromRoute'
import BreadcrumbList from '../../page-components/Breadcrumb/BreadcrumbList'
import styles from './styles.module.scss'
import spaceStyles from '../../styles/space.module.scss'
import { BooleanField, Item } from '../../types/SitecoreAdapter'
import { ICTABannerEscapeText } from '../../page-components/getEscapeText'
import { get } from 'lodash-es'

const CTAAppStartSwitch = loadable(
  () => import('../../quotes/CTAAppStartSwitch')
)
const TestMetadata = loadable(() => import('../../components/Test-Metadata'))

type MinorPageProps = {
  route: {
    fields: {
      hero: Item<HeroProps>
      ctaBanner: {
        fields: ICTABannerProps
      } & ICTABannerEscapeText
      valueBanner: any
      heading: any
      tpWidgetGlobalSettings: {
        fields: {
          enableTrustPilotHeroWidget: BooleanField
        }
      }
    }
  } & RouteData
}

const MinorPage = ({ route }: MinorPageProps) => {
  const { hero, ctaBanner, valueBanner, heading, tpWidgetGlobalSettings } =
    route.fields

  const { image, icon, title, body } = valueBanner.fields

  const { enableTrustPilotHeroWidget } = tpWidgetGlobalSettings.fields

  const headingComponent = heading.fields.title.value.length > 0 && (
    <Heading
      testId={'MinorPage-Heading'}
      fields={{
        title: heading.fields.title,
        tag: { fields: { tag: { value: 'h2' } } },
        descriptor: heading.fields.descriptor,
      }}
      addStyle={{ root: clsx(styles.topSpace, spaceStyles.contain) }}
    />
  )

  const valueBannerComponent = image.value.src &&
    icon.value.src &&
    title.value.length > 0 &&
    body.value.length > 0 && (
      <MultiColumn
        left={<Image field={image} className={styles.image} />}
        right={<ValueProp {...valueBanner.fields} />}
      />
    )

  const heroProps = {
    ...hero.fields,
    applyDefaultImgSizing: true,
    enableTrustPilotHeroWidget,
  }

  return (
    <>
      <Nav />
      <Banner />
      <Suspense>
        <div className="copy">
          <TestMetadata pageType="Minor" appStartType="Typeahead" />

          <Hero {...heroProps} className={styles.minorHero}>
            <HeroAppStartSwitch
              darkBg={get(hero, 'fields.isDark.value', false)}
            />
          </Hero>

          <Placeholder name="Hero-Placeholder" rendering={route} />

          <BreadcrumbsFromRoute>
            {(breadcrumbs) => (
              <BreadcrumbList
                showWrap={false}
                breadcrumbs={breadcrumbs}
                isSticky={false}
                paddedLeft
                className={styles.breadcrumb}
              />
            )}
          </BreadcrumbsFromRoute>

          {headingComponent}

          {valueBannerComponent}

          <Placeholder name="Minor-Placeholder" rendering={route} />

          {ctaBanner && (
            <CTABanner testId="MinorPage-CTABanner" {...ctaBanner.fields}>
              <CTAAppStartSwitch />
            </CTABanner>
          )}
        </div>
      </Suspense>
    </>
  )
}

export default MinorPage
