import React, { ReactNode } from 'react'
import Wrapper from './Wrapper'
import Sidebar from './Sidebar'
import Aside from './Aside'

type DesktopProps = {
  article: JSX.Element
  tagTopics: JSX.Element
  cta: JSX.Element
}

const Desktop = ({ article, tagTopics, cta }: DesktopProps) => (
  <Wrapper>
    <Sidebar main={article} bar={<Aside />} foot={tagTopics} />
    {cta}
  </Wrapper>
)

export default Desktop
