import { useEffect } from 'react'

const registered = new Set<string>()

// Forces state to reset after back navigation on iOS
// Be mindful to test going forward, and then back, multiple times
export default function useSafariPageLoadPersist(cb: () => void, name: string) {
  useEffect(() => {
    if (!registered.has(name)) {
      registered.add(name)

      console.log(`[useSafariPageLoadPersist: ${name}] attach listener`)
      window.addEventListener('pageshow', e => {
        if (e.persisted) {
          console.log(`[useSafariPageLoadPersist: ${name}] run callback`)
          cb()
        }
      })
    }
  })
}
