import React from "react"
import styles from "./../styles.module.scss";
import { isEmpty } from "lodash-es";
import { FC } from "../../../types/common";

interface StepProps {
  /**
   * Provide a unique idenifier for each individual step
   */
  id: string;
  /**
   * Provide a boolean value if step is active
   */
  isActive?: boolean;
}

const Step: FC<StepProps> = ({ id, isActive, children }) => {
  return (
    <>
      <input
        className={styles.stepper__input}
        id={id}
        type="radio"
        checked={isActive}
      />
      <div className={styles.stepper__step}>
        <label className={styles.stepper__button} htmlFor={id} />
        {!isEmpty(children) && <p className={styles.stepper__content}>{children}</p>}
      </div>
    </>
  );
}

export default Step;