import React from 'react'
import clsx from 'clsx'

import { RouterItemProps } from '../../types'
import styles from './styles.module.scss'

export default function({
  image,
  text,
  href,
  title,
  styleClassName = 'carousel',
  className = '',
}: RouterItemProps) {
  return (
    <div
      className={clsx(
        styleClassName,
        styleClassName === 'grid' ? styles.item : ''
      )}
    >
      <a
        href={href}
        title={title}
        className={clsx(className, styles.anchor)}
      >
        {image && <div className={styles.image}>{image}</div>}
        {text}
      </a>
    </div>
  )
}
