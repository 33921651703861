import React from 'react'
import Media from '../Media'
import Desktop from './Desktop'
import Mobile from './Mobile'
import { PostsByTagProps } from './types'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

const PostsByTag = (props: PostsByTagProps) => {
  return (
    <div data-test-id="PostsByTag-Wrap">
      <Media
        mobile={() => <Mobile {...props} />}
        tablet={() => <Desktop {...props} />}
      />
    </div>
  )
}

export default withErrorBoundary(PostsByTag, { component: 'Posts-By-Tag' })
