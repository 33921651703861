import React, { FC } from 'react'
import styles from './styles.module.scss'
import { ImageField } from '../../../../../../types/SitecoreAdapter'
import CalendlyWidgetFromRoute from '../../../../../../components/CalendlyWidget/CalendlyWidgetFromRoute'

export interface ScheduleCallCTAProps {
  icon?: ImageField['value']
  text: string
}

const ScheduleCallCTA: FC<ScheduleCallCTAProps> = ({ text, icon }) => {
  return (
    <div
      data-test-id={'prt-recommendation-calendly-cta'}
      className={styles.scheduleACallWrap}
    >
      <img className={styles.calendlyIcon} {...icon} />
      <CalendlyWidgetFromRoute
        textOverride={text}
        externalStyles={styles.scheduleLink}
      />
    </div>
  )
}

export default ScheduleCallCTA
