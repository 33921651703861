import React, { FC, useContext } from 'react'
import Modal from '../../../../../../../page-components/Modal'
import styles from './styles.module.scss'
import { ViewPolicyInfoModalContext } from '../../../../../context/ViewPolicyInfo'

const PolicyInfoModal: FC = () => {
  const { hideModal, isVisible, data } = useContext(ViewPolicyInfoModalContext)
  return (
    <>
      {isVisible && data ? (
        <Modal className={styles.viewMoreInfoModalWrap} onClose={hideModal}>
          <div className={styles.modalContent}>
            <div
              data-test-id={'prt-recommendation-policy-details-modal-heading'}
              className={styles.modalHeader}
            >
              <img {...data.icon} />
              <h3>{data.title}</h3>
            </div>
            <div
              className={styles.modalBody}
              dangerouslySetInnerHTML={{ __html: data.description }}
            />
          </div>
        </Modal>
      ) : null}
    </>
  )
}

export default PolicyInfoModal
