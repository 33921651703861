import React from 'react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import SearchAppStartContext from './experiences/search-app-start/context'
import SearchAppStart, { CTASearchAppStartProps } from './experiences/search-app-start/embeds/cta'

type HeroAppStartSwitchProps = {
  sitecoreContext: {
    route: any
  }
  hasPolicyRecCTA?: boolean
  darkBg?: boolean
  appStartKey?: string
} & {appStartProps?: CTASearchAppStartProps}

function HeroAppStartSwitch({
  hasPolicyRecCTA,
  darkBg,
  appStartProps,
  appStartKey = "HeroAppStartSwitch"
}: HeroAppStartSwitchProps) {
  return (
    <SearchAppStartContext darkBg={darkBg} hasPolicyRecCTA={hasPolicyRecCTA}>
      <SearchAppStart appStartKey={appStartKey} {...appStartProps} />
    </SearchAppStartContext>
  )
}

export default withSitecoreContext()(HeroAppStartSwitch)
