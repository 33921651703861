import React, {
  useContext,
  useReducer,
  useCallback,
  useRef,
  useState,
  useEffect,
} from 'react'
import RS_SelectAsync from 'react-select/async'
import { ActionMeta, InputActionMeta, SingleValue, Theme } from 'react-select'
import { IOption, OptionList } from '../../types'
import NoOptionsMessage from './NoOptionsMessage'
import ClearIndicator from './ClearIndicator'
import MenuList from './MenuList'
import { formatOptionLabel } from './services/formatLabel'
import { reducer, initialState, setInput, clearInput } from './services/reducer'
import { AppStartSettingsContext } from '../../context'
import { PatchableSelect } from './useInputIntercept'
import getSelectStyles from './styles'
import clsx from 'clsx'
import EmptyComponent from './EmptyComponent'
import { iPhoneScrollFix } from '../../iPhoneScrollFix'
import { SelectControl } from './Control'
import { isEmpty } from 'lodash'

interface ILoadOptionsCallback {
  loadOptions: (input: string) => Promise<OptionList>
}

type SelectAsyncOptions = ILoadOptionsCallback & {
  onInput?: (v: string) => void
  onSelect?: (option: IOption) => void
  hideSearchIconWhileTyping?: boolean
  className?: string
}

type InputChangePropType = (
  newValue: string,
  actionMeta: InputActionMeta
) => void

export default function SelectAsync({
  loadOptions,
  onSelect,
  onInput,
  hideSearchIconWhileTyping = false,
  className,
}: SelectAsyncOptions) {
  const [selectState, selectDispatch] = useReducer(reducer, initialState)
  const [professions, setProfessions] = useState<OptionList>([])
  const [filteredProfessions, setFilteredProfessions] = useState<OptionList>([])
  const { searchingPlaceholder, noResultsHeader, noResultsSuggestions } =
    useContext(AppStartSettingsContext)
  // console.log('[Insureon Select] state', selectState)
  const selectRef = useRef<PatchableSelect>(null)
  const defaultOptions = isEmpty(selectState.inputValue)
    ? [...professions]
    : [...filteredProfessions]

  /** Loads a list of options on initial render */
  useEffect(() => {
    loadOptions('').then((res) => {
      setProfessions([...res])
    })
  }, [])

  const handleClear = useCallback(() => {
    selectDispatch({ ...clearInput })
  }, [])

  const handleChange = useCallback<
    (newValue: SingleValue<IOption>, actionMeta: ActionMeta<IOption>) => void
  >(
    (value, { action }) => {
      if (action === 'select-option' && value) {
        onSelect && onSelect(value as IOption)
      }
    },
    [onSelect]
  )

  const handleInputChange = useCallback<InputChangePropType>(
    (inputText: string, { action }) => {
      // console.log('[Select Async] handle input change', inputText, action)
      if (action === 'input-change') {
        selectDispatch(setInput(inputText))

        onInput && onInput(inputText)
      }
      // if (action === 'input-blur') handleClear();
    },
    [onInput]
  )

  const ClearButtonFactory = () => {
    const canClear = selectState.inputValue || selectState.value
    return canClear ? <ClearIndicator onClick={handleClear} /> : null
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    switch (event.key) {
      case 'Escape':
        handleClear()
        break
      case 'Enter':
      case 'Tab':
        event.preventDefault()
        break
      case ' ':
        if (selectState.inputValue === '') {
          event.preventDefault()
          break
        }
    }
  }

  const hasSearchIcon = () =>
    !hideSearchIconWhileTyping || selectState.inputValue.length === 0

  const resolveTheme = (theme: Theme) => {
    return {
      ...theme,
      borderRadius: 30,
      colors: {
        ...theme.colors,
        primary: '#2B0985',
      },
    }
  }

  return (
    <RS_SelectAsync<IOption, false>
      value={selectState?.value}
      inputValue={selectState.inputValue}
      // cacheOptions
      defaultOptions={defaultOptions}
      loadOptions={(i, cb) => {
        loadOptions(i).then((res) => {
          setFilteredProfessions([...res])
          cb(res)
        })
      }}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onKeyDown={onKeyDown}
      formatOptionLabel={formatOptionLabel}
      autoFocus={true}
      menuIsOpen={true}
      placeholder={searchingPlaceholder}
      styles={getSelectStyles()}
      isInert={false}
      hasSearchIcon={hasSearchIcon()}
      lightText={true}
      theme={resolveTheme}
      noResultsHeader={noResultsHeader}
      noResultsSuggestions={noResultsSuggestions}
      components={{
        NoOptionsMessage: NoOptionsMessage,
        ClearIndicator: EmptyComponent,
        DropdownIndicator: ClearButtonFactory,
        IndicatorSeparator: EmptyComponent,
        Control: SelectControl,
        MenuList,
      }}
      ref={selectRef}
      onFocus={iPhoneScrollFix}
      onBlur={(e) => selectRef.current}
      className={clsx(className)}
    />
  )
}
