import React, { useState } from 'react'
import clsx from 'clsx'

import styles from './styles.module.scss'
import spaceStyles from '../../styles/space.module.scss'

import { BooleanField, ImageField } from '../../types/SitecoreAdapter'
import { Image } from '@sitecore-jss/sitecore-jss-react'
import Media from '../Media'
import ResponsiveModal from '../../page-components/Modal'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type CustomImageProps = {
  fields: {
    img: ImageField
    tabletImage?: ImageField
    mobileImage?: ImageField
    enableZoom?: BooleanField
    zoomImage?: ImageField
  }
}

const CustomImage = ({
  fields: { img, tabletImage, mobileImage, enableZoom, zoomImage },
}: CustomImageProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleClick = () => {
    if (!isZoomable) return
    setIsModalOpen(true)
  }

  const handleClose = () => {
    setIsModalOpen(false)
  }

  const mobileImg = mobileImage && mobileImage.value.src ? mobileImage : img
  const tabletImg = tabletImage && tabletImage.value.src ? tabletImage : img
  const zommedInImg = zoomImage && zoomImage.value.src ? zoomImage : img
  const isZoomable = enableZoom && enableZoom.value

  return (
    <>
      <div
        data-test-id="CustomImage"
        className={clsx(styles.imageWrap, 'Imagesize', spaceStyles.contain)}
      >
        <Media
          mobile={() => (
            <Image
              field={mobileImg}
              className={clsx(styles.image, 'CustomImage')}
            />
          )}
          tablet={() => (
            <Image
              field={tabletImg}
              className={clsx(styles.image, 'CustomImage')}
            />
          )}
          laptop={() => (
            <div onClick={handleClick}>
              <Image
                field={img}
                className={clsx(
                  styles.image,
                  'CustomImage',
                  isZoomable && styles.zoomable
                )}
              />
            </div>
          )}
        />
      </div>
      {isModalOpen && (
        <Media
          mobile={false}
          laptop={() => (
            <ResponsiveModal
              className={clsx(styles.zoomContainer)}
              onClose={handleClose}
            >
              <Image
                field={zommedInImg}
                className={clsx(styles.enlargedImage, 'CustomImage')}
              />
            </ResponsiveModal>
          )}
        />
      )}
    </>
  )
}

export default withErrorBoundary(CustomImage, { component: 'CustomImage' })
