// Util to add/append query paramenters at the end of image url

function addQueryParamsToUrl(src: string | JSX.Element, params: string): string  {
  // Check if the URL is a string
  if (typeof src === 'string') {
    // Check if the URL already has query parameters
    const separator = src.includes("?") ? "&" : "?";

    // Combine the original URL and new parameters
    const newUrl = `${src}${separator}${params}`;

    return newUrl;
  }

  return `${src.toString()}`;
}

export { addQueryParamsToUrl }