import React, { FC, useContext } from 'react'
import BannerBase from '../../../components/Banner/Banner'
import infoIcon from './../../../components/Banner/story/info.png'
import { FallbackRender, FallbackRenderProps } from './types'
import { EnvironmentContext } from '../../../context/Environment/EnvironmentContext'
import { toLower } from 'lodash-es'

export const ErrorFallback: FC<FallbackRenderProps> = ({
  error,
  component,
}) => {
  const envConfig = useContext(EnvironmentContext)
  const showFallback = ['authoring', 'local'].includes(toLower(envConfig.env))

  if (!showFallback)
    return <div className="ErrorBoundary-Error" id={`EB-${component}`}></div>

  return (
    <BannerBase
      text={
        <div className="ErrorBoundary-Error" id={`EB-${component}`}>
          <p>
            <b>Component:</b> {component}
            <br />
            <b>Error:</b> {error.message}
          </p>
        </div>
      }
      icon={<img src={infoIcon} width="20" height="20" alt="Error" />}
    />
  )
}

export const DefaultFallback: FallbackRender = (ep) => <ErrorFallback {...ep} />
