import React from 'react'
import { LinkWrapperWithChildItems } from '../../../../components/LinkWrapper'
import clsx from 'clsx'
import { BlogAuthorTitles } from '../../../../constants/blog-author'
import { ContenProps } from '.'
import styles from './styles.module.scss'
import { isEmpty, omit } from 'lodash-es'
import { defaultAuthorProfilePic } from '../../../blog-author/AuthorProfile/ProfileInfo'
import { PopoverContent } from '../../../../components/Popover'

const AuthorBioContent = ({
  profilePic,
  shortDescription,
  authorPageMapping,
}: ContenProps) => {
  return (
    <PopoverContent className={'Popover'}>
      <div className={clsx(styles.authorContentWrap, 'copy-article')}>
        <div className={styles.authorProfileBioWrap}>
          <div className={styles.authorProfilePicWrap}>
            <img
              className={styles.authorProfilePic}
              {...omit(
                isEmpty(profilePic?.value?.src)
                  ? defaultAuthorProfilePic
                  : profilePic?.value,
                ['height', 'width']
              )}
            />
          </div>
          <div className={styles.authorBioContentWrap}>
            <p className={styles.authorShortBio}>{shortDescription?.value}</p>
            {!isEmpty(authorPageMapping.value) && (
              <div>
                <LinkWrapperWithChildItems
                  href={`/blog/authors/${authorPageMapping.value}`}
                  title={BlogAuthorTitles.FULL_BIO}
                  className={styles.bioPageLink}
                >
                  {BlogAuthorTitles.FULL_BIO}
                </LinkWrapperWithChildItems>
              </div>
            )}
          </div>
        </div>
        <div className={styles.editorialPolicyLinkWrap}>
          <p>
            Learn about our{' '}
            <LinkWrapperWithChildItems
              href="/about-us/our-editorial-process"
              title={BlogAuthorTitles.EDITORIAL_POLICIES}
            >
              {BlogAuthorTitles.EDITORIAL_POLICIES}
            </LinkWrapperWithChildItems>
          </p>
        </div>
      </div>
    </PopoverContent>
  )
}
export default AuthorBioContent
