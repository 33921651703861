import React, { memo } from 'react'
import TableBase from './Table'
import {
  RichText,
  Text,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react'
import {
  Item,
  BooleanField,
  TextField,
} from '../../types/SitecoreAdapter'
import { Column } from './types'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type ConfigFields = {
  numColumns: TextField
  numRows: TextField
  boldRowHeadings: BooleanField
  squash: BooleanField
  layout: TextField
}

type CellFields = { [key: string]: TextField }

type TableProps = Item<CellFields & ConfigFields>

const Table = ({ fields }: TableProps) => {
  const columnHeadings = fieldsOfType(fields, 'columnHeading')
  const rowHeadings = fieldsOfType(fields, 'rowHeading')
  const cells = fieldsOfType(fields, 'cellText')
  const {
    sitecoreContext: { pageEditing: isEditing },
  } = useSitecoreContext()
  const {
    numColumns: { value: numColumns },
    numRows: { value: numRows },
    boldRowHeadings: { value: boldRowHeadings },
    squash: { value: squash },
    layout: { value: layout },
    cornerLabel,
  } = fields

  const intNumColumns = parseInt(numColumns, 10)
  const intNumRows = parseInt(numRows, 10)

  return (
    <TableBase
      rowHeadings={rowHeadings}
      numberOfColumns={intNumColumns}
      columns={getColumns(intNumColumns, intNumRows, cells, columnHeadings)}
      boldRowHeadings={boldRowHeadings}
      squash={squash}
      largerFirstColumn={isLargerFirstColumn(layout)}
      cornerLabel={getCornerLabel(isEditing ?? false, cornerLabel)}
    />
  )
}

const isLargerFirstColumn = (layout: string) => layout === 'largerFirstColumn'

function getCornerLabel(isEditing: boolean, cornerLabelField: TextField) {
  const useField = isEditing || cornerLabelField.value
  return useField ? <Text field={cornerLabelField} /> : undefined
}

function fieldsOfType(fields: CellFields, type: string): JSX.Element[] {
  return Object.entries(fields) // force-break
    .filter(([name, value]) => name.startsWith(type))
    .sort(([firstName], [secondName]) => firstName.localeCompare(secondName))
    .map(([, value]) => {
      if (type !== 'columnHeading') return <RichText field={value} />
      return <Text field={value} />
    })
}

function getColumns(
  numColumns: number,
  numRows: number,
  cells: JSX.Element[],
  columnHeadings: JSX.Element[]
): Column[] {
  const columns = []

  // split the array into groups of 10 (each column has max 10 rows)
  // since we are not filtering for empty values,
  // the length of the cells[] is always 30
  const cellGroups = []
  const groupSize = 10

  for (let i = 0; i < cells.length; i += groupSize) {
    const chunk = cells.slice(i, i + groupSize)
    cellGroups.push(chunk)
  }

  // for each column pick the rows from the associated cell group
  for (let column = 0; column < numColumns; column++) {
    const rows = []
    const rowsData = cellGroups[column]

    for (let row = 0; row < numRows; row++) {
      rows.push(rowsData[row])
    }

    columns.push({
      title: columnHeadings[column],
      rows: rows,
    })
  }

  return columns
}

export default withErrorBoundary(memo(Table), { component: 'Table' })
