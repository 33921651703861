import * as React from 'react'
import BackgroundImage from '../../../Homepage-Hero/BackgroundImage'
import styles from './styles.module.scss'
import clsx from 'clsx'
import { HomeHeroColumnsBaseProps } from '../types'
import TrustPilotWidget from '../../../TrustPilot-Widget/TrustPilotWidget'

export default function HomepageHeroColumnsBase({
  heading,
  descriptor,
  backgroundImage,
  isDark,
  showTrustPilotWidget,
  children,
}: HomeHeroColumnsBaseProps) {
  const darkBg = isDark && isDark.value

  return (
    <div data-test-context="Hero" className={styles.content}>
      <div
        className={styles.heroHeadingContainer}
        data-test-id={'home-hero-tp-widget-container'}
      >
        <h1
          className={clsx(styles.headline, !darkBg && styles.darkText)}
          data-test-id="mobileHeroHeadline"
        >
          {heading}
        </h1>
        {showTrustPilotWidget && showTrustPilotWidget.value && (
          <TrustPilotWidget type="microStar" variant={'home'} />
        )}
      </div>

      <div className={styles.card}>
        <div
          className={clsx(styles.descriptor, 'h5', !darkBg && styles.darkText)}
        >
          {descriptor}
        </div>
        <div className={styles.quoteContainer}>{children}</div>
      </div>
      <BackgroundImage>{backgroundImage}</BackgroundImage>
    </div>
  )
}
