import { useEffect, Dispatch } from 'react'
import { cookiesAreEnabled } from '../../../services/check-browser/cookies-are-enabled'
import { Event, Event_CookieCheck } from '../state-management/events'

export function useCookieCheck(dispatch: Dispatch<Event_CookieCheck>) {
  // the cookie check runs after render, which may not be ideal, because you could get a flash of the system working
  // but this should be rare enough that optimizing first paint for the majority of people who can start apps makes sense
  useEffect(() => {
    dispatch({
      type: Event.CookieCheck,
      enabled: cookiesAreEnabled(),
    })
  }, [])
}
