import React from 'react'
import { ControlProps, GroupBase, components } from 'react-select'
import { FC } from '../../../../../../types/common'
import { IOption } from '../../../types'
import styles from './styles.module.scss'
import searchIcon from './search.svg'

type Props = ControlProps<IOption, false, GroupBase<IOption>>

export const SelectControl: FC<Props> = ({ children, ...props }) => (
  <components.Control {...props}>
    {props.selectProps.hasSearchIcon && (
      <img src={searchIcon} className={styles.searchIcon} alt={'Search Icon'} />
    )}
    {children}
  </components.Control>
)
