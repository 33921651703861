import React, { useContext } from 'react'
import { Status } from '../../state-management/states'
import {
  SelectFocus,
  SelectCTA,
  ModalClose,
  RejectSelection,
  ConfirmSelection,
} from '../../state-management/events'
import { selectProfession } from '../../state-management/reducer'
import {
  triggerInputClick,
  triggerButtonClick,
} from '../../analytics/search-app-analytics'
import SelectAsync from '../../components/Select/SelectAsync'
import SelectInert from '../../components/Select/SelectInert'
import Modal from '../../../../../page-components/Modal'
import QuoteButton from '../../components/QuoteButton'
import { AppStartSettingsContext } from '../../context'
import styles from './styles.module.scss'
import {
  CloseCallback,
  DispatchProp,
  SearchAppStartEmbedProps,
} from '../../state-management/container'
import Button from '../../../../../components/Button'
import LeadRoutingNumber from '../../../../../components/Lead-Routing-Number-Attribution'

export default function DesktopHeroSearchAppStart({
  loadOptions,
  appState,
  dispatch,
  callbacks: { onInput, onClose },
}: SearchAppStartEmbedProps) {
  const {
    buttonText,
    placeholder,
    modalHeading,
    preselectShowInput,
    confirmSelection,
    confirmButtonLabel,
    darkBg,
  } = useContext(AppStartSettingsContext)

  if (appState.status === Status.Inert) {
    return (
      <div className={styles.control}>
        {preselectShowInput && (
          <SelectInert
            value={appState.selection}
            placeholder={placeholder}
            onFocus={() => {
              triggerInputClick()
              dispatch(SelectFocus)
            }}
          />
        )}

        <QuoteButton
          onClick={() => {
            triggerButtonClick()
            dispatch(SelectCTA)
          }}
        >
          {buttonText}
        </QuoteButton>
        <LeadRoutingNumber
          testId={'SearchAppStart-LeadRouteNumber'}
          isBgDark={darkBg}
          className={styles.routingAttribution}
          variant="cta"
        />
      </div>
    )
  }

  if (appState.status === Status.AppStart && appState.direct == true) {
    return (
      <div className={styles.control}>
        {preselectShowInput && (
          <SelectInert
            value={appState.selection}
            placeholder={placeholder}
            isDisabled={true}
          />
        )}

        <QuoteButton loading disabled>
          {buttonText}
        </QuoteButton>
      </div>
    )
  }

  return (
    <ModalAndBackground
      preselectShowInput={preselectShowInput}
      buttonText={buttonText}
      placeholder={placeholder}
      modalHeading={modalHeading}
      onClose={onClose}
      dispatch={dispatch}
      darkBg={darkBg}
    >
      {confirmBodyActive() || confirmBodyLoading() || modalBody()}
    </ModalAndBackground>
  )

  function confirmBodyActive() {
    if (
      confirmSelection &&
      appState.status === Status.Searching &&
      appState.selection !== undefined
    ) {
      return (
        <>
          <SelectInert
            className={styles.inlineSelectRoot}
            value={appState.selection}
            placeholder={placeholder}
            onFocus={() => {
              dispatch(RejectSelection)
            }}
          />
          <Button
            onClick={() => dispatch(ConfirmSelection)}
            testId="ConfirmButton"
            size="xlarge"
            className={styles.confirmButton}
          >
            {confirmButtonLabel}
          </Button>
        </>
      )
    }
  }

  function confirmBodyLoading() {
    if (confirmSelection && appState.status === Status.AppStart) {
      return (
        <>
          <SelectInert
            className={styles.inlineSelectRoot}
            value={appState.selection}
            placeholder={placeholder}
            isDisabled
          />
          <Button
            testId="ConfirmButton"
            size="xlarge"
            className={styles.confirmButton}
            disabled
            loading
          >
            {confirmButtonLabel}
          </Button>
        </>
      )
    }
  }

  function modalBody() {
    if (
      appState.status === Status.Searching ||
      appState.status === Status.AppStart
    ) {
      return (
        <div className={styles.horizontalSelectRoot}>
          <SelectAsync
            loadOptions={loadOptions}
            onSelect={(option) => {
              dispatch(selectProfession(option))
            }}
            onInput={onInput}
          />
        </div>
      )
    }
  }
}

type ModalHeadingProps = {
  children: React.ReactNode
  preselectShowInput: boolean
  buttonText: string
  placeholder: string
  modalHeading: React.ReactElement
  darkBg?: boolean
} & CloseCallback &
  DispatchProp

function ModalAndBackground({
  children,
  onClose,
  dispatch,
  preselectShowInput,
  buttonText,
  placeholder,
  modalHeading,
  darkBg,
}: ModalHeadingProps) {
  const handleClose = () => {
    onClose && onClose()
    dispatch(ModalClose)
  }

  return (
    <>
      <div className={styles.control}>
        {preselectShowInput && <SelectInert placeholder={placeholder} />}
        <QuoteButton>{buttonText}</QuoteButton>
        <LeadRoutingNumber
          testId={'SearchAppStart-LeadRouteNumber'}
          isBgDark={darkBg}
          className={styles.routingAttribution}
          variant="cta"
        />
      </div>
      <Modal onClose={handleClose} modalHeading={modalHeading} desktopSize="md">
        {children}
      </Modal>
    </>
  )
}
