import React from 'react'
import { ButtonBack, ButtonNext } from 'pure-react-carousel'

import styles from './styles.module.scss'
import clsx from 'clsx'

export default function NavButtons() {
  return (
    <div className={styles.nav}>
      <ButtonBack className={clsx(styles.button, styles.back)}>
        <span className={styles.hidden}>Back</span>
      </ButtonBack>
      <ButtonNext className={clsx(styles.button, styles.next)}>
        <span className={styles.hidden}>Next</span>
      </ButtonNext>
    </div>
  )
}
