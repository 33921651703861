import React from 'react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import clsx from 'clsx'
import { moduleClassNames } from '../../css-utils'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import styles from './styles.module.scss'
import baseStyles from '../../styles.module.scss'
import LinkWrapper, {
  LinkWrapperWithChildItems,
} from '../../../../components/LinkWrapper'
import PolicyRecommendationCTA from '../../../../components/Policy-Recommendation-CTA'
import { isEmpty, get } from 'lodash-es'

class Menu extends React.PureComponent {
  targetElement = null

  componentDidUpdate() {
    this.targetElement.scrollTop = 0
  }

  handleClick = event => {
    const { section, onRequestClose } = this.props

    event.preventDefault()

    onRequestClose(true).then(() => {
      window.location = section.href
    })
  }

  render() {
    const {
      section,
      activeSection,
      onShowSection,
      onRequestClose,
      onGoBack,
    } = this.props

    const menuClassName = clsx(
      styles.subMenu,
      activeSection === section && styles.isOpen
    )
    const linkClassName = clsx(styles.header, styles.moreLink)

    return (
      <div ref={e => (this.targetElement = e)} className={menuClassName}>
        <div className={styles.nav}>
          <div className={styles.backButton} onClick={onGoBack} />
          <LinkWrapper
            href={section && section.href}
            title={section && section.title}
            text={section && section.text}
            className={linkClassName}
            {...{ 'data-tag': 'meganav' }}
            onClick={this.handleClick}
          />
          <div className={styles.emptyButton} />
        </div>
        <ul className={styles.navList}>
          {section &&
            section.links &&
            section.links.map((section, index) => (
              <React.Fragment key={index}>
                <Header title={section.text} />
                <li className={clsx(styles.listItem, styles.nested)}>
                  <List
                    links={section.links}
                    onShowSection={onShowSection}
                    onRequestClose={onRequestClose}
                    onGoBack={onGoBack}
                  />
                </li>
              </React.Fragment>
            ))}
        </ul>
      </div>
    )
  }
}

const List = ({
  links,
  activeSection,
  onShowSection,
  onRequestClose,
  onGoBack,
  hasPolicyCTA = false,
  showSearch,
  hideCallUs
}) => {
  const searchLink = {
    href: '/search',
    isSummaryLink: false,
    text: 'Search Insureon',
    mobileOnly: true,
    links: []
  }

  const menuItems = links
  const menuItemsWithSearch = menuItems.slice(0, -1).concat(searchLink, menuItems.slice(-1))
  const itemLinks = showSearch ? menuItemsWithSearch : links
  const menuItemsWithoutCallUs = itemLinks.slice().filter(item => item.text.toLowerCase() !== "call us")
  const finalItemsList = hideCallUs ? menuItemsWithoutCallUs : itemLinks

  return (
    <ul className={styles.navList} style={{ width: '100%' }}>
      {/* Policy Recomendation CTA */}
      {hasPolicyCTA && <li className={styles.listItem}>
        <div className={styles.itemLink}>
          <PolicyRecommendationCTA
            wrapClassName={styles.policyCTAWrap}
            isVisible
            visibleOn={['laptop', 'tablet', 'mobile']}
            alignItems='Left'
            direction='column'
          />
        </div>
      </li>}
      {finalItemsList.map(link => (
        <Item
          key={link.text}
          title={link.text}
          section={link}
          activeSection={activeSection}
          onShowSection={onShowSection}
          onRequestClose={onRequestClose}
          onGoBack={onGoBack}
        />
      ))}
    </ul>
  )
}

const Header = ({ title }) => {
  const itemClassName = clsx(styles.listItem, styles.header)

  return (
    <li className={itemClassName}>
      <span className={styles.itemLink} {...{ 'data-tag': 'meganav' }}>
        {title}
      </span>
    </li>
  )
}

const Item = ({
  title,
  section,
  activeSection,
  onShowSection,
  onRequestClose,
  onGoBack,
}) => {
  const hasChildren = section.links && section.links.length > 0

  const itemClassName = clsx(styles.listItem, hasChildren && styles.hasSubMenu)

  const linkClassName = clsx(
    styles.itemLink,
    section.isSummaryLink && styles.moreLink
  )

  const handleClick = event => {
    event.preventDefault()

    if (hasChildren) {
      onShowSection(section)
    } else {
      onRequestClose(true).then(() => {
        window.location = section.href
      })
    }
  }

  return (
    <li className={itemClassName}>
      <LinkWrapperWithChildItems
        href={section.href}
        className={linkClassName}
        title={section.title}
        {...{ 'data-tag': 'meganav' }}
        onClick={handleClick}
      >
        {section.icon && (
          <img
            src={section.icon}
            alt={section.iconAlt}
            title={section.iconAlt}
            width={20}
            height={20}
            style={{ marginRight: 10 }}
            loading={'lazy'}
          />
        )}
        {title}
      </LinkWrapperWithChildItems>
      {hasChildren && (
        <Menu
          key={section.title}
          section={section}
          activeSection={activeSection}
          onShowSection={onShowSection}
          onRequestClose={onRequestClose}
          onGoBack={onGoBack}
        />
      )}
    </li>
  )
}

/**
 * Responsive mega-menu for tablet and mobile devices
 */
class MegaMenu extends React.PureComponent {
  targetElement = null

  componentDidUpdate() {
    if (this.props.isOpen) {
      disableBodyScroll(this.targetElement)
      document.body.style.overflow = 'hidden'
    } else {
      clearAllBodyScrollLocks()
      document.body.style.overflow = null
    }
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks()
  }

  handleGoBack = event => {
    this.props.onShowSection(null)
  }

  render() {
    const {
      links,
      activeSection,
      isOpen,
      isSubMenuOpen,
      menuClassName,
      onRequestClose,
      onShowSection,
      theme,
      showSearch,
      hideCallUs
    } = this.props

    const className = clsx(
      styles.container,
      baseStyles.container,
      moduleClassNames(theme, styles),
      isOpen && styles.isOpen,
      isSubMenuOpen && styles.subMenuIsOpen
    )
    const hasPolicyCTA = !isEmpty(
      get(
        this.props.sitecoreContext,
        'route.fields.policyRecommendationCtaSettings'
      )
    )

    return (
      <div className={className}>
        <div className={styles.overlay} onClick={event => onRequestClose()} />
        <div className={clsx(styles.megaMenu, menuClassName)}>
          <List
            links={links}
            activeSection={activeSection}
            onShowSection={onShowSection}
            onRequestClose={onRequestClose}
            onGoBack={this.handleGoBack}
            hasPolicyCTA={hasPolicyCTA}
            showSearch={showSearch}
            hideCallUs={hideCallUs}
          />
        </div>
      </div>
    )
  }
}

export default withSitecoreContext()(MegaMenu)
