import React from 'react'
import Wrapper from './Wrapper'
import Aside from './Aside'
import clsx from 'clsx'
import spaceStyles from '../../styles/space.module.scss'
import styles from './styles.module.scss'

type MobileProps = {
  article: JSX.Element
  tagTopics: JSX.Element
  cta: JSX.Element
}

const Mobile = ({ article, tagTopics, cta }: MobileProps) => (
  <Wrapper>
    <div className={clsx('block', spaceStyles.contain, styles.article)}>
      {article}
    </div>
    <aside className={styles.creamyBg}>
      <div className={clsx(styles.tagContain, spaceStyles.contain)}>
        {tagTopics}
      </div>
      <div className="block">
        <Aside />
      </div>
    </aside>
    {cta}
  </Wrapper>
)

export default Mobile
