import * as React from 'react'
import * as CMS from '../../types/SitecoreAdapter'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import Helmet from 'react-helmet'

export const Title = ({ children }: { children: string }) => {
  return (
    <Helmet>
      <title>{children}</title>
    </Helmet>
  )
}

type TitleAdapterProps = CMS.RouteFields<{
  pageTitle: CMS.TextField
}>

export const TitleAdapter = ({
  sitecoreContext: {
    route: {
      fields: { pageTitle },
    },
  },
}: TitleAdapterProps) => {
  return <Title>{pageTitle.value}</Title>
}

export const TitleWired = withSitecoreContext()(TitleAdapter)
