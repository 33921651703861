import React from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'

type SidebarProps = {
  main: JSX.Element
  bar: JSX.Element
  foot?: JSX.Element
  transparent?: boolean
}

function Sidebar({ main, bar, foot, transparent }: SidebarProps) {
  return (
    <div className={styles.base}>
      <div className={styles.main}>{main}</div>
      <aside
        className={clsx(styles.bar, transparent && styles.transparent)}
      >
        <div className={styles.barStick}>{bar}</div>
      </aside>
      {foot && (
        <>
          <div className={styles.foot}>{foot}</div>
          <div className={styles.footBg} />
        </>
      )}
    </div>
  )
}

export default Sidebar
