import React from 'react'
import clsx from 'clsx'

import arrow from './arrow.svg'
import { LinkWrapperWithChildItems } from '../../LinkWrapper'
import { LinkWrapperProps } from '../../LinkWrapper/LinkTypes'

import styles from './styles.module.scss'

// Links with an arrow icon
type FeaturedContentLinkProps = {
  linkAttr: LinkWrapperProps
  className?: string
}

export default function FeaturedContentLink({
  linkAttr,
  className,
}: FeaturedContentLinkProps) {
  return (
    <LinkWrapperWithChildItems
      className={clsx(styles.link, className)}
      href={linkAttr.href}
      title={linkAttr.title}
      newWindow={linkAttr.newWindow}
      additionalProps={{
        'data-tag': 'FeaturedContent',
        'data-test-id': 'FeaturedContent-Link',
      }}
    >
      {linkAttr.text}
      <ArrowIcon />
    </LinkWrapperWithChildItems>
  )
}

function ArrowIcon() {
  return <img src={arrow} alt="forward arrow" className={styles.arrow} />
}
