import React, { useContext } from 'react'
import { Status } from '../../state-management/states'
import {
  SelectFocus,
  SelectCTA,
  ModalClose,
  RejectSelection,
  ConfirmSelection,
} from '../../state-management/events'
import { selectProfession } from '../../state-management/reducer'
import {
  triggerInputClick,
  triggerButtonClick,
} from '../../analytics/search-app-analytics'
import SelectAsync from '../../components/Select/SelectAsync'
import SelectInert from '../../components/Select/SelectInert'
import Modal from '../../../../../page-components/Modal'
import QuoteButton from '../../components/QuoteButton'
import { AppStartSettingsContext } from '../../context'
import styles from './styles.module.scss'
import {
  CloseCallback,
  DispatchProp,
  SearchAppStartEmbedProps,
} from '../../state-management/container'
import Button from '../../../../../components/Button'
import { IOption } from '../../types'

export default function DesktopHeroSearchAppStart({
  loadOptions,
  appState,
  dispatch,
  callbacks: { onInput, onClose },
}: SearchAppStartEmbedProps) {
  const {
    buttonText,
    placeholder,
    modalHeading,
    preselectShowInput,
    confirmSelection,
    confirmButtonLabel,
  } = useContext(AppStartSettingsContext)

  if (appState.status === Status.Inert) {
    return (
      <div className={styles.control}>
        {preselectShowInput && (
          <SelectInert
            value={appState.selection}
            placeholder={placeholder}
            onFocus={() => {
              triggerInputClick()
              dispatch(SelectFocus)
            }}
          />
        )}

        <div className={styles.desktopButton}>
          <QuoteButton
            onClick={() => {
              triggerButtonClick()
              dispatch(SelectCTA)
            }}
          >
            {buttonText}
          </QuoteButton>
        </div>
      </div>
    )
  }

  if (appState.status === Status.AppStart && appState.direct === true) {
    return (
      <div className={styles.control}>
        {preselectShowInput && (
          <SelectInert
            placeholder={placeholder}
            value={appState.selection}
            isDisabled={true}
          />
        )}

        <div className={styles.desktopButton}>
          <QuoteButton loading disabled>
            {buttonText}
          </QuoteButton>
        </div>
      </div>
    )
  }

  return (
    <ModalAndBackground
      buttonText={buttonText}
      dispatch={dispatch}
      modalHeading={modalHeading}
      placeholder={placeholder}
      preselectShowInput={preselectShowInput}
      onClose={onClose}
      selectedProfession={appState.status !== Status.BrowserError ? appState.selection : undefined}
    >
      {confirmBodyActive() || confirmBodyLoading() || modalBody()}
    </ModalAndBackground>
  )

  function confirmBodyActive() {
    if (
      confirmSelection &&
      appState.status === Status.Searching &&
      appState.selection !== undefined
    ) {
      return (
        <>
          <SelectInert
            className={styles.inlineSelectRoot}
            value={appState.selection}
            placeholder={placeholder}
            onFocus={() => {
              dispatch(RejectSelection)
            }}
          />
          <Button
            className={styles.confirmButton}
            size="xlarge"
            testId="ConfirmButton"
            onClick={() => dispatch(ConfirmSelection)}
          >
            {confirmButtonLabel}
          </Button>
        </>
      )
    }
  }

  function confirmBodyLoading() {
    if (confirmSelection && appState.status === Status.AppStart) {
      return (
        <>
          <SelectInert
            className={styles.inlineSelectRoot}
            value={appState.selection}
            placeholder={placeholder}
            isDisabled
          />
          <Button
            className={styles.confirmButton}
            size="xlarge"
            testId="ConfirmButton"
            disabled
            loading
          >
            {confirmButtonLabel}
          </Button>
        </>
      )
    }
  }

  function modalBody() {
    if (
      appState.status === Status.Searching ||
      appState.status === Status.AppStart
    ) {
      return (
        <div className={styles.horizontalSelectRoot}>
          <SelectAsync
            loadOptions={loadOptions}
            onSelect={option => {
              dispatch(selectProfession(option))
            }}
            onInput={onInput}
          />
        </div>
      )
    }
  }
}

type ModalAndBackgroundProps = {
  children: React.ReactNode
  preselectShowInput: boolean
  placeholder: string
  buttonText: string
  modalHeading: React.ReactElement
  selectedProfession?: IOption
} & DispatchProp &
  CloseCallback

function ModalAndBackground({
  children,
  onClose,
  dispatch,
  preselectShowInput,
  placeholder,
  buttonText,
  modalHeading,
  selectedProfession
}: ModalAndBackgroundProps) {
  const handleClose = () => {
    onClose && onClose()
    dispatch(ModalClose)
  }

  return (
    <>
      <div className={styles.control}>
        {preselectShowInput && <SelectInert value={selectedProfession} placeholder={placeholder} />}
        <div className={styles.desktopButton}>
          <QuoteButton>{buttonText}</QuoteButton>
        </div>
      </div>
      <Modal onClose={handleClose} modalHeading={modalHeading} desktopSize="md">
        {children}
      </Modal>
    </>
  )
}
