import React from 'react'
import styles from './styles.module.scss'
import { TagBandProps } from './TagBand'
import TagList from './TagList/TagList'

const DesktopTagBand = ({ tags, title, pathPrefix }: TagBandProps) => {
  return (
    <div data-test-id={"TagBand-Wrap"} className={styles.containDesktop}>
      <div className={styles.desktopWrap}>
        <div className={styles.title}>{title.value}</div>
        <TagList tags={tags} pathPrefix={pathPrefix} />
      </div>
    </div>
  )
}

export default DesktopTagBand
