import React, { ReactNode } from 'react'
import { Column } from '../types'
import Cell from './Cell'
import CellHeader from './CellHeader'
import TableContainer from './TableContainer'
import styles from './styles.module.scss'
import SpacingContainer from '../SpacingContainer'

type MobileTableProps = {
  rowHeadings: ReactNode[]
  columns: Column[]
  numberOfColumns?: number
}

export const MobileTable = ({ rowHeadings, columns }: MobileTableProps) => {
  return (
    <SpacingContainer>
      <TableContainer>
        {columns.map((column, index) => (
          <React.Fragment key={index}>
            <div className={styles.hrow}>
              <CellHeader>{column.title}</CellHeader>
            </div>
            {column.rows.map((row, index) => (
              <div key={index} className={styles.row}>
                <CellHeader>{rowHeadings[index]}</CellHeader>
                <Cell className={styles.cell}>{row}</Cell>
              </div>
            ))}
          </React.Fragment>
        ))}
      </TableContainer>
    </SpacingContainer>
  )
}
