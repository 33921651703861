import React, { ReactNode, memo } from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'

type CellProps = {
  children: ReactNode
  className?: string
  borderBottom?: boolean
}

export function Cell({
  children,
  className,
  borderBottom
}: CellProps) {
  return <div className={clsx(styles.cell, className)}>{children}</div>
}

export default memo(Cell)
