import * as React from 'react'
import styles from './styles.module.scss'

type DesktopCarrierBandProps = {
  image: JSX.Element
}

// TODO desktop has the styling issue with the line
export default function DesktopCarrierBand({ image }: DesktopCarrierBandProps) {
  return <div data-test-id="DesktopCarrierBand" className={styles.desktopImg}>{image}</div>
}
