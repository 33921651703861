import React, { useRef } from 'react'
import styles from './styles.module.scss'
import { useCardClick } from './useCardClick'
import clsx from 'clsx'

type PolicyCardProps = {
  icon: React.ReactElement
  linkHref: string
  linkTitle: string
  heading: React.ReactElement
  body: React.ReactElement
  bestFor: React.ReactElement
  className?: string
  children?: React.ReactNode
}

const PolicyCard = ({
  icon,
  linkHref,
  linkTitle,
  heading,
  body,
  bestFor,
  children,
  className,
}: PolicyCardProps) => {
  const anchorEl = useRef<HTMLAnchorElement>(null)
  const [onCardMouseDown, onCardMouseUp, onAnchorMouseDown] = useCardClick(
    anchorEl
  )

  return (
    <div
      data-test-id={'PolicyCard-Wrap'}
      className={clsx('PolicyCard', styles.card, className)}
      onMouseDown={onCardMouseDown}
      onMouseUp={onCardMouseUp}
    >
      <div className={styles.topWrap}>
        <div className={styles.icon}>{icon}</div>
        <a
          ref={anchorEl}
          onMouseDown={onAnchorMouseDown}
          href={linkHref}
          title={linkTitle}
          className={styles.head}
        >
          {heading}
        </a>
        <div className="card-descriptor">{body}</div>
      </div>
      <div className={styles.bestForWrap}>
        <div className={styles.bestForHead}>BEST FOR</div>
        {bestFor}
      </div>
      <div className={styles.buttonWrap}>{children}</div>
    </div>
  )
}

export default React.memo(PolicyCard)
