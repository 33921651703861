import React from 'react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'

export enum ThemeEnum {
  Blue,
  Q221,
}

type ThemeContextProps = {
  children?: React.ReactNode
}

export const ThemeContext = React.createContext<ThemeEnum>(ThemeEnum.Q221)

export function ThemeContextRoot({
  children,
}: ThemeContextProps) {
  return <ThemeContext.Provider value={ThemeEnum.Q221}>{children}</ThemeContext.Provider>
}

export default withSitecoreContext()(ThemeContextRoot)
