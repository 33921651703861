import React, { FC } from 'react'
import Radio from '../Radio'
import { get, toLower } from 'lodash-es'
import styles from './styles.module.scss'
import clsx from 'clsx'
import { OptionType, RadioGroupProps } from './types'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

const RadioGroup: FC<RadioGroupProps> = ({
  id,
  options,
  value,
  direction = 'vertical',
  align = 'start',
  onChange,
}) => {
  const handleChange = (o: OptionType) => onChange && onChange(o)

  return (
    <div
      data-test-id={'radio-group-wrap'}
      className={clsx(styles.radioGroupWrap, styles[direction], styles[align])}
    >
      {options.map((o, index) => (
        <Radio
          key={id + index}
          {...o}
          onChange={() => handleChange(o)}
          checked={toLower(get(value, 'value')) === toLower(o.value)}
        />
      ))}
    </div>
  )
}

export default withErrorBoundary(RadioGroup, { component: 'RadioGroup' })
