import {useEffect} from 'react'

export default function useTrackingApi() {
	useEffect(() => {
		const vidTracker = document.createElement('script')
		vidTracker.type = "text/javascript"
		vidTracker.async = true
		vidTracker.src = "https://www.youtube.com/iframe_api"
		document.head.appendChild(vidTracker)
	}, [])
}