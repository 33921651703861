import React, { useContext } from 'react'
import Media from '../../components/Media'
import DesktopMenu from './DesktopMenu'
import TabletMenu from './TabletMenu'
import MobileMenu from './MobileMenu'
import { NavigationContext } from './services/context'
import { NavigationType } from './types'

const NavigationTypeSwitch = () => {
  const { navigationType } = useContext(NavigationContext)

  if (navigationType === NavigationType.DesktopNav) {
    return <DesktopMenu />
  }

  return (
    <Media
      mobile={() => <MobileMenu />}
      tablet={() => <TabletMenu />}
      desktop={() => <DesktopMenu />}
    />
  )
}

export default NavigationTypeSwitch
