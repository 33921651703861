import { StylesConfig } from 'react-select'
import { IOption } from '../../types'
import rightArrows from './assets/arrows_ion_purple.svg'

const xsBp = '@media (min-width: 480px)'
const desktopBp = '@media (min-width: 768px)'

export default function getSelectStyles(): StylesConfig<IOption, false> {
  return {
    container: (provided) => ({
      ...provided,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: '.875rem',
      [desktopBp]: {
        fontSize: '1rem',
      },
    }),
    control: (provided, { selectProps }) => ({
      ...provided,
      height: '3.125rem',
      zIndex: 2,
      borderRadius: '1.875rem',
      margin: selectProps?.isInert ? '' : '0 0 .625rem',
      cursor: selectProps?.isInert ? 'pointer' : 'inherit',
    }),
    valueContainer: (provided, { selectProps }) => ({
      ...provided,
      textAlign: 'left',
      paddingLeft: !selectProps.hasSearchIcon ? 20 : 0,
      paddingRight: 0,
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: 0,
      marginBottom: 0,
      boxShadow: 'none',
      border: 'none',
      borderRadius: 0,
      padding: 0,
      top: 0,
      flex: '1 1 18.75rem',
      position: 'relative',
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: '.937rem',
      position: 'absolute',
      height: '100%',
      width: '100%',
      overflowY: 'auto',
      maxHeight: 'none',
    }),
    option: (provided) => {
      return {
        ...provided,
        textAlign: 'left',
        fontSize: '.85rem',
        lineHeight: 1.6,
        padding: '.3rem 1rem',
        color: 'inherit',
        display: 'flex',
        alignItems: 'center',
        minHeight: '2rem',
        cursor: 'pointer',
        background: 'transparent',
        '&:hover, &:focus, &:active': {
          background: '#f3f3f8',
          position: 'relative',
          '&:after': {
            content: `url(${rightArrows})`,
            position: 'absolute',
            top: '.313rem !important',
            right: '.313rem',
          },
        },
        [xsBp]: {
          '&:hover, &:focus, &:active': {
            '&:after': {
              right: '.938rem',
            },
          },
          fontSize: '0.875rem',
        },
        [desktopBp]: {
          '&:hover, &:focus, &:active': {
            '&:after': {
              top: '.5rem !important',
            },
          },
        },
      }
    },
    placeholder: (provided, { selectProps }) => ({
      ...provided,
      left: 0,
      color: selectProps?.lightText ? '#84829c' : '#2b2a3a',
      fontSize: '.8rem',
      [xsBp]: {
        fontSize: '1rem',
        marginLeft: '.25rem',
      },
      [desktopBp]: {
        fontSize: selectProps?.isInert ? '1.125rem' : '1rem',
        marginLeft: '.139rem',
      },
    }),
    input: (provided) => ({
      ...provided,
      padding: 0,
      fontSize: '1rem',
    }),
  }
}
