import * as React from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'
import { ImageField } from '../../types/SitecoreAdapter'
import { addQueryParamsToUrl } from '../../util/imageUrlUtil'
import { PageTypes } from '../../constants/page-type'
import { usePageType } from '../../util/usePageType'

type PropCard = {
  label: React.ReactNode
  imageProps: ImageField
}

export default function PropCard({ label, imageProps }: PropCard) {
  const pageType = usePageType()
  const isHomePage = pageType === PageTypes.HOME_PAGE

  return (
    <div className={styles.card}>
      <div className={styles.cardImage}>
        <img
          src={addQueryParamsToUrl(imageProps.value.src, `mh=170`)}
          title={imageProps.value.title}
          alt={imageProps.value.alt}
          height={imageProps.value.height}
          width={imageProps.value.width}
        />
      </div>
      <div
        className={clsx(
          styles.cardLabel,
          !isHomePage && styles.propCardHeadingOverride,
          'h4'
        )}
      >
        {label}
      </div>
    </div>
  )
}
