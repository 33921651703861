import React from 'react'
import styles from './styles.module.scss'
import BlogCard from '../Blog-Card'
import BlogCardList from '../Blog-Card/List'
import BlogContain from '../Blog-Card/Contain'
import Media from '../Media'
import { LinkWrapperWithChildItems } from '../LinkWrapper'
import { RecentPostsFields } from './types'
import { addQueryParamsToUrl } from '../../util/imageUrlUtil'
import clsx from 'clsx'

const RecentPosts = ({
  title,
  cards,
  seeMoreLink,
  seeMoreText,
  styleOverride,
  hideCardDescription,
}: RecentPostsFields) => {
  const desktopSeeMore = seeMoreLink && seeMoreText && (
    <Media
      desktop={() => (
        <LinkWrapperWithChildItems
          href={seeMoreLink}
          className={styles.moreLink}
        >
          {seeMoreText}
        </LinkWrapperWithChildItems>
      )}
    />
  )

  const mobileSeeMore = seeMoreLink && seeMoreText && (
    <Media
      mobile={() => (
        <div className={styles.linkPosition}>
          <LinkWrapperWithChildItems
            href={seeMoreLink}
            className={styles.moreLink}
          >
            {seeMoreText}
          </LinkWrapperWithChildItems>
        </div>
      )}
      desktop={false}
      wide={false}
    />
  )

  return (
    <BlogContain testId="RecentPosts" block className="Bloghome-RecentPosts">
      <div className={styles.headingWrap}>
        <h2 className={clsx(styles.head, styleOverride?.titleHead ?? '')}>
          {title}
        </h2>
        {desktopSeeMore}
      </div>

      <BlogCardList testId="RecentPosts-Cards">
        {cards.map((card) => (
          <BlogCard
            key={card.title}
            image={
              card.image &&
              addQueryParamsToUrl(card.image, `mh=190`)
            }
            imageAlt={card.imageAlt}
            imageWidth={390}
            imageHeight={190}
            title={card.title}
            href={card.href}
            tag={card.tag}
          >
            {!hideCardDescription && card.description ? card.description : ''}
          </BlogCard>
        ))}
      </BlogCardList>

      {mobileSeeMore}
    </BlogContain>
  )
}

export default RecentPosts
