import React from 'react'
import { NoticeProps } from 'react-select'
import { IOption } from '../../../types'
import styles from './styles.module.scss'
import LeadRoutingNumber from '../../../../../../components/Lead-Routing-Number-Attribution'

type Props = NoticeProps<IOption, false>

export default function NoOptionsMessage(props: Props) {
  const {
    selectProps: { inputValue, noResultsSuggestions, noResultsHeader },
  } = props
  const header = noResultsHeader?.replace('{term}', inputValue)
  return (
    <div className={styles.message}>
      {inputValue && <strong>{header}</strong>}
      {noResultsSuggestions && (
        <div className={styles.helpText}>
          {noResultsSuggestions}

          <LeadRoutingNumber
            testId={'SearchAppNoMsg-LeadRouteNumber'}
            variant={'appStart'}
            className={styles.leadRoutingAttr}
          />
        </div>
      )}
    </div>
  )
}
