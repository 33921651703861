import * as React from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'
import { usePageType } from '../../util/usePageType'
import { PageTypes } from '../../constants/page-type'

type SeriesValueProps = {
  heading?: React.ReactNode
  descriptor?: React.ReactNode
  cards: React.ReactNode
}

export default function SeriesValueProp({
  heading,
  descriptor,
  cards,
}: SeriesValueProps) {
  const pageType = usePageType()
  const isHomePage = pageType === PageTypes.HOME_PAGE

  return (
    <div
      data-test-id={'SeriesValueProp-Wrap'}
      className={clsx(
        styles.root,
        !isHomePage && 'block',
        'Q221SeriesValueProp'
      )}
    >
      {heading && (
        <h2
          className={clsx(
            styles.heading,
            !isHomePage && styles.valuePropHeadingOverride
          )}
        >
          {heading}
        </h2>
      )}
      {descriptor && (
        <div
          className={clsx(
            styles.descriptor,
            !isHomePage && styles.valuePropDescriptorOverride,
            'descriptor'
          )}
        >
          {descriptor}
        </div>
      )}
      <div className={styles.cards}>{cards}</div>
    </div>
  )
}
