import React from 'react'
import {
  withSitecoreContext,
  Text,
  Link,
  Image,
} from '@sitecore-jss/sitecore-jss-react'

import styles from './styles.module.scss'
import {
  ImageField,
  LinkField,
  RouteFields,
  TextField,
} from '../../../../types/SitecoreAdapter'

type TasgResultContactBar = RouteFields<{
  reviewsBadge: ImageField
  reviewsBadgeLink: LinkField
  contact: {
    fields: {
      phone: TextField
      hours: TextField
      days: TextField
    }
  }
}>

function TagResultsContact({
  sitecoreContext: {
    route: {
      fields: {
        reviewsBadge,
        reviewsBadgeLink,
        contact: {
          fields: { phone, hours, days },
        },
      },
    },
  },
}: TasgResultContactBar) {
  return (
    <>
      <div data-test-id="TagResultsContact" className={styles.contact}>
        <div>
          Call <Text field={phone} />
        </div>
        <div>
          <Text field={hours} />, <Text field={days} />
        </div>
      </div>
      <Link field={reviewsBadgeLink}>
        <Image field={reviewsBadge} className={styles.reviewsBadge} />
      </Link>
    </>
  )
}

export default withSitecoreContext()(TagResultsContact)
