import * as React from 'react'
import * as D from './domain'
import styles from './styles.module.scss'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import clsx from 'clsx'

export type SlideshowUiProps = {
  frame: D.Frame
  width: number
  height: number
  className?: string
}

export default function SlideshowUi({
  frame,
  width,
  height,
  className,
}: SlideshowUiProps) {
  const [bottom, top] = frame

  return (
    <div
      data-test-id="SlideshowUi"
      className={clsx(styles.base, className)}
      style={{ height: height, width: width }}
    >
      <TransitionGroup>
        {top && (
          <CSSTransition key={top.key} timeout={400} classNames="fade">
            <div className={styles.top}>{top}</div>
          </CSSTransition>
        )}
      </TransitionGroup>
      {bottom}
    </div>
  )
}
