import React, { ReactElement } from 'react'
import { Image } from '@sitecore-jss/sitecore-jss-react'
import ExploreOnInsureon from './ExploreOnInsureon'
import { TextField, ImageField } from '../../types/SitecoreAdapter'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

export type LinkFields = {
  fields: {
    icon: ImageField
    linkText: TextField
    linkHref: TextField
  }
}

export type Link = {
  linkText: string
  linkHref: string
  icon: ReactElement
}

type ExploreOnInsureonAdapterProps = {
  title: TextField
  linkItems: LinkFields[]
}

const ExploreOnInsureonAdapter = ({
  title,
  linkItems,
}: ExploreOnInsureonAdapterProps) => {
  const links: Link[] = linkItems.map(
    ({ fields: { icon, linkText, linkHref } }: LinkFields) => ({
      linkText: linkText.value,
      linkHref: linkHref.value,
      icon: <Image field={icon} />,
    })
  )

  return (
    <ExploreOnInsureon
      testId="ExploreOnInsureonWrap"
      title={title.value}
      links={links}
    />
  )
}

export default withErrorBoundary(React.memo(ExploreOnInsureonAdapter), {
  component: 'Explore-On-Insureon',
})
