import { useEffect, useState } from 'react'
import { debounce } from 'lodash-es'

export function useShowBoxBorder() {
  const [showBoxBorder, setShowBoxBorder] = useState(false)

  useEffect(() => {
    const setShadow = () => {
      setShowBoxBorder(window.pageYOffset > 0)
    }

    const debouncedCb = debounce(setShadow, 10)

    const onScroll = () => {
      window.requestAnimationFrame(debouncedCb)
    }

    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [showBoxBorder])

  return showBoxBorder
}
