import React, { ReactNode } from 'react'

import Video from '../Video/Video'
import { getIdFromUrl, getEmbedUrlFromId } from '../Video/services/getEmbedUrl'

import clsx from 'clsx'

import homeStyles from './styles/styles-home.module.scss'
import CollapsiblePanelBase from '../CollapsiblePanel/base'

type VideoComponentWithListProps = {
  heading?: string
  videoUrl: string
  listPlaceholder: ReactNode
  className?: string
  transcript?: ReactNode
  lazyLoad?: boolean
}

type StylingProps = {
  styles: { [key: string]: string }
}

const VideoComponentWithList = ({
  heading,
  videoUrl,
  listPlaceholder,
  styles,
  transcript,
  lazyLoad = false,
}: VideoComponentWithListProps & StylingProps) => {
  const videoId = getIdFromUrl(videoUrl) || ''
  const videoEmbedUrl = videoId ? getEmbedUrlFromId(videoId) : ''

  const styleClassOverride = styles === homeStyles ? 'q221' : 'contain'

  const videoEl = (
    <div className={styles.multiColVideoComponent}>
      <Video id={videoId} embedUrl={videoEmbedUrl} lazyLoad={lazyLoad} />
      {transcript && (
        <div className={styles.transcriptContainer}>
          <CollapsiblePanelBase
            testId="VideoComponentWithList-TranscriptPanel"
            toggle={
              <span className={styles.titleHeading}>
                View video transcript.
              </span>
            }
            open={false}
            styleOverrides={{ body: styles.body, toggle: styles.toggle }}
            isVideoAccordion
          >
            {transcript}
          </CollapsiblePanelBase>
        </div>
      )}
    </div>
  )

  return (
    <div
      data-test-id={'VideoComponentWithList-Wrap'}
      className={clsx(
        styles.multiColumnVideoContainer,
        'VideoComponentWithList',
        styleClassOverride
      )}
    >
      <div className={clsx(styles.multiColumnVideo)}>
        {heading && <h2 className={clsx(styles.header)}>{heading}</h2>}
        <div className={styles.columns}>
          <div className={styles.leftColumn}>{videoEl}</div>
          <div className={clsx(styles.col, styles.rightColumn)}>
            {listPlaceholder}
          </div>
        </div>
      </div>
    </div>
  )
}

export default VideoComponentWithList
