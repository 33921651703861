import React from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'

type CurrentPageProps = {
  title: JSX.Element
}

export function CurrentPage({ title }: CurrentPageProps) {
  return (
    <span
      className={clsx(styles.crumb, styles.currentPageCrumb)}
      key="current"
    >
      {title}
    </span>
  )
}
