import * as React from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'

type PolicyCardListProps = {
  heading: React.ReactNode
  descriptor: React.ReactNode
  cards: React.ReactNode
  foot?: React.ReactNode
}

export default function PolicyCardList({
  heading,
  descriptor,
  cards,
  foot,
}: PolicyCardListProps) {
  return (
    <div data-test-id="PolicyCardList" className={clsx('PolicyCardList', styles.root)}>
      <h2 className={clsx(styles.heading, 'h2', 'brick')}>{heading}</h2>
      <div className={clsx(styles.descriptor, 'descriptor', 'brick')}>
        {descriptor}
      </div>
      <div className={clsx(styles.cards, foot && styles.footMargin)}>{cards}</div>
      {foot && <div className={styles.foot}>{foot}</div>}
    </div>
  )
}
