
let scrollFixSet = false;
let originalX = 0;
let originalY = 0;
let originalScrollTop = 0;

// on initial focus in Chrome on iOS 14, the top of the header is scrolled out of view
// https://stackoverflow.com/questions/38619762/how-to-prevent-ios-keyboard-from-pushing-the-view-off-screen-with-css-or-js
export function iPhoneScrollFix() {
  const isiPhone = /iPhone/.test(navigator.userAgent)
  const isChrome = /CriOS/.test(navigator.userAgent)

  if (isiPhone && isChrome) {
    if(!scrollFixSet) {
      console.log(`[iPhoneScrollFix] capture current scroll values (${window.scrollX}, ${window.scrollY}), scrollTop: ${document.body.scrollTop}`)
      originalX = window.scrollX;
      originalY = window.scrollY;
      originalScrollTop = document.body.scrollTop;
      scrollFixSet = true;
    }

    console.log('[iPhoneScrollFix] reset body scroll to top')
    window.scrollTo(0, 0)
    document.body.scrollTop = 0
  }
}

export function iPhoneScrollRestore() {
  if (scrollFixSet) {
    console.log(`[iPhoneScrollRestore] restore body scroll to (${originalX}, ${originalY}), scrollTop: ${originalScrollTop}`)
    window.scrollTo(originalX, originalY)
    document.body.scrollTop = originalScrollTop;
    scrollFixSet = false;
  }
}