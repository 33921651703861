import { get, isEmpty } from 'lodash-es'
import { footerMapperPropsType } from '../types'
import { DEFAULT_VALUES } from '../../../../../constants/policy-recommendation'
import {
  AdditionalPolicyResType,
  PolicyResType,
} from '../../../services/policy-lookup/types'
import { Item } from '../../../../../types/SitecoreAdapter'
import {
  PRTPageSettings,
  RecommendedPolicyAdapterProps,
} from '../../../../../page-components/PolicyRecommendation/components/Questionnaire/types'

export const getPolicyCodes = ({
  pageData,
  selectedPolicies,
}: Pick<footerMapperPropsType, 'pageData' | 'selectedPolicies'>) =>
  (pageData !== undefined && selectedPolicies.length > 0
    ? selectedPolicies.map((sp) =>
        pageData.fields.policyData.find(({ id }) => id === sp)
      )
    : []
  ).map((a) => get(a, 'fields.policyCode.value'))

export const mapFooterData = ({
  pageData,
  onStartOver,
  selectedPolicies,
  onGetQuote,
}: footerMapperPropsType) => ({
  scheduleCallCTA: {
    icon: get(pageData, 'fields.calendlyCtaIcon.value'),
    text: get(
      pageData,
      'fields.calendlyCtaLinkText.value',
      DEFAULT_VALUES.callCTA
    ),
  },
  actionBtns: {
    startOver: {
      children: get(
        pageData,
        'fields.startOverCtaButtonText.value',
        DEFAULT_VALUES.startOverCTA
      ),
      onClick: () => {
        window.scrollTo(0, 0)
        onStartOver()
      },
    },
    getQuote: {
      disabled: isEmpty(selectedPolicies),
      children: get(
        pageData,
        'fields.getQuotesCtaButtonText.value',
        DEFAULT_VALUES.getQuoteCTA
      ),
      onClick: () => onGetQuote && onGetQuote(),
    },
  },
  description: get(
    pageData,
    'fields.footerText.value',
    DEFAULT_VALUES.footerDesc
  ),
})

type MapPolicyParamType = {
  pageData?: Item<PRTPageSettings>
  policies: Array<PolicyResType | AdditionalPolicyResType>
}

export const mapPolicyData = ({ policies, pageData }: MapPolicyParamType) =>
  pageData !== undefined && policies.length > 0
    ? policies.map(({ policy: policyItemId, ...recInfo }) => {
        const { fields: p, id = '' } = pageData.fields.policyData.find(
          ({ id }) => id === policyItemId
        ) as Item<RecommendedPolicyAdapterProps>
        return {
          title: p.policyTitleText.value,
          modalTitle:
            get(p, 'policyModalTitle.value') || get(p, 'policyTitleText.value'),
          icon: p.policyIcon.value,
          description: p.detailedPolicyDescription.value,
          id,
          ...recInfo,
        }
      })
    : []
