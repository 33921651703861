export const BlogAuthorTitles = {
  VIEW_LINKEDIN: 'View LinkedIn',
  EDITORIAL_POLICIES: 'editorial policies',
  FULL_BIO: 'Full Bio >',
  SUMMARY: 'Summary',
  EXPERIENCE: 'Experience',
  EDUCATION: 'Education',
}

export const DEFAULT_VALUES = {
  REVIEWED_BY: 'Reviewed by',
  INSUREON_STAFF: "insureon staff",
}
