import React from 'react'
import { Image, RichText, Text } from '@sitecore-jss/sitecore-jss-react'
import PolicyCard from './PolicyCard'
import {
  TextField,
  LinkField,
  ImageField,
  Item,
} from '../../types/SitecoreAdapter'

import PolicyCardButtons from './PolicyCardButtons'

import HeadingAdapter from '../Heading'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type PolicyCardAdapterProps = {
  fields: {
    link: LinkField
    icon: ImageField
    title: TextField
    tag: Item<{ tag: TextField }>
    body: TextField
    bestFor: TextField
    linkTitle: TextField
    desktopButton: TextField
    mobileButton: TextField
    quoteButton: TextField
  }
}

function learnMoreLabel(mobileButton: TextField, desktopButton: TextField) {
  return <Text field={mobileButton.value ? mobileButton : desktopButton} />
}

const PolicyCardAdapter = ({
  fields: {
    link,
    icon,
    title,
    tag,
    body,
    bestFor,
    linkTitle,
    desktopButton,
    mobileButton,
    quoteButton,
  },
}: PolicyCardAdapterProps) => {
  return (
    <PolicyCard
      icon={<Image field={icon} loading="lazy" />}
      linkHref={link.value.href}
      linkTitle={linkTitle.value}
      heading={
        <HeadingAdapter
          testId={'PolicyCard-Heading'}
          fields={{
            title,
            mobileTitle: title,
            tag: tag,
          }}
          addStyle={{ title: 'h4 headingBottom' }}
        />
      }
      body={<RichText field={body} />}
      bestFor={<RichText field={bestFor} />}
    >
      <PolicyCardButtons
        getQuotesLabel={<Text field={quoteButton} />}
        learnMoreLabel={learnMoreLabel(mobileButton, desktopButton)}
      />
    </PolicyCard>
  )
}

export default withErrorBoundary(PolicyCardAdapter, {
  component: 'Policy-Card',
})
