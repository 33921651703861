import * as React from 'react'
import { Event, Event_PreselectCheck } from '../state-management/events'
import { IOption } from '../types'
import { IValidateWebBusClass } from './SearchController'

export default function usePreselectCheck(
  validateService: IValidateWebBusClass,
  selection: false | IOption,
  dispatch: React.Dispatch<Event_PreselectCheck>
) {
  React.useEffect(() => {
    if (selection) {
      validateService.isValidWebBusClass(selection.value).then(result => {
        if (result === false) {
          console.log(
            '[usePreselectCheck] Preselected option failed check ',
            selection.value
          )
          dispatch({
            type: Event.PreselectCheck,
            valid: false,
          })
        }
      })
    }
  }, [])
}
