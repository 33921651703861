import React from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'
import Button from '../Button'
import ModalButton from './ModalButton'

type PolicyCardButtonProps = {
  getQuotesLabel: React.ReactElement
  learnMoreLabel: React.ReactElement
}

const PolicyCardButtons = ({
  getQuotesLabel,
  learnMoreLabel,
}: PolicyCardButtonProps) => {
  return (
    <div className={styles.buttonGroup}>
      <ModalButton
        testId={'PolicyCardQuote-GetQuoteBtn'}
        getQuotesLabel={getQuotesLabel}
      />
      <Button
        className={clsx('button', styles.button)}
        type="secondary"
        size="medium"
        testId={'PolicyCardQuote-LearnMoreBtn'}
      >
        {learnMoreLabel}
      </Button>
    </div>
  )
}

export default React.memo(PolicyCardButtons)
