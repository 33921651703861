import React, { FC } from 'react'
import styles from './styles.module.scss'
import ScheduleCallCTA, { ScheduleCallCTAProps } from './ScheduleCallCTA'
import Button, { ButtonProps } from '../../../../../../components/Button'

interface FooterProps {
  scheduleCallCTA: ScheduleCallCTAProps
  actionBtns: {
    startOver: ButtonProps
    getQuote: ButtonProps
  }
  description: string
}

const Footer: FC<FooterProps> = ({
  scheduleCallCTA,
  actionBtns,
  description,
}) => {
  return (
    <div
      data-test-id={'prt-recommendation-footer-wrap'}
      className={styles.policyListFooterWrap}
    >
      <div
        data-test-id={'prt-recommendation-footer-actions'}
        className={styles.footerActionsWrap}
      >
        <div className={styles.actionCTAWrap}>
          <Button
            testId={'prt-recommendation-start-over'}
            type="secondary"
            {...actionBtns.startOver}
          />
          <Button
            testId={'prt-recommendation-quotes-cta'}
            type="primary"
            {...actionBtns.getQuote}
          />
        </div>
        <ScheduleCallCTA {...scheduleCallCTA} />
      </div>
      <div
        data-test-id={'prt-recommendation-footer-disclaimer'}
        className={styles.footerDesclaimerWrap}
      >
        <p>{description}</p>
      </div>
    </div>
  )
}

export default Footer
