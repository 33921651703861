import { BreakpointSpec } from "../Media/domain"

export const imageBreakpoints = {
  xsMax: 599,
  sm:    600,
  smMax: 1023,
  laptop: 1024,
  laptopMax: 1199,
  md:    1200,
  mdMax: 1599,
  lg:    1600,
  lgMax: 1899,
  xl:    1900
}

export const heroBreakpoints: BreakpointSpec[] = [
  ['mobile', '(max-width: 599px)'],
  ['tablet', '(min-width: 600px) and (max-width: 1023px)'],
  ['laptop', '(min-width: 1024px) and (max-width: 1199px)'],
  ['desktop', '(min-width: 1200px) and (max-width: 1399px)'],
  ['wide', '(min-width: 1400px)'],
]