import React from 'react'
import { RichText } from '@sitecore-jss/sitecore-jss-react'
import clsx from 'clsx'
import spaceStyles from '../../styles/space.module.scss'
import styles from './styles.module.scss'
import { Item, TextField, BooleanField } from '../../types/SitecoreAdapter'
import CTALinkIntercept from '../../quotes/CTALinkIntercept'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type BodytextProps = Item<{ body: TextField; enableSpacing?: BooleanField }>

const Bodytext = ({ fields: { body, enableSpacing } }: BodytextProps) => {
  return (
    <CTALinkIntercept>
      <RichText
        className={clsx(
          spaceStyles.contain,
          styles.body,
          'BodyText',
          'list-style',
          enableSpacing && enableSpacing.value && 'ListSpacingOverride'
        )}
        field={body}
      />
    </CTALinkIntercept>
  )
}

export default withErrorBoundary(Bodytext, { component: 'Bodytext' })
