import React from 'react'
import { Image, Text } from '@sitecore-jss/sitecore-jss-react'
import clsx from 'clsx'
import Media from '../Media'
import DesktopCarrierBand from './Desktop'
import Slideshow from '../Carrier-Band-Slides/Slideshow'
import * as SC from '../../types/SitecoreAdapter'
import { ImageFieldValue } from '@sitecore-jss/sitecore-jss-react/types/components/Image'

import styles from './styles.module.scss'
import { usePageType } from '../../util/usePageType'
import { PageTypes } from '../../constants/page-type'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type SlideAdapter = {
  url: string
  alt: SC.TextField
  width: SC.TextField
  height: SC.TextField
}

// this data is the result of an integrated graphql query
// that means, the graphql query runs on the server, so data is fetched in time for SSR
// the reason we used graphql was to get the url of the images in a list
// out of the box, a treelist of image items didn't provide src urls
// we told Sitecore that this amount of ceremony to get src urls for a list of images is a bit silly, and
// they have taken that into consideration

type CarrierBandAdapterProps = {
  fields: {
    data: {
      datasource: {
        title: {
          jss: {
            value: string
          }
        }
        desktopImage: {
          alt: string
          height: null | string
          width: null | string
          src: null | string
        }
        tabletSlides: {
          targetItems: SlideAdapter[]
        }
        mobileSlides: {
          targetItems: SlideAdapter[]
        }
        mobileDuration: {
          value: string
        }
        tabletDuration: {
          value: string
        }
      }
    }
  }
}

const CarrierBand = ({
  fields: {
    data: {
      datasource: {
        title,
        desktopImage,
        tabletSlides,
        mobileSlides,
        mobileDuration,
        tabletDuration,
      },
    },
  },
}: CarrierBandAdapterProps) => {
  const intro = <Text field={title.jss} className={styles.intro} tag="div" />
  const pageType = usePageType()
  const isPartnerPage = pageType === PageTypes.PARTNER_PAGE

  return (
    <div
      data-test-id="CarrierBand"
      className={clsx(
        styles.wrap,
        'CarrierBand',
        isPartnerPage ? 'partnerBand' : ''
      )}
    >
      {intro}
      <Media
        desktop={() => (
          <DesktopCarrierBand
            key="desktop-carrier-band"
            image={<Image field={{ value: desktopImage }} />}
          />
        )}
        tablet={() => (
          <Slideshow
            key="tablet-carrier-band"
            slides={getSlides(tabletSlides)}
            height={80}
            width={750}
            className={styles.center}
            durationSeconds={getDuration(tabletDuration, 4)}
          />
        )}
        mobile={() => (
          <Slideshow
            key="mobile-carrier-band"
            slides={getSlides(mobileSlides)}
            height={60}
            width={300}
            className={styles.center}
            durationSeconds={getDuration(mobileDuration, 2)}
          />
        )}
      />
    </div>
  )
}

export function getDuration(
  { value }: { value: string },
  defaultSeconds: number
): number {
  if (value === '') return defaultSeconds
  const parsedSeconds = parseInt(value, 10)
  return isNaN(parsedSeconds) ? defaultSeconds : parsedSeconds
}

function getSlides({
  targetItems,
}: {
  targetItems: SlideAdapter[]
}): JSX.Element[] {
  return targetItems.map((slide) => {
    // the graphql on the server gets close to the right shape, but not quite there
    // so we do some adjustments here, to make it closer to a regular Image
    const imageProps: ImageFieldValue = {
      src: slide.url,
      alt: slide.alt.value,
      width: slide.width.value,
      height: slide.height.value,
    }

    return <Image key={imageProps.src} field={{ value: imageProps }} />
  })
}

export default withErrorBoundary(CarrierBand, { component: 'Carrier-Band-Slides' })
