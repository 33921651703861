import React, { useEffect, useState } from 'react'
import useIntersect from '../../../util/useIntersect'
import { isServer } from '../../../util/getEnv'
import { WrapperChildProps } from '.'

type LazyLoadWrapperProps = {
  className: string
} & WrapperChildProps

const LazyLoadWrapper = ({ children, className }: LazyLoadWrapperProps) => {
  const [showVideo, setShowVideo] = useState(false)

  if (isServer) {
    return (
      <div data-test-id={'video-container'} className={className}>
        {children(false)}
      </div>
    )
  }

  const [ref, entry, disconnectObserver, supported] = useIntersect({
    threshold: 0,
    rootMargin: '50px',
  })

  useEffect(() => {
    if (!supported || entry.isIntersecting) {
      setShowVideo(true)
      disconnectObserver()
    }
  }, [entry, supported])

  return (
    <div data-test-id={'video-container'} className={className} ref={ref}>
      {children(showVideo)}
    </div>
  )
}

export default React.memo(LazyLoadWrapper)
