import React from 'react'
import { Placeholder, RichText } from '@sitecore-jss/sitecore-jss-react'
import * as SC from '../../types/SitecoreAdapter'

import PageTypography from '../PageTypography'

import VideoComponentWithListBase from './VideoComponentWithList'

import copyStyles from './styles/styles-copy.module.scss'
import homeStyles from './styles/styles-home.module.scss'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type VideoComponentWithListFields = {
  heading: SC.TextField
  videoUrl: SC.TextField
  videoTranscript?: SC.TextField
  lazyLoad?: SC.BooleanField
}

type VideoComponentWithListProps = {
  fields: VideoComponentWithListFields
  rendering: any
}

const VideoComponentWithList = (props: VideoComponentWithListProps) => {
  const getStyleClass = (pageType: string) => {
    switch (pageType) {
      case 'home':
        return homeStyles
      case 'policy':
      case 'article':
      case 'blog':
      default:
        return copyStyles
    }
  }

  const transcriptElCheck =
    props.fields.videoTranscript && props.fields.videoTranscript.value != ''

  return (
    <PageTypography>
      {(pageType: string) => (
        <VideoComponentWithListBase
          heading={props.fields.heading && props.fields.heading.value}
          videoUrl={props.fields.videoUrl && props.fields.videoUrl.value}
          listPlaceholder={
            <Placeholder name="video-bullet-list" rendering={props.rendering} />
          }
          styles={getStyleClass(pageType)}
          transcript={
            (transcriptElCheck && (
              <RichText field={props.fields.videoTranscript} />
            )) ||
            null
          }
          lazyLoad={
            props.fields && props.fields.lazyLoad && props.fields.lazyLoad.value
          }
        />
      )}
    </PageTypography>
  )
}

export default withErrorBoundary(VideoComponentWithList, {
  component: 'VideoComponentWithList',
})
