import React, { ReactNode } from 'react'
import clsx from 'clsx'
import spaceStyles from '../../styles/space.module.scss'
import styles from './styles.module.scss'

function IconTabList({ children }: { children: ReactNode }) {
  return (
    <div className={clsx(styles.list, spaceStyles.contain)}>
      {children}
    </div>
  )
}

export default IconTabList
