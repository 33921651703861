import React from 'react'
import {
  Field,
  Image,
  ImageField,
  Text,
} from '@sitecore-jss/sitecore-jss-react'

import clsx from 'clsx'
import ModalCTA from '../Modal-CTA/ModalCTA'

import styles from './styles.module.scss'
import { BooleanField, TextField } from '../../types/SitecoreAdapter'
import LinkWrapper from '../LinkWrapper'
import CalendlyWidgetFromRoute from '../CalendlyWidget/CalendlyWidgetFromRoute'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type InContentCTAProps = {
  fields: {
    heading: TextField
    button: TextField
    text: TextField
    href: TextField
    title: TextField
    openCalendly: BooleanField
    newWindow?: BooleanField
    direction: Field<'ltr' | 'rtl'>
    ctaImage: ImageField
  }
}

const Incontent = (props: InContentCTAProps) => {
  const direction = props?.fields?.direction?.value || 'ltr'
  const ctaImage = props?.fields?.ctaImage?.value

  const learnMore = (props?.fields?.openCalendly?.value ||
    props?.fields?.href?.value) && (
    <div className={clsx(styles.learnMore, 'LEARN MORE')}>
      {props.fields.openCalendly.value ? (
        <CalendlyWidgetFromRoute textOverride={props.fields.text.value} />
      ) : props.fields.href && props.fields.href.value ? (
        <LinkWrapper
          href={props.fields.href.value}
          text={props.fields.text.value}
          title={props.fields.title.value}
          newWindow={
            (props.fields.newWindow && props.fields.newWindow.value) || false
          }
        />
      ) : null}
    </div>
  )

  return (
    <div className={clsx('contain block In-Content', styles.wrap)}>
      <div className={styles.grad} />
      <div className={clsx(styles.container, styles[direction])}>
        <div className={styles.ctaContentWrap}>
          <Text
            field={props.fields.heading}
            tag="div"
            className={styles.head}
          />
          <div>
            <div className={styles.buttonWrapper}>
              <ModalCTA
                buttonClassName={clsx('button', styles.modalButton)}
                wrapClassName={styles.modalButtonWrap}
                alignment={'Center'}
                primary={true}
                size="large"
              >
                {props.fields.button.value}
              </ModalCTA>
            </div>
          </div>
          {learnMore}
        </div>
        {ctaImage && (
          <div className={styles.ctaImageWrap}>
            <Image
              field={{
                ...ctaImage,
                width: 239,
                height: 157,
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default withErrorBoundary(Incontent, { component: 'In-content' })
