import React from 'react'
import SidebarSection from '../../../components/SidebarSection'
import styles from './styles.module.scss'
import { LinkWrapperWithChildItems } from '../../../components/LinkWrapper'

type RelatedPostsProps = {
  relatedPosts: RelatedPostLinkProps[]
}

export type RelatedPostLinkProps = {
  text: string
  href: string
}

const RelatedPostLink = ({ text, href }: RelatedPostLinkProps) => {
  if (!text || !href) return null

  return (
    <LinkWrapperWithChildItems href={href} className={styles.RelatedPostLink}>
      {text}
    </LinkWrapperWithChildItems>
  )
}

const RelatedPosts = ({ relatedPosts }: RelatedPostsProps) => {
  const relatedPostEls = relatedPosts.map(props => (
    <RelatedPostLink text={props.text} href={props.href} key={props.href} />
  ))

  if (relatedPostEls.length === 0) {
    return null
  }

  return (
    <SidebarSection testId="BlogPage-RelatedPosts-SideSection" title="RELATED POSTS" bottomMargin="40">
      {relatedPostEls}
    </SidebarSection>
  )
}

export default RelatedPosts
