import * as React from 'react'
import styles from './styles.module.scss'

export default function ContactBar({
  children
}: {children?: React.ReactNode}) {
  return (
    <div data-test-id="ContactBar" className={styles.root}>
      {children}
    </div>
  )
}

