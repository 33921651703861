import React, { memo } from 'react'
import HeroAdapter, { HeroProps } from '../../../../page-components/Hero'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import HeroAppStartSwitch from '../../../../quotes/HeroAppStartSwitch'
import { BooleanField, RouteFields } from "../../../../types/SitecoreAdapter"
import { get } from 'lodash-es'

type HeroFields = {
  fields: HeroProps
}

type HeroAdapterProps = RouteFields<{
  hero: HeroFields
  tpWidgetGlobalSettings: {
    fields: {
      enableTrustPilotHeroWidget: BooleanField
    }
  }
}>

function Hero({
  sitecoreContext: {
    route: {
      fields: {
        hero,
        tpWidgetGlobalSettings: {
          fields: { enableTrustPilotHeroWidget },
        },
      },
    },
  },
  hasPolicyRecCTA,
}: HeroAdapterProps & { hasPolicyRecCTA: boolean }) {
  if (hero) {
    const heroProps = {
      ...hero.fields,
      applyDefaultImgSizing: true,
      enableTrustPilotHeroWidget,
    }

    return (
      <HeroAdapter {...heroProps} className="copy-article" tag="div">
        <HeroAppStartSwitch darkBg={get(heroProps, "isDark.value", false)} hasPolicyRecCTA={true} />
      </HeroAdapter>
    )
  } else {
    return null
  }
}

export default withSitecoreContext()(memo(Hero))
