import config from '../../temp/config'

type TempConfigJs = {
  env: string
  app: string
  portal: string
  professions: string
  typeahead: string
  typeaheadList: string
  naics: string
  tracker: string
  siteSearch: string
  sentryDsn: string
}

type EnvironmentSettings = {
  env: string
  endpoints:{
    app: string
    portal: string
    professions: string
    typeahead: string
    typeaheadList: string
    naics: string
    tracker: string
    siteSearch: string
    sentryDsn: string
  }
}

export default function getEnvironmentFromConfig(brandCode: string, busUnitId: string) : EnvironmentSettings {

  const {
    env,
    app,
    portal,
    professions,
    typeahead,
    typeaheadList,
    naics,
    tracker,
    siteSearch,
    sentryDsn
  }: TempConfigJs = config

  return {
    env: env,
    endpoints: {
      app: replaceTokens(app, brandCode, busUnitId),
      portal: replaceTokens(portal, brandCode, busUnitId),
      professions: replaceTokens(professions, brandCode, busUnitId),
      typeahead: replaceTokens(typeahead, brandCode, busUnitId),
      typeaheadList: replaceTokens(typeaheadList, brandCode, busUnitId),
      naics: replaceTokens(naics, brandCode, busUnitId),
      tracker: replaceTokens(tracker, brandCode, busUnitId),
      siteSearch: replaceTokens(siteSearch, brandCode, busUnitId),
      sentryDsn: replaceTokens(sentryDsn, brandCode, busUnitId),
    }
  }
}

function replaceTokens(url: string, brandCode: string, busUnitId: string) {
  const brandToken = '$BRAND'
  const busUnitToken = '$BUSUNITID'
  return url.replace(brandToken, brandCode).replace(busUnitToken, busUnitId)
}
