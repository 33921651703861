import React, { FC } from "react";
import { useRecommendedPolicies } from "./services/useRecommendedPolicies";
import Footer from "./components/Footer";
import PolicyListSection from "./components/PolicyListSection";
import { DEFAULT_VALUES } from "../../../../constants/policy-recommendation";

const RecommendedPolicies: FC = () => {

  const {
    footer,
    recommendedPolicies,
    additionalRecommendedPolicies,
    selectedPolicies,
    onPolicySelect
  } = useRecommendedPolicies()

  return (
    <div>
      <PolicyListSection
        key="recommendedPolicies"
        title={DEFAULT_VALUES.recommendedSectionTitle}
        policies={recommendedPolicies}
        {...{ selectedPolicies, onPolicySelect }}
      />
      <PolicyListSection
        key="additionalRecommendedPolicies"
        title={DEFAULT_VALUES.additionalSectionTitle}
        policies={additionalRecommendedPolicies}
        {...{ selectedPolicies, onPolicySelect }}
      />
      <Footer {...footer} />
    </div>
  );
}

export default RecommendedPolicies;