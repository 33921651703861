import * as React from 'react'
import { PopoverProps } from './types'
import { usePopover } from './usePopover'
import { PopoverContext } from './context'
import { PopoverTrigger } from './components/Trigger'
import { PopoverContent } from './components/Content'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

function Popover({
  children,
  modal = false,
  ...restOptions
}: {
  children: React.ReactNode
} & PopoverProps) {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const popover = usePopover({ modal, ...restOptions })
  return (
    <PopoverContext.Provider value={popover}>
      {children}
    </PopoverContext.Provider>
  )
}

export default withErrorBoundary(Popover, { component: 'Popover' })

export { PopoverTrigger, PopoverContent }
