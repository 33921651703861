import React, { ReactNode } from 'react'
import { Column } from '../types'

function TH({ children = null }: { children?: ReactNode }) {
  return <th>{children}</th>
}

function TD({ children = null }: { children?: ReactNode }) {
  return <td>{children}</td>
}

function getRowElements(data: Column[]) {
  const rowCount = getRowCount(data)
  const rows = []
  for (let i = 0; i < rowCount; i++) {
    rows.push(
      data.map((column, index) => <td key={index}>{column.rows[i]}</td>)
    )
  }

  return rows
}

function getRowCount(data: Column[]): number {
  return data.reduce((count, column) => Math.max(count, column.rows.length), 0)
}

export function addRowHeadings(
  bold: boolean = true,
  headings: ReactNode[],
  columns: Column[]
) {
  const Cell = bold ? TH : TD

  return getRowElements(columns).map((row, index) => (
    <tr key={index}>
      <Cell>{headings[index]}</Cell>
      {row}
    </tr>
  ))
}
