import React from 'react'
import { RichText } from '@sitecore-jss/sitecore-jss-react'
import clsx from 'clsx'
import baseStyles from '../Bodytext/styles.module.scss'
import styles from './styles.module.scss'
import { BooleanField, TextField } from '../../types/SitecoreAdapter'
import CTALinkIntercept from '../../quotes/CTALinkIntercept'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type BlogContentProps = {
  fields: {
    content: TextField
    enableSpacing?: BooleanField
  }
}

const BlogContent = ({
  fields: { content, enableSpacing },
}: BlogContentProps) => (
  <CTALinkIntercept>
    <RichText
      field={content}
      className={clsx(
        'BodyText list-style',
        baseStyles.body,
        styles.body,
        enableSpacing && enableSpacing.value && 'ListSpacingOverride'
      )}
    />
  </CTALinkIntercept>
)

export default withErrorBoundary(BlogContent, { component: 'BlogContent' })
