import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react'

type PageEditingProps = {
  children: (pageType: string) => JSX.Element | null
}

// To check if the page uses copy/article typography
function PageTypography({ children }: PageEditingProps) {
  const {
    sitecoreContext: { route },
  } = useSitecoreContext()
  switch (route?.templateName) {
    case 'Policy-Page-Type':
      return children('policy')
    case 'Home-Page-Type':
      return children('home')
    case 'Article-Page-Type':
      return children('article')
    case 'Blog-Page-Type':
      return children('blog')
    default:
      return children('copy')
  }
}

// Util to get appropriate style classes based on page type
export const getPageStyles = (pageType: string) => {
  switch (pageType) {
    case 'Policy-Page-Type':
      return 'policy'
    case 'Home-Page-Type':
      return 'home'
    case 'Article-Page-Type':
      return 'article'
    case 'Blog-Page-Type':
      return 'blog'
    default:
      return 'copy'
  }
}

// there's a bug in the type definitions for this version of JSS that incorrect flags
// any props other than sitecoreContext to a component as an error
// @ts-ignore
export default PageTypography
