import React from 'react'
import Modal, { ModalProps } from './Modal'
import * as Dialog from '@radix-ui/react-dialog'

export default function ResponsiveModal({
  modalHeading,
  children,
  onClose,
  className,
  desktopSize,
}: ModalProps) {
  const modal = (
    <Modal
      modalHeading={modalHeading}
      onClose={onClose}
      className={className}
      desktopSize={desktopSize}
    >
      {children}
    </Modal>
  )

  return (
    <Dialog.Root open={true} aria-label="modal dialog">
      {modal}
    </Dialog.Root>
  )
}
