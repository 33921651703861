import React, { FC } from 'react'
import { PolicyAccordionProps } from '../../types'
import { isEmpty } from 'lodash-es'
import ViewMorePolicyInfo from './ViewMoreInfo'

import styles from './styles.module.scss'

export const PolicyAccordionDetails: FC<PolicyAccordionProps> = ({
  icon,
  modalTitle,
  bestFor,
  whyRec = [],
  description,
}) => (
  <div
    data-test-id={'prt-recommendation-policy-details'}
    className={styles.accDetailsWrap}
  >
    <div className={styles.policyDetailsIcon}>
      <img {...icon} width={76} height={66} />
    </div>
    <div className={styles.whyRecommendedWrap}>
      {!isEmpty(whyRec) && (
        <dl data-test-id={'prt-recommendation-policy-details-why-recommend'}>
          <dt>
            <div className={styles.whyRecommendedWrapTitle}>
              Why recommended:
            </div>
          </dt>
          <dd>
            <ul>
              {whyRec.map((r, i) => (
                <li key={r + i}>{r}</li>
              ))}
            </ul>
          </dd>
        </dl>
      )}
      {!isEmpty(bestFor) && (
        <dl data-test-id={'prt-recommendation-policy-details-best-for'}>
          <dt>
            <div className={styles.whyRecommendedWrapTitle}>Best for:</div>
          </dt>
          <dd>
            <ul>
              {bestFor.map((r, i) => (
                <li key={r + i}>{r}</li>
              ))}
            </ul>
          </dd>
        </dl>
      )}
    </div>
    <ViewMorePolicyInfo {...{ icon, title: modalTitle, description }} />
  </div>
)
