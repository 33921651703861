import * as React from 'react'
import styles from './styles.module.scss'
import { LinkWrapperWithChildItems } from '../LinkWrapper'

type EmailCardProps = {
  icon: React.ReactNode
  label: React.ReactNode
  emailAddress: string
}

export default function EmailCard({
  icon,
  label,
  emailAddress,
}: EmailCardProps) {
  return (
    <LinkWrapperWithChildItems
      additionalProps={{
        'data-tag': 'ContactBar',
        'data-test-id': 'ContactBar-EmailLink',
      }}
      href={`mailto:${emailAddress}`}
      className={styles.card}
    >
      <div className={styles.icon}>{icon}</div>
      <div className={styles.label}>{label}</div>
    </LinkWrapperWithChildItems>
  )
}
