import React from 'react'

import Media from '../../components/Media'
import Heading from '../../components/Heading'
import Sidebar from '../blog/Sidebar'
import SidebarSection from '../../components/SidebarSection'
import BlogCard from '../../components/Blog-Card'
import BlogCardList from '../../components/Blog-Card/List'
import TagList from '../../page-components/TagTopics/Tag-List'

import styles from './styles.module.scss'
import spaceStyles from '../../styles/space.module.scss'

import clsx from 'clsx'
import ScrollToTopOnUpdate from '../../util/ScrollToTopOnUpdate'
import { addQueryParamsToUrl } from "../../util/imageUrlUtil"

type TagResultCardProps = {
  description: string
  href: string
  image: string
  imageAlt: string
  tag: string
  title: string
}

type RelatedTagsProps = {
  href: string
  label: string
}

type TagResultsProps = {
  tagName: string
  tagPath: string
  cards: TagResultCardProps[]
  pagination: React.ReactNode
  contact: React.ReactNode
  isBlog: boolean
  relatedTags: RelatedTagsProps[]
  ctaBanner: React.ReactNode
}

function TagResults({
  tagName,
  tagPath,
  cards,
  pagination,
  contact,
  isBlog,
  relatedTags,
  ctaBanner,
}: TagResultsProps) {
  const divider = <div className={styles.divider}></div>

  const renderResults = () => (
    <div className={styles.body}>
      {cards && (
        <BlogCardList fullWidth>
          {cards.map(card => (
            <BlogCard
              key={card.href}
              title={card.title}
              href={card.href}
              tag={card.tag}
              image={card.image && addQueryParamsToUrl(card.image, `mh=220`)}
              imageAlt={card.imageAlt}
              imageWidth={350}
              imageHeight={220}
              horizontal
              feature={false}
            >
              {card.description ? card.description : false}
            </BlogCard>
          ))}
        </BlogCardList>
      )}
      {pagination}
      {divider}
    </div>
  )

  const renderNoResults = () => (
    <div className={styles.noResults}>
      <span className={styles.noResultsText}>
        Sorry, no tags match this search
      </span>
    </div>
  )

  const body = cards && cards.length ? renderResults() : renderNoResults()

  const h1Tag = { fields: { tag: { value: 'h1' } } }

  const topic = <div className={styles.topic}>Topic</div>

  const heading = (
    <Heading
      testId={'TagResultsPage-Heading'}
      fields={{
        title: { value: tagName },
        tag: h1Tag,
      }}
      embed
    />
  )

  const relatedTagsSection = relatedTags.length > 0 && (
    <SidebarSection testId="TagsResultPage-RelatedTopics-SideSection" title="Related Topics" className={styles.aside}>
      <TagList tags={relatedTags} rootPath={isBlog ? '/blog' : ''} />
    </SidebarSection>
  )

  const aside = (
    <>
      {relatedTagsSection}
      <SidebarSection testId="TagsResultPage-Contact-SideSection" title="Contact Us" className={styles.aside}>
        {contact}
      </SidebarSection>
    </>
  )

  const desktopAside = <div className={styles.desktopAside}>{aside}</div>

  const articleMobile = (
    <article>
      {topic}
      {heading}
      {body}
    </article>
  )

  const articleDesktop = (
    <article>
      {topic}
      {heading}
      {body}
      {ctaBanner}
    </article>
  )

  return (
    <Media
      desktop={() => (
        <ScrollToTopOnUpdate>
          <div className="copy-article">
            <Sidebar main={articleDesktop} bar={desktopAside} transparent />
          </div>
        </ScrollToTopOnUpdate>
      )}
      mobile={() => (
        <ScrollToTopOnUpdate>
          <div className="copy-article">
            <div
              className={clsx(
                'block',
                spaceStyles.contain,
                styles.article
              )}
            >
              {articleMobile}
            </div>
            {ctaBanner}
            <aside className={styles.creamyBg}>
              <div className={clsx(spaceStyles.contain, styles.article)}>
                {aside}
              </div>
            </aside>
          </div>
        </ScrollToTopOnUpdate>
      )}
    />
  )
}

export default TagResults
