import React, { FC } from 'react'
import Checkbox from '../../../../../../components/Checkbox'
import { PolicyAccordionProps } from '../../types'
import styles from './styles.module.scss'

export const PolicyAccordionSummary: FC<PolicyAccordionProps> = ({
  id,
  icon,
  isSelected,
  title,
  onPolicySelect,
}) => {
  const handlePolicySelection = (e: React.MouseEvent) => {
    e.stopPropagation()
    onPolicySelect && onPolicySelect(id)
  }

  return (
    <div
      data-test-id={'prt-recommendation-policy-title-summary'}
      className={styles.accPanelSummaryWrap}
    >
      <img {...icon} />
      <Checkbox
        id={id}
        checked={isSelected}
        label={<div data-test-id={`prt-recommendation-${title}`} className={styles.accPanelSummaryLabel}>{title}</div>}
        labelProps={{
          htmlFor: id,
          className: styles.policyCheckbox,
          onClick: e => e.preventDefault(),
        }}
        onClick={handlePolicySelection}
        className={styles.policyCheckbox}
      />
    </div>
  )
}
