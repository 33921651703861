import React, { ReactNode, useRef } from 'react'
import clsx from 'clsx'

import { useCardClick } from './useCardClick'

import styles from './styles.module.scss'
import ImageWrapper from '../ImageWrapper'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

export type BlogCardProps = {
  image?: JSX.Element | string
  imageAlt?: string
  imageWidth?: number | string
  imageHeight?: number | string
  title: string
  href: string
  tag: string
  children: ReactNode
  feature?: boolean
  horizontal?: boolean
  props?: any
  testId?: string
}

const BlogCard = ({
  image,
  imageAlt,
  imageWidth,
  imageHeight,
  title,
  href,
  tag,
  children,
  feature = false,
  horizontal = false,
  testId = 'BlogCard',
  ...props
}: BlogCardProps) => {
  const anchorEl = useRef<HTMLAnchorElement>(null)
  const [onCardMouseDown, onCardMouseUp, onAnchorMouseDown] =
    useCardClick(anchorEl)

  const cardImage =
    typeof image === 'string' ? (
      <ImageWrapper
        testId={'BlogCard-Image'}
        imageStyles={styles.image}
        src={image}
        alt={imageAlt}
      />
    ) : (
      image
    )

  const trim = feature ? 400 : 200
  const body = getBody(children, trim)

  const rootClassName = clsx(
    styles.card,
    !image && styles.noImage,
    feature && styles.feature,
    horizontal && styles.horizontal
  )

  return (
    <div
      className={rootClassName}
      {...props}
      onMouseDown={onCardMouseDown}
      onMouseUp={onCardMouseUp}
      data-test-id={testId}
    >
      {cardImage}
      <div className={clsx(styles.content, cardImage && styles.cardImage)}>
        <div className={styles.tag}>{tag}</div>
        <div className={styles.title}>
          <a
            ref={anchorEl}
            onMouseDown={onAnchorMouseDown}
            href={href}
            title={title}
            className={styles.blogCardLink}
          >
            {title}
          </a>
        </div>
        <div className={styles.body}>{body}</div>
      </div>
    </div>
  )
}

function getBody(children: ReactNode, trim: number) {
  // if body isn't defined, skip it
  if (children === undefined) {
    return null
  }

  // if body is a RichText field value, add as HTML
  if (typeof children === 'string' && children.startsWith('<p>')) {
    return <div dangerouslySetInnerHTML={{ __html: children }} />
  }

  // if body is text, trim it, and wrap in a <p>
  if (typeof children === 'string' && children.length > trim) {
    const trimmedBody =
      children.slice(0, trim).split(' ').slice(0, -1).join(' ') + '...'

    return <p>{trimmedBody}</p>
  }

  // if body is a React Element, render as is
  return children
}

export default withErrorBoundary(BlogCard, { component: 'BlogCard' })
