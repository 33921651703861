import React from 'react'
import { Route, Routes } from 'react-router-dom'
import JssRoute from '../JssRoute'
import {
  LayoutServiceData,
  SitecoreContext,
} from '@sitecore-jss/sitecore-jss-react'
import componentFactory from '../../temp/componentFactory'
import fixRemoveRenderingFunction from '../../util/RemoveRenderingPatch'
import { prepContext } from '../../util/context'

// React Router delegates app route resolution to Sitecore through this class
// This class handles cases of server rendering, server hydrating, and client side navigating
export type AppRootProps = {
  Router: any
  path: string
  serverData?: LayoutServiceData
}

const routeWildcard = '*'

// cases
// SSR -- have the data, use synchronous
// Hyrdate -- have the data, use synchronous
// Client -- don't have the data, async render
export class AppRoot extends React.Component<AppRootProps> {
  componentDidMount() {
    fixRemoveRenderingFunction()
  }

  render() {
    const Router = this.props.Router

    if (this.props.serverData) {
      console.log('[Update Context]', prepContext(this.props.serverData))
    }

    return (
      <>
        <SitecoreContext
          componentFactory={componentFactory}
          layoutData={this.props.serverData}
        >
          <Router location={this.props.path}>
            <Routes>
              <Route
                key={routeWildcard}
                path={routeWildcard}
                element={<JssRoute isSSR={!!this.props.serverData} />}
              />
            </Routes>
          </Router>
        </SitecoreContext>
      </>
    )
  }
}

export default AppRoot
