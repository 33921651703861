import { isEmpty } from 'lodash-es'
import React, { FC } from 'react'
import { QuestionType } from '../types'
import styles from './../styles.module.scss'

interface QustionHeaderProps {
  question: QuestionType
}

const QustionHeader: FC<QustionHeaderProps> = ({
  question: { questionsPageTitle, questionsPageDescription },
}) => {
  return (
    <>
      {!isEmpty(questionsPageTitle) || !isEmpty(questionsPageTitle) ? (
        <div className={styles.questionPageDescWrap}>
          <h2 data-test-id={'prt-question-page-title'}>{questionsPageTitle}</h2>
          <div
            data-test-id={'prt-question-page-desc'}
            className={styles.quesDescription}
          >
            {questionsPageDescription}
          </div>
        </div>
      ) : null}
    </>
  )
}

export default QustionHeader
