import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react'
import * as React from 'react'
import * as CMS from '../../../types/SitecoreAdapter'
import SidebarSection from '../../../components/SidebarSection'
import SocialSharing from '../../../page-components/SocialSharing'
import styles from '../styles.module.scss'

type ISocialSectionProps = CMS.RouteFields<{
  shareSectionHeader: CMS.TextField
}>

export function SocialSection({
  sitecoreContext: {
    route: {
      fields: { shareSectionHeader },
    },
  },
}: ISocialSectionProps) {
  return (
    <SidebarSection
      testId="ArticlePage-Social-SideSection"
      title={<Text field={shareSectionHeader} />}
      className={styles.socialSection}
    >
      <SocialSharing size={'34'} />
    </SidebarSection>
  )
}

export default withSitecoreContext()(SocialSection)
