import React, {
  DetailedHTMLProps,
  FC,
  InputHTMLAttributes,
  ReactNode,
} from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

type RadioProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label: ReactNode
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>
}

const Radio: FC<RadioProps> = ({
  label,
  className,
  labelProps,
  ...inputProps
}) => {
  return (
    <label
      {...labelProps}
      className={clsx(styles.label, labelProps && labelProps.className)}
    >
      <input
        data-test-id={'radio-selector'}
        className={clsx(styles.radio, className)}
        type="radio"
        {...inputProps}
      />
      <span data-test-id={`${label}-radio`}>{label}</span>
    </label>
  )
}

export default withErrorBoundary(Radio, { component: 'Radio' })
