import React from 'react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import TagList from './TagList'
import { TagProps } from '../../page-components/TagTopics/types'

type TagTopicsProps = {
  sitecoreContext: {
    tags: TagProps[]
  }
}

const TagTopics = ({ sitecoreContext: { tags } }: TagTopicsProps) => {
  if (!tags || tags.length === 0) return null
  return <TagList tags={tags} rootPath="/blog" />
}

export default withSitecoreContext()(TagTopics)
