import React, { FC, ReactNode } from 'react'
import Media from '../Media'
import { TextField } from '../../types/SitecoreAdapter'
import { HeadingTagField } from '../Heading/types'
import ColumnSection from './ColumnSection'

export type PolicyCardListProps = {
  title: TextField
  titleTag: HeadingTagField
  descriptor: TextField
  escapeText: ReactNode
  threeCol?: boolean
  forceSingleCol?: boolean
  children: Array<ReactNode>
}

const PolicyCardList: FC<PolicyCardListProps> = props => {
  const { threeCol = false, forceSingleCol = false } = props

  const Section1Column = React.memo(() => (
    <ColumnSection columnCount={1} {...props} />
  ))
  const Section2Column = React.memo(() => (
    <ColumnSection columnCount={2} {...props} />
  ))
  const Section3Column = React.memo(() => (
    <ColumnSection columnCount={3} {...props} />
  ))

  // page editor only works with single column
  if (forceSingleCol) {
    return <Section1Column />
  }

  return (
    <Media
      mobile={() => <Section1Column />}
      tablet={() => <Section2Column />}
      desktop={() => (threeCol ? <Section3Column /> : <Section2Column />)}
    />
  )
}

export default PolicyCardList
