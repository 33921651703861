import React from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'
import TrustPilotWidget from '../../../components/TrustPilot-Widget/TrustPilotWidget'
import Media from '../../../components/Media'

type HeroProps = {
  backgroundImage: JSX.Element
  title: JSX.Element
  subtitle?: JSX.Element
  isDark: boolean
  showTrustPilotWidget?: boolean
}

const AboutHeroBase = ({
  title,
  subtitle,
  backgroundImage,
  isDark,
  showTrustPilotWidget,
}: HeroProps) => {
  return (
    <div
      className={clsx(styles.hero, isDark && styles.dark)}
      data-test-context="Hero"
    >
      <div className={styles.image}>{backgroundImage}</div>
      <div className={styles.body}>
        <div className={styles.title}>{title}</div>
        {showTrustPilotWidget && (
          <div
            className={styles.aboutUsTPwidget}
            data-test-id={'about-us-hero-tp-widget'}
          >
            <Media
              mobile={() => (
                <TrustPilotWidget type={'microstar'} darkBg={isDark} />
              )}
              tablet={() => (
                <TrustPilotWidget type={'microcombo'} darkBg={isDark} />
              )}
            />
          </div>
        )}
        {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
      </div>
    </div>
  )
}

export default AboutHeroBase
