import React, { useRef, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../../../components/Button'
import SearchIcon from '../../assets/icon-gnav-search.svg'
import { CurrentBreakpoint } from '../../../../components/Media'

import styles from './styles.module.scss'
import { SEARCH_PAGE } from '../../api/api'

export type SearchInputProps = {
  defaultValue?: string
  onSearch?: () => void
}

function SearchInput({ defaultValue, onSearch } : SearchInputProps) {
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);

  const bp = useContext(CurrentBreakpoint)
  const placeholder =
    bp != 'mobile' ? 'Search by question or keyword' : 'Search'

  const handleClick = () => {
    const searchTerm = (inputRef.current && inputRef.current.value) || ''

    if (searchTerm == '') {
      return
    }

    onSearch && onSearch();
    const qFragment = `q=${(encodeURIComponent(searchTerm))}`;
    navigate(`${SEARCH_PAGE}?${qFragment}`);
  }

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      handleClick()
    }
  }

  return (
    <div className={styles.searchContainer} onKeyDown={handleKeypress}>
      <div className={styles.inputContainer}>
        <span className={styles.icon}>
          <img src={SearchIcon} alt="Search Icon" />
          <span className={styles.rectangle}></span>
        </span>
        <input
          type="text"
          ref={inputRef}
          autoFocus={true}
          className={styles.searchInput}
          placeholder={placeholder}
          defaultValue={defaultValue || ''}
        />
      </div>
      <div className={styles.buttonContainer}>
        <Button
          className={styles.confirmButton}
          size="medium"
          testId="ConfirmButton"
          // disabled={!inputRef.current.value}
          onClick={handleClick}
        >
          Go
        </Button>
      </div>
    </div>
  )
}

export default SearchInput
