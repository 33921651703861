import React, { ReactNode } from 'react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { RouteFields } from '../types/SitecoreAdapter'

type SearchAppStartSettings = any

type AppStartProps = RouteFields<{
  searchAppStart?: SearchAppStartSettings
}> & { children?: ReactNode }

export enum AppStartComponents {
  DoubleDropdown,
  SearchAppStart,
}

type AppStartComponent = {
  componentType: AppStartComponents
}

export const AppStartComponentContext = React.createContext(
  AppStartComponents.DoubleDropdown
)

function AppStartContext({
  sitecoreContext: {
    route: {
      fields: { searchAppStart },
    },
  },
  children,
}: AppStartProps) {
  const appStartComponent = searchAppStart
    ? AppStartComponents.SearchAppStart
    : AppStartComponents.DoubleDropdown

  return (
    <AppStartComponentContext.Provider value={appStartComponent}>
      {children}
    </AppStartComponentContext.Provider>
  )
}

export default withSitecoreContext()(AppStartContext)
