import { isEmpty } from 'lodash-es'
import { IOption, OptionList } from '../types'
import { getListResults, ListResults } from './ListResults'
import { getSearchResults, SearchResults } from './SearchResults'

type SearchControllerCallbacks = {
  onNoResults?: (searchInput: string) => void
}

export interface IValidateWebBusClass {
  isValidWebBusClass: (webBusClassId: string | number) => Promise<boolean>
}

export class SearchController implements IValidateWebBusClass {
  listResults: ListResults
  searchResults: SearchResults
  endpointApp: string
  onNoResults?: (searchInput: string) => void

  constructor(
    endpointSearch: string,
    endpointList: string,
    industryList: string,
    endpointApp: string,
    preselect: { industry: false | string; profession: false | IOption },
    callbacks: SearchControllerCallbacks
  ) {
    Object.assign(this, callbacks)

    this.listResults = getListResults(endpointList, industryList, preselect)
    this.searchResults = getSearchResults(endpointSearch)
    this.endpointApp = endpointApp
  }

  loadOptions: (input: string) => Promise<OptionList> = async input => {
    // console.log('[Search Controller] loadOptions ', input)

    if (isMinLength(input)) {
      return this.search(input)
    } else {
      return this.listResults.fetch()
    }
  }

  async search(input: string): Promise<OptionList> {
    // console.log('[Search Controller] search ', input)
    const results = await this.searchResults.fetch(input)

    const hasResults = results.length > 0

    if (!hasResults && this.onNoResults) {
      this.onNoResults(input)
    }

    return results
  }

  primeCache() {
    // console.log('[Search Controller] prime cache')
    this.listResults.primeCache()
  }

  isValidWebBusClass = async (webBusClassId: string | number) => {
    const professions = await this.listResults.fetchProfessions()

    const getIntBusClass: () => number = () => {
      if (typeof webBusClassId === 'string') {
        return parseInt(webBusClassId, 10)
      }
      return webBusClassId
    }

    return (
      professions.find(option => option.value === getIntBusClass()) !==
      undefined
    )
  }

  startApplication = async (webBusClassId: string | number, startAppRouteQueryStr: string = '') => {
    if (await this.isValidWebBusClass(webBusClassId)) {
      const url = this.endpointApp + `?WebBusClassId=${webBusClassId}${!isEmpty(startAppRouteQueryStr) ? `&${startAppRouteQueryStr}` : ''}`

      console.log('App start: ', url)

      // fire the cannons
      window.location.href = url
    } else {
      console.warn('[App Start] failed to start app for business class ', webBusClassId)
    }
  }
}

export function isMinLength(input: string): boolean {
  return input.length >= 1
}
