import React, { ReactNode, memo } from 'react'
import Button from '../../Button'
import styles from './styles.module.scss'

type ButtonProps = {
  onClick: () => void
  children: ReactNode
}

export function SelectionButton({ onClick, children }: ButtonProps) {
  return (
    <Button
      type="secondary"
      size="large"
      onClick={onClick}
      className={styles.button}
      testId='StateModule-SelectStateBtn'
    >
      <span className={styles.arrow}>{children}</span>
    </Button>
  )
}

export default memo(SelectionButton)
