import React, { createElement } from 'react'
import clsx from 'clsx'
import spaceStyles from '../../styles/space.module.scss'
import Media from '../Media'
import { withClassName } from '../../util/withClassName'
import { HeadingClassOverrides } from './types'
import styles from './styles.module.scss'
import { usePageType } from '../../util/usePageType'
import { PageTypes } from '../../constants/page-type'

type HeadingProps = {
  addStyle?: HeadingClassOverrides
  headingTag?: string
  embed?: boolean
  title: JSX.Element
  mobileTitle?: JSX.Element
  body?: JSX.Element | null
  testId?: string
}

const Heading = ({
  title,
  mobileTitle,
  body,
  addStyle = {},
  embed = false,
  testId,
  headingTag = '',
}: HeadingProps) => {
  const pageType = usePageType()
  const isMajorOrMinorOrAbout = [
    PageTypes.MAJOR_PAGE,
    PageTypes.MINOR_PAGE,
    PageTypes.ABOUT_PAGE,
    PageTypes.POLICY_PAGE
  ].includes(pageType)
  return (
    <div
      data-test-id={testId}
      className={clsx(
        addStyle.root,
        !embed && 'block',
        'Heading',
        !embed && spaceStyles.contain,
        isMajorOrMinorOrAbout && headingTag === 'h2' && styles.heading2
      )}
    >
      <Media
        desktop={() => withClassName(title, addStyle.title)}
        mobile={() => withClassName(mobileTitle || title, addStyle.title)}
      />
      {body &&
        createElement(body.type, {
          ...body.props,
          className: clsx('descriptor', addStyle.descriptor),
        })}
    </div>
  )
}

export default Heading
