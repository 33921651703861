import React, { useContext } from 'react'
import CallIcon from '../../assets/icon-circle-whitephone.svg'

import styles from './styles.module.scss'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { LeadRoutingNumberProps } from '../..'
import { LinkWrapperWithChildItems } from '../../../LinkWrapper'
import { NavigationContext } from '../../../../page-components/Navigation/services/context'
import clsx from 'clsx'

const RoutingNumberButton = ({
  sitecoreContext: {
    route: {
      fields: {
        leadRoutingAttributes: {
          fields: { leadRoutingNumber },
        },
      },
    },
  },
}: LeadRoutingNumberProps) => {
  const cleanNumberString = leadRoutingNumber.value.replace(/[^a-zA-Z0-9]/g, '');
  const { isPolicyFinderPage } = useContext(NavigationContext);
  return (
    <div className={clsx(styles.callIcon, isPolicyFinderPage && styles.isPolicyFinderPage)}>
      <LinkWrapperWithChildItems href={`tel:+1${cleanNumberString}`}>
        <img src={CallIcon} alt="Call Icon" />
      </LinkWrapperWithChildItems>
    </div>
  )
}

export default withSitecoreContext()(RoutingNumberButton)
