import React, { useEffect, useContext, useReducer } from 'react'
import InterceptLinks from './InterceptLinks'
import { ModalCTAContext } from '../../context/ModalCTAContext'
import { AppStartSettingsContext } from '../experiences/search-app-start/context'

type CTALinkInterceptProps = {
  preSelectDisabled?: boolean
  children: React.ReactNode
}

function CTALinkIntercept({
  preSelectDisabled = false,
  children,
}: CTALinkInterceptProps) {
  const { showModal } = useContext(ModalCTAContext)
  const { reset } = useContext(AppStartSettingsContext)

  const clickHandler = () => {
    if (preSelectDisabled) {
      reset && reset()
    }

    showModal()
  }

  return (
    <InterceptLinks checkLink={isStartQuoteLink} onChange={clickHandler}>
      {children}
    </InterceptLinks>
  )
}

export default CTALinkIntercept

export function isStartQuoteLink(href: string) {
  return /start-quote$/.test(href)
}
