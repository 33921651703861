import { useContext } from "react"
import { SitecoreContextReactContext } from "@sitecore-jss/sitecore-jss-react"

/**
 * Returns site-core context
 */
export const useSitecoreContext = () => {
  const { context: sitecoreContext } = useContext(SitecoreContextReactContext);

  return ({
    sitecoreContext
  })
}