import * as React from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'


type BackgroundImageProps = {
  className?: string
  children: React.ReactNode
}

export default function BackgroundImage({ children, className }: BackgroundImageProps) {
  return <div className={clsx(styles.backgroundImage, className)}>{children}</div>
}
