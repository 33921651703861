import React from 'react'
import styles from './styles.module.scss'

// Maintains layout while component is out of viewport
export default function() {
  return (
    <div className={styles.parent}>
      <div className={styles.child}></div>
    </div>
  )
}
