const options: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}

export const convertToLocalString = (date: string | number | Date) => {
  return new Date(date).toLocaleDateString('en-US')
}

export const convertToLocalTZInvariantString = (
  date: string | number | Date
) => {
  return new Date(date).toLocaleDateString('en-US', {
    ...options,
    timeZone: 'UTC',
  })
}
