import React, { useContext } from 'react'
import styles from './styles.module.scss';
import { CurrentBreakpoint } from '../../../../components/Media'
import clsx from 'clsx'

export type PagingProps = {
  startCursor: string
  endCursor: string
  total: number
  onPrevPage?: () => void
  onNextPage?: () => void
}

function Paging(props: PagingProps) {
  const currentBreakPoint = useContext(CurrentBreakpoint);

  const optionalText = (currentBreakPoint != "mobile") ? 'Displaying results ' : '';

  const leftStyles = props.total > 15
    ? clsx(styles.pageButton, styles.pageButtonLeft)
    : clsx(styles.pageButton, styles.pageButtonLeft, styles.pageButtonHidden)

  const rightStyles = props.total > 15
    ? clsx(styles.pageButton, styles.pageButtonRight)
    : clsx(styles.pageButton, styles.pageButtonRight, styles.pageButtonHidden)

  return (
    <div className={styles.paging}>
      <button disabled={props.startCursor === "1"} className={leftStyles} onClick={props.onPrevPage}></button>
      <span className={styles.description}>{optionalText}{props.startCursor} to {props.endCursor} of {props.total}</span>
      <button disabled={props.endCursor === props.total.toString(10)} className={rightStyles} onClick={props.onNextPage}></button>
    </div>
  )
}

export default Paging