import * as React from 'react'
import * as rs from '@thi.ng/rstream'
import * as carousel from './slides'
import * as D from './domain'

const transitionMs = 400

type SlideshowProps = {
  slides: JSX.Element[]
  durationSeconds?: number
  children: ({ frame }: { frame: D.Frame }) => JSX.Element
}

type SlideshowState = {
  frame: D.Frame
}

export default function SlideshowContainer({
  slides,
  durationSeconds = 2,
  children,
}: SlideshowProps) {
  const durationMs = durationSeconds * 1000 + transitionMs

  // server render and start with the first slide
  const [state, setState] = React.useState<SlideshowState>({
    frame: [slides[0], null],
  })

  // on client mount, start frame transitions
  React.useEffect(() => {
    const delayOpts = {
      delay: durationMs,
    }

    const updateFrame = (frame: D.Frame) => {
      setState({
        frame: frame,
      })
    }

    // carousel.frames gives us an iterable which we can pull from infinitely
    // iterables are cool, but using an infinite iterable with a delay is just enough odd code
    // that feeding the iterable to rstream, to turn it into a stream with a delay property, makes sense
    // rstream wraps the iterable in a subscription with a next callback
    const subscription = rs
      .fromIterable(carousel.frames(slides), delayOpts)
      .subscribe({
        next: updateFrame,
      })

    return function cleanup() {
      subscription.unsubscribe()
    }
  }, [])

  return children({ frame: state.frame })
}
