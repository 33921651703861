import { LinkWrapperWithChildItems } from '../../../components/LinkWrapper'
import Media from '../../../components/Media'
import { FC } from '../../../types/common'
import styles from './styles.module.scss'
import LinkedInLogo from './../assets/linkedin-blue-icon.svg'
import defaultProfilePic from './../assets/default-profile.png'
import { isEmpty, omit } from 'lodash-es'
import { BlogAuthorTitles } from '../../../constants/blog-author'
import { AuthorProfileProps } from '.'

export const defaultAuthorProfilePic = {
  src: defaultProfilePic,
  height: 48,
  width: 48,
  alt: 'Insureon Author',
  title: 'Insureon Author',
}

const ProfileInfoSection: FC<AuthorProfileProps> = ({ author }) => {
  const {
    fields: { profilePic, name, linkedinUrl },
  } = author
  return (
    <div className={styles.authorProfileSection}>
      <div className={styles.authorProfilePicWrap}>
        <img
          className={styles.authorProfilePic}
          {...omit(
            isEmpty(profilePic?.value?.src)
              ? defaultAuthorProfilePic
              : profilePic?.value,
            ['height', 'width']
          )}
        />
      </div>
      <Media
        mobile={() => <h2 className={styles.authorName}>{name?.value}</h2>}
        tablet={() => <></>}
      />
      {linkedinUrl?.value && (
        <div>
          <LinkWrapperWithChildItems
            href={linkedinUrl?.value}
            text={BlogAuthorTitles.VIEW_LINKEDIN}
            title={BlogAuthorTitles.VIEW_LINKEDIN}
            className={styles.viewLinkedInLinkWrap}
          >
            <img src={LinkedInLogo} height={27} width={27} />
            <div className={styles.viewLinkedInLink}>
              {BlogAuthorTitles.VIEW_LINKEDIN}
            </div>
          </LinkWrapperWithChildItems>
        </div>
      )}
    </div>
  )
}

export default ProfileInfoSection
