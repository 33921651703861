import React from 'react'
import { SitecoreContextValue, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import Breadcrumb from '../../../page-components/Breadcrumb/BreadcrumbList'
import { Item, TextField } from '../../../types/SitecoreAdapter'
import { getBreadcrumbs } from './getBreadcrumbs'
import { TagProps } from '../../../page-components/TagTopics/types'

export type PrimaryTag = {
  url: string
} & Item<{
  name: TextField
}>

type BreadcrumbAdapterProps = {
  sitecoreContext: SitecoreContextValue & {
    route: Item<{
      heading: TextField
      primaryTag: PrimaryTag
    }>
    tags: TagProps[]
  }
}

const BreadcrumbAdapter = ({
  sitecoreContext: {
    route: {
      fields: { heading, primaryTag },
    },
    tags,
  },
}: BreadcrumbAdapterProps) => (
  <Breadcrumb
    breadcrumbs={getBreadcrumbs(tags, heading.value, primaryTag)}
    showCurrentPageCrumb={false}
  />
)

export default withSitecoreContext()(BreadcrumbAdapter)
