/**
 * Convenience function to map theme strings to CSS module class names
 * 
 * @param {*} theme  A string or array of strings representing a theme
 * @param {*} styles A CSS module object used to translate the class names
 */
const moduleClassNames = (theme: string | any, styles: { [key: string]: string }) => {
  const array = [].concat(theme).filter(value => typeof value === 'string')

  return array.map(value => styles[value])
}

export {
  moduleClassNames
}
