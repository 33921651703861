import React, { FC } from 'react'
import styles from './styles.module.scss'
import CollapsiblePanel from '../../../../../../components/CollapsiblePanel/base'
import { PolicyAccordionProps } from '../../types'
import clsx from 'clsx'
import { PolicyAccordionSummary } from './Summary'
import { PolicyAccordionDetails } from './Details'

const PolicyAccordion: FC<PolicyAccordionProps> = props => (
  <CollapsiblePanel
    testId="RecommendedPolicy-Accordion"
    toggle={<PolicyAccordionSummary {...props} />}
    styleOverrides={{
      contain: clsx(
        styles.accPanelWrap,
        props.isSelected ? styles.isPolicySelected : ''
      ),
      accordionItemTitle: styles.accordionItemTitle,
      body: styles.accordionBody,
    }}
  >
    <PolicyAccordionDetails {...props} />
  </CollapsiblePanel>
)

export default PolicyAccordion
