import React from 'react'

import Heading from '../Heading'
import EscapeText from './EscapeText'
import clsx from 'clsx'
import styles from './styles.module.scss'
import spaceStyles from '../../styles/space.module.scss'
import { PolicyCardListProps } from './PolicyCardList'
import { FC } from '../../types/common'

type Props = { isMobile: boolean } & Pick<
  PolicyCardListProps,
  'descriptor' | 'escapeText' | 'title' | 'titleTag'
>

const CardSection: FC<Props> = ({
  title,
  titleTag,
  descriptor,
  escapeText,
  isMobile,
  children,
}) => {
  const sectionClasses = clsx(
    'block',
    styles.policyCardWrap,
    'PolicyCardSection',
    { [spaceStyles.contain]: isMobile }
  )

  return (
    <section className={sectionClasses}>
      <Heading
        testId={'PolicyCardList-Heading'}
        fields={{ title, tag: titleTag, descriptor }}
        addStyle={{ root: clsx(styles.heading, spaceStyles.contain) }}
        embed
      />
      <div className={styles.list}>{children}</div>
      <EscapeText escapeText={escapeText} />
    </section>
  )
}

export default CardSection
