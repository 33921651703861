import React, { useState, useRef, useEffect, ReactNode, memo } from 'react'
import List, { ServerLink } from './List'
import Button from './Button'
import styles from './styles.module.scss'

type SelectionProps = {
  button: ReactNode
  lists: ServerLink[]
}

export function Selection({ button, lists }: SelectionProps) {

  const ref = useRef(null);
  const toggle = () => setIsOpen(isOpen => !isOpen)
  const close = () => setIsOpen(false)

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const handleClickOutside = (event:any) => {
      // @ts-ignore
      if (ref.current && !ref.current.contains(event.target)) {
        close();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [ toggle ]);

  return (
    <div ref={ref} className={styles.selectionRef}>
      <Button onClick={toggle}>{button}</Button>
      <List links={lists} show={isOpen} onLinkClick={close} />
    </div>
  )
}

export default memo(Selection)
