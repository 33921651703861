import React, { Suspense } from 'react'

import { Placeholder, RouteData } from '@sitecore-jss/sitecore-jss-react'
import clsx from 'clsx'
import Nav from '../../page-components/Navigation'
import Banner from '../../components/Banner'
import styles from './styles.module.scss'
import HomePageHero from '../../components/Homepage-Hero'
import useTypeThemeClass from './useTypeThemeClass'
import PolicyRecommendationCTA from '../../components/Policy-Recommendation-CTA'
import { get, isEmpty } from 'lodash-es'

type HomeRouteProps = {
  route: {
    fields: {
      hero:
        | undefined
        | {
            fields: any
          }
    }
  } & RouteData
}

const HomePage = ({ route }: HomeRouteProps) => {
  const hero = route.fields.hero
  const hasPolicyCTA = !isEmpty(
    get(route.fields, 'policyRecommendationCtaSettings')
  )

  useTypeThemeClass(true)
  return (
    <>
      <Nav />
      <Banner />
      {hero && <HomePageHero {...hero} />}
      <Suspense>
        <div className={clsx('home', 'q221')}>
          <Placeholder name="Hero-Placeholder" rendering={route} />
          {hasPolicyCTA && (
            <PolicyRecommendationCTA
              wrapClassName={styles.homePRTCTA}
              visibleOn={['tablet', 'mobile']}
            />
          )}
          <Placeholder name="Home-Placeholder" rendering={route} />
        </div>
      </Suspense>
    </>
  )
}

export default HomePage
