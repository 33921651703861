import {
  fetchSitecorePixel,
  triggerSitecoreEvent as createSitecoreEvent,
} from '../../../../analytics/sitecore-analytics'
import { EventName } from './events'
import { partial } from 'lodash-es'

const sitecoreGoalItems: Map<EventName, string> = new Map([
  [EventName.ButtonClick, 'f812ad8c17724955827d7377a1a8d789'],
  [EventName.Drop, 'eac01a6add9a4850a426a47eb85eeed6'],
  [EventName.EscapeLink, '78267718f4b046aa9cb6293ace6ddf95'],
  [EventName.FirstLetter, '54bd114ad5cb48c28479d305cd0a00a9'],
  [EventName.InputClick, '0530704c56f245588b5c9c189a588c7e'],
  [EventName.NoResults, '37118da4694344b5bf1bddcd9a475434'],
])

const sitecoreAppStartGoalItem = 'b44574b272874aec9c70df757478c1bb'

export const triggerSitecoreEvent = partial(
  createSitecoreEvent,
  sitecoreGoalItems
)

export function triggerSitecoreAppStart() {
  return fetchSitecorePixel(sitecoreAppStartGoalItem)
}
