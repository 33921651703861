//=========================
// Policy Lookup Types
//=========================

/**
 * A list of Policy Item Ids
 */
export enum POLICY {
  GENERAL = 'c91f5a32-9de6-4e94-a542-a187ae95058f', //"General Liability"
  WORKERS_COMPENSATION = 'dba85c90-c034-40e9-8fc0-cc02c59f8abb', // "Workers' Compensation"
  BOP = '0078de77-f796-45f7-b1bc-bd9f9c4c44f4', // "Business Owner's Policy (BOP)"
  COMMERCIAL_AUTO = 'e61fd344-6eb3-4baa-b7f9-18da24e7c2d8', // 'Commercial Auto'
  PROFESSIONAL = 'de556b02-cf83-4f0d-bf84-a6f03898e315', // 'Professional Liability'
  CYBER = '139dd472-83a8-466f-8529-a0417488fe02', // 'Cyber Liability'
  COMMERCIAL_UMBRELLA = 'd251cbe1-fe3a-46b3-886c-41c3461e4a6b', // "Commercial Umbrella"
  PRODUCT = 'a6d3bb0b-765e-42ae-ba53-e2882599cbe6', // "Product Liability"
}

type QueOptionType = {
  option: string
  policy: POLICY
  whyRec: string[]
  bestFor: string[]
}

export type PolicyLookupItemType = {
  que: string
  options: QueOptionType[]
}

export type PolicyInfoType = { [key in POLICY]: { bestFor: string[] } }

export type GetRecQuestionType = { que: string; ans: string | string[] }

export type FlatQueType = { que: string; ans: string }

export type PolicyResType = Pick<QueOptionType, 'policy' | 'whyRec' | 'bestFor'>

export type AdditionalPolicyResType = Pick<QueOptionType, 'policy' | 'bestFor'>

export type AdditionalLookupType = {
  /**
   * Additional policies to be returned for a given list of rec. policies
   */
  additional: POLICY[]
  /**
   * A list of possible combinations of rec. policies,
   * for which the additional policies being suggested
   */
  recommended: Array<POLICY[]>
}

/**
 * A list of Question Item Ids
 */
export enum QUESTION {
  Q1 = '8c673c29-9724-478a-920a-4dcfd63b9773', // 'Which best describes you?'
  Q2 = '118af6ba-4e77-40c0-ad68-6dc6f6c41e7a', // 'Do you have employees other than yourself?'
  Q3 = '7fa6e8a6-3861-4aa3-905e-2805918fa5d4', // 'Do any of the following apply to your business?'
}

/**
 * A list of Question Response Item Ids
 */
export enum QUESTION_RESPONSES {
  Q1_OPT1 = '64DBF400-AF64-4A71-B060-9AB968E2AB09', // 'I have a business and currently have insurance'
  Q1_OPT2 = '63649CD2-0E35-4B58-B58D-756B3EB95E4A', // 'I have a business, but do not have insurance'
  Q1_OPT3 = '965A5615-39EB-42BD-A9E5-C50A5B2F9421', // 'I have a business and am required to have insurance per a contract and/or landlord requirement'
  Q2_OPT1 = '772B6D1D-DDC3-434E-A966-55DF8DAD1322', // 'Yes'
  Q2_OPT2 = '2A5833CD-543A-4BDA-9C30-5578FB6C627C', // 'No'
  Q3_OPT1 = '2A94B8FA-F9D8-4E19-8BEE-09F8AC0FA042', // 'I own a building (physical building, storefront, warehouse, etc…)'
  Q3_OPT2 = '1A3C3E60-0A3F-4399-88AB-D736FA506467', // 'I own a vehicle(s)'
  Q3_OPT3 = '3541377A-3D03-4E95-B179-6F888D87CDA8', // 'I own/rent business equipment (computers, office furniture, etc…)'
  Q3_OPT4 = '2A40AF5C-DE63-4C64-AB89-838DB90DFB9D', // 'I own/rent portable equipment (tools, machinery, etc…)'
  Q3_OPT5 = 'DCB45EDB-9AE2-41E0-BDBA-CA38A89D713C', // 'I provide professional advise and/or sell services'
  Q3_OPT6 = 'CD0486D7-7D5B-4242-B6B5-CAA9EF1F7ACA', // 'I handle and/or store sensitive data (credit card, SSNs, health records, etc…)'
  Q3_OPT7 = 'C7A36C13-5999-4347-AA64-813AEED8F92E', // 'None of the above'
}