import * as React from 'react'
import * as CMS from '../../../types/SitecoreAdapter'
import Heading from '../../../components/Heading'
import clsx from 'clsx'
import styles from '../styles.module.scss'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'

type IArticleHeadingProps = CMS.RouteFields<{
  heading: CMS.TextField
  subhead: CMS.TextField
}>

function ArticleHeading({
  sitecoreContext: {
    route: {
      fields: { heading, subhead },
    },
  },
}: IArticleHeadingProps) {
  const h1Tag = { fields: { tag: { value: 'h1' } } }

  return (
    <Heading
      testId={'ArticlePage-Heading'}
      embed
      fields={{
        title: heading,
        tag: h1Tag,
        descriptor: subhead,
      }}
      addStyle={{ root: clsx(styles.heading, 'contain') }}
    />
  )
}

export default withSitecoreContext()(ArticleHeading)
