import React from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'

type HorizontalScrollerProps = {
  children: JSX.Element[]
  className?: string
}

export default function HorizontalScroller({
  children,
  className = '',
}: HorizontalScrollerProps) {
  if (!children) return null
  return <div className={clsx(className, styles.root)}>{children}</div>
}
